/* Confirmation Modal Template */

// External
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
// components
import Button from "@/components/button";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// store
import { setModalIsOpen } from "../../modalSlice";

import {
  useLabFlowsheetUpdateDateMutation,
  LabFlowsheetUpdateDateApiArg
} from "@/store/services/lab";
import { setSelectDayResultsDate } from "@/components/labFlowSheet/slice";

// styles
import styles from "../../styles.module.scss";

export interface ConfirmUpdateLabFlowsheetDateProps {
  updateDatePayload: LabFlowsheetUpdateDateApiArg;
}

export default function ConfirmUpdateLabFlowsheetDate({
  updateDatePayload
}: ConfirmUpdateLabFlowsheetDateProps) {
  const dispatch = useDispatch();

  const [updateDate] = useLabFlowsheetUpdateDateMutation();

  const oldDate = updateDatePayload.labFlowsheetUpdateDateParams.old_date;
  const newDate = updateDatePayload.labFlowsheetUpdateDateParams.new_date;

  const formattedOldDate = dayjs(oldDate).format("MM/DD/YYYY");
  const formattedNewDate = dayjs(newDate).format("MM/DD/YYYY");

  const handleConfirm = () => {
    updateDate(updateDatePayload)
      .unwrap()
      .then(() => {
        // update the date in the store on success
        dispatch(setSelectDayResultsDate(newDate));
        dispatch(
          addAlertToToastTrough({
            message: "Lab Flowsheet Date Updated",
            status: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(error => {
        dispatch(
          addAlertToToastTrough({
            message: "Error updating lab flowsheet date",
            status: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  return (
    <div className={styles.ConfirmUpdateLabFlowsheetDate}>
      <p>
        Are you sure you want to change the date from{" "}
        <span className="tMd">{formattedOldDate}</span> to{" "}
        <span className="tMd">{formattedNewDate}</span>? Any data previously
        entered for <span className="tMd">{formattedNewDate}</span> will be
        overwritten.
      </p>
      <div className={styles.buttons}>
        <Button onClick={handleCancel} style={STYLES.SECONDARY}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </div>
    </div>
  );
}
