/* Button Component */

// External
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { motion } from "framer-motion";
import posthog from "posthog-js";

// components
import ButtonLink from "../button/link";
import Button from "../button";
import AvatarPlaceholder from "../avatarPlaceholder";
// constants
import { STYLES, PAGES, APP_KEYS, METRICS } from "../../globals/constants";
import Icon from "../icons";

// store
import { RootState } from "@/store/store";
import { setIsAuthenticated } from "@/components/auth/slice";
import { useMessageInboxQuery } from "@/store/services/message";
import {
  ClaimStatus,
  useGetClaimNotificationsQuery
} from "@/store/services/claim";

// styles
import styles from "./styles.module.scss";

// utils
import { unsetCookie } from "@/utils/cookies";

export interface SidebarProps {
  links: {
    label: string;
    href: string;
  }[];
}

export default function SideBar({ links }: SidebarProps) {
  const dispatch = useDispatch();
  const router = useRouter();

  // get user session  info
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  let userInitials = "";
  if (sessionInfo)
    userInitials = sessionInfo.first
      ? sessionInfo?.first?.[0]
      : "" + sessionInfo?.last?.[0];

  const [hover, setHover] = useState<boolean>(false);

  const { data, isSuccess: isInboxSuccess } = useMessageInboxQuery(
    {},
    { pollingInterval: 3000 }
  );

  const { data: claimNotifications, isSuccess: isClaimSuccess } =
    useGetClaimNotificationsQuery({
      statusIn: sessionInfo?.is_biller
        ? ([METRICS.IN_REVIEW, METRICS.ACCEPTED] as ClaimStatus[])
        : ([
            METRICS.CREATED,
            METRICS.DENIED_PENDING_PROVIDER_APPROVAL,
            METRICS.PARTIALLY_DENIED,
            METRICS.PATIENT_BALANCE,
            METRICS.DENIED,
            METRICS.REJECTED_BY_CLEARINGHOUSE,
            METRICS.CHANGES_REQUIRED
          ] as ClaimStatus[])
    });

  let notificationAmount = 0;

  if (isInboxSuccess) {
    data.messages.forEach(message => {
      notificationAmount += message.unread;
    });
  }

  const handleNotificationDisplay = (label: string) => {
    switch (label) {
      case "messaging":
        return !!data?.messages.find(msg => msg.unread);
      case "claims":
        if (claimNotifications) {
          return claimNotifications > 0;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  const handleClaimNotificationCount = (label: string) => {
    switch (label) {
      case "messaging":
        return notificationAmount;
      case "claims":
        return claimNotifications;
      default:
        return null;
    }
  };

  const list = {
    hidden: {
      width: "80px",
      transition: {
        delay: 0.2
      }
    },
    shown: {
      width: "180px"
    }
  };

  const item = {
    hidden: {
      x: -10,
      opacity: 0,
      width: 0,
      marginRight: "-8px"
    },
    shown: {
      x: 0,
      opacity: 1,
      marginRight: 0,
      width: "min-content",
      transition: {
        delay: 0.2,
        stagger: 0.1
      }
    }
  };

  return (
    <motion.nav
      className={clsx(styles.SideBar)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variants={list}
      animate={hover ? "shown" : "hidden"}
    >
      <ButtonLink href={PAGES.HOME} style={STYLES.ICON}>
        <Image
          src="/svgs/logo_secondary.svg"
          alt="Pario Health Logo"
          width={30}
          height={30}
        />
      </ButtonLink>
      {/* TODO: add links to additional screens */}
      <motion.div className={styles.pages} data-cy="sidebar-links">
        {links.map(({ label, href }) => (
          <ButtonLink
            href={href}
            key={`${label}-sidebar-nav`}
            style={STYLES.ICON}
            isNav
            isActive={router.route === href}
            isNotification={handleNotificationDisplay(label)}
            notificationNumber={handleClaimNotificationCount(label)}
            className={styles.sidebarLink}
          >
            <Icon svg={label} width={20} height={20} />
            <motion.span
              className={styles.linkLabel}
              variants={item}
              animate={hover ? "shown" : "hidden"}
            >
              {label.slice(0, 1).toUpperCase().concat(label.slice(1))}
            </motion.span>
          </ButtonLink>
        ))}
      </motion.div>
      <div className={styles.settings}>
        <Button
          onClick={() => {
            unsetCookie("X-Pario-Auth");
            dispatch(setIsAuthenticated(false));
            posthog.reset();
            window.Intercom("update", {
              hide_default_launcher: true
            });
          }}
          style={STYLES.ICON}
        >
          <Icon svg="log-out" flipped />
        </Button>
        <AvatarPlaceholder
          character={userInitials}
          isInverse
          isSmall
          userId={sessionInfo?.user_id}
        />
      </div>
    </motion.nav>
  );
}
