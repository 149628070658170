/* ViewBody Name */
/* External Imports */
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

/* Local Imports */

// components

import LayoutLeft from "../../../public/svgs/layout-left.svg";
import Button from "../button";
import NotificationIcon from "../notificationIcon";
import FileAnnotations from "@/components/fileAnnotations";

// constants
import { STYLES } from "@/globals/constants";
// store
import { FileId, useFileDetailsQuery } from "@/store/services/file";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

/* ViewBody Typescript Interface */
interface ViewBodyProps {
  children: ReactNode;
  fileId?: FileId;
}

export default function ViewBody({ children, fileId }: ViewBodyProps) {
  /* Redux */

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: fileDetails } = useFileDetailsQuery(
    { fileId: fileId as FileId },
    { skip: !fileId }
  );

  const [showAnnotations, setShowAnnotations] = useState(false);

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.ViewBody)}>
      <div className={styles.menu}>
        {fileId && !sessionInfo?.is_patient && (
          <div className={styles.icon}>
            <Button
              style={STYLES.ICON}
              onClick={() => setShowAnnotations(!showAnnotations)}
              dataCy="toggle-annotations-button"
            >
              <LayoutLeft
                stroke={showAnnotations ? styles.primary700 : styles.gray500}
                height={20}
                width={20}
              />
              <div className="light">
                {showAnnotations
                  ? "Close Annotation Drawer"
                  : "Open Annotation Drawer"}
              </div>
              {fileDetails?.annotations ? <NotificationIcon /> : ""}
            </Button>
          </div>
        )}
      </div>
      {/* The PDF */}
      <div className={styles.content}>
        <div className={styles.contentViewer}>{children} </div>
        {/* The annotations menu */}
        {showAnnotations && fileId && <FileAnnotations fileId={fileId} />}
      </div>
    </div>
  );
}
