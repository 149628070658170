/* FileAnnotationReadOnly Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { sanitize } from "dompurify";

/* Local Imports */

// components
import AvatarPlaceholder from "@/components/avatarPlaceholder";
import ContentRenderer from "@/components/textArea/contentRenderer";
// constants
import { FORMAT } from "@/globals/helpers/formatters";

// store
import { FileAnnotation } from "@/store/services/file";
import { useUserGetInfoQuery } from "@/store/services/user";

// styles
import styles from "./styles.module.scss";

/* FileAnnotationReadOnly Typescript Interface */
interface FileAnnotationReadOnlyProps {
  fileAnnotation: FileAnnotation;
}

export default function FileAnnotationReadOnly({
  fileAnnotation
}: FileAnnotationReadOnlyProps) {
  /* Redux */
  const { data: userInfo } = useUserGetInfoQuery({
    userId: fileAnnotation.author
  });

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.FileAnnotationReadOnly)}>
      <div className={styles.metadata}>
        <AvatarPlaceholder
          character={
            userInfo ? userInfo?.first_name?.[0] + userInfo?.last_name?.[0] : ""
          }
          userId={userInfo?.user_id}
          isSmall
        />
        <div>
          <p className="t4 dark">{userInfo ? FORMAT.name(userInfo) : "-"}</p>
          <p className="t5 gray400">
            {dayjs(fileAnnotation.updated || fileAnnotation.created).format(
              "hh:mm a M/D/YY "
            )}
          </p>
        </div>
      </div>
      <div className={styles.content}>
        <ContentRenderer content={sanitize(fileAnnotation.annotation)} />
      </div>
    </div>
  );
}
