// External
import { useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import clsx from "clsx";

// Helpers, globals, constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { EVENTS, STAGES } from "@/components/flows/labor/constants";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// Store
import {
  LaborTransferPdfParams,
  PatientGeneratePdfPayload,
  PatientInfo,
  usePatientGeneratePdfMutation
} from "@/store/services/patient";
import {
  EncounterLaborEventUpsertApiArg,
  useEncounterLaborEventUpsertMutation
} from "@/store/services/encounter";

// Components
import Input from "@/components/input";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import FaxContact from "@/components/input/faxContact";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

// Styles
import styles from "../styles.module.scss";
import { RecordGenerationFormValues, useLabDataList } from "@/components/modal/templates/shareRecords/helpers/recordGenerationHooks";
import { parseFileIds } from "@/components/modal/templates/shareRecords/helpers/utilities";
import { getBlobFromBase64String } from "@/globals/helpers/fileHelpers";
import AdditionalDataCheckList from "@/components/modal/templates/shareRecords/additionalDataCheckList";

interface TransferDecisionFormProps {
  defaultValues: DefaultValues<RecordGenerationFormValues>;
  patient: PatientInfo;
  encounterId: number;
}

export default function TransferDecisionForm({
  defaultValues,
  patient,
  encounterId
}: TransferDecisionFormProps) {
  const dispatch = useDispatch();

  const [sendTransferData] = usePatientGeneratePdfMutation();
  const [saveLaborEvent] = useEncounterLaborEventUpsertMutation();

  // Internal state
  const [errorText, setErrorText] = useState<string>("");

  const labListOptions = useLabDataList(patient.user_id);

  // Form hook
  const form = useForm<RecordGenerationFormValues>({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue
  } = form;

  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  const saveTransferForm = async (data: any) => {
    const args: EncounterLaborEventUpsertApiArg = {
      encounterId: encounterId,
      stage: STAGES.TRANSFER,
      laborEvent: EVENTS.DECISION,
      laborEventUpdate: {
        event_id: EVENTS.DECISION,
        forms: data
      }
    };

    await saveLaborEvent(args)
      .unwrap()
      .then(_ => {
        launchToast(`Transfer recorded.`, STATUS_KEYS.SUCCESS);
      })
      .catch(() =>
        launchToast(`Transfer event not recorded.`, STATUS_KEYS.ERROR)
      );
  };

  const submit = async (data: RecordGenerationFormValues) => {
    await saveTransferForm(data);

    dayjs.extend(timezone);
    if (!data.recipient_fax) {
      setErrorText("Fax number required");
      return;
    }

    const fileIds = parseFileIds(data);

    const coverpageParams: LaborTransferPdfParams = {
      encounter_id: encounterId,
      subject_of_transfer: data.subject_of_transfer,
      reason_for_transfer: data.reason_for_transfer,
      transportation_mode: data.transportation_mode,
      patient_status_at_time_of_transport:
        data.patient_status_at_time_of_transport,
      assessment: data.assessment,
      recommendation: data.recommendation
    };

    if (data.transportation_called_at) {
      coverpageParams.transportation_called_at = dayjs(
        `${data.transportation_called_at}`,
        "YYYYMMDDHHmmss"
      ).toISOString();
    }

    if (data.transportation_arrived_at) {
      coverpageParams.transportation_arrived_at = dayjs(
        `${data.transportation_arrived_at}`,
        "YYYYMMDDHHmmss"
      ).toISOString();
    }

    if (data.transportation_departed_at) {
      coverpageParams.transportation_departed_at = dayjs(
        `${data.transportation_departed_at}`,
        "YYYYMMDDHHmmss"
      ).toISOString();
    }

    const transferPayload: PatientGeneratePdfPayload = {
      sections: ["labor_transfer"],
      labor_transfer_params: coverpageParams,
      extra_file_ids: fileIds
    };

    dispatch(setModalIsOpen(true));
    dispatch(setModalContent({ type: MODAL_TYPES.LOADING_SPINNER, props: {
      title: "Loading...."
    } }));

    sendTransferData({
      patientId: patient.user_id,
      patientGeneratePdfPayload: transferPayload
    })
      .unwrap()
      .then(res => {
        dispatch(setModalIsOpen(true));
        dispatch(
          setModalContent({
            type: MODAL_TYPES.PREVIEW_PDF,
            props: {
              title: "Transfer",
              pdfBase64: res.content,
              isSendingFax: true,
              patientId: patient.user_id,
              data,
              errors: res.file_errors
            }
          })
        );
      });
  };

  return (
    <form
      className={clsx(styles.DecisionForm, styles.LaborForm)}
      onSubmit={handleSubmit(submit)}
    >
      <div className={styles.timestamps}>
        <Controller
          name="transportation_called_at"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={
                  defaultValues &&
                  parseInt(
                    dayjs(defaultValues.transportation_called_at).format(
                      "YYYYMMDDHHmmss"
                    )
                  )
                }
                value={value}
                label="Time of decision to transport"
              />
            );
          }}
        />
        <Controller
          name="transportation_arrived_at"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={
                  defaultValues &&
                  parseInt(
                    dayjs(defaultValues.transportation_arrived_at).format(
                      "YYYYMMDDHHmmss"
                    )
                  )
                }
                value={value}
                label="Time transport arrived"
              />
            );
          }}
        />
        <Controller
          name="transportation_departed_at"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={
                  defaultValues &&
                  parseInt(
                    dayjs(defaultValues.transportation_departed_at).format(
                      "YYYYMMDDHHmmss"
                    )
                  )
                }
                value={value}
                label="Time transport departed"
              />
            );
          }}
        />
      </div>
      <div>
        <ControlledCombobox
          label="Subject of transfer"
          name="subject_of_transfer"
          control={control}
          errors={errors}
          isHorizontalLayout={false}
          options={["mother", "infant"]}
          fullWidth
          required
        />
        <ControlledCombobox
          label="Type of transport"
          name="transportation_mode"
          control={control}
          errors={errors}
          isHorizontalLayout={false}
          options={["Ambulance", "Provider's car", "Patient's car"]}
          fullWidth
        />
      </div>
      <div className={styles.fullWidth}>
        <Input
          type="text"
          name="assessment"
          register={register}
          id="assessment"
          label="Assessment"
          fullWidth
        />
        <Input
          type="text"
          name="recommendation"
          id="recommendation"
          label="Recommendation"
          register={register}
          fullWidth
        />
      </div>
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Transfer note"
          name="reason_for_transfer"
          id="reason_for_transfer"
          form={form}
          rows={3}
          placeholder="Enter reason for transfer and any other relevant infomation here..."
        />
      </div>
      <ControlledCombobox
        label="Condition at transport"
        name="patient_status_at_time_of_transport"
        control={control}
        errors={errors}
        isHorizontalLayout={false}
        options={["Stable", "Unstable"]}
        fullWidth
      />
      <Input
        type="text"
        label="Recipient Name*"
        name="recipient_name"
        id="recipient_name"
        register={register}
        fullWidth
        rules={{
          required: true
        }}
        error={errors.recipient_name && "Recipient can't be blank"}
      />
      <input
        type="hidden"
        {...register("recipient_fax", {
          required: "Recipient fax can't be blank"
        })}
      />
      <FaxContact
        label="Recipient Fax*"
        required
        onChange={item => {
          if (item) {
            setValue("recipient_fax", item.fax, {
              shouldValidate: true
            });
          }
        }}
        error={errors.recipient_fax && "Recipient fax can't be blank"}
      />
      
      <div className={styles.fullWidth}>
        <AdditionalDataCheckList
        form={form}
        listId="labs"
        listLabel="Labs"
        listOptions={labListOptions}
      />
      </div> 

      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="record_note"
          id="comments"
          placeholder="Enter comments here."
          rows={4}
        />
      </div>
      <div className={styles.fullWidth}>
        {(errorText != "" || errors.subject_of_transfer) && (
          <div style={{ margin: "10px 0" }}>
            <p className="error" style={{ padding: "10px" }}>
              {errors.subject_of_transfer ? "Subject is required" : errorText}
            </p>
          </div>
        )}
        <div className="grid2">
          <Button
            style={STYLES.FULL_WIDTH}
            type="button"
            onClick={() => saveTransferForm(form.getValues())}
          >
            Save
          </Button>
          <Button
            style={STYLES.FULL_WIDTH}
            type="submit"
            nativeButtonProps={{
              disabled: !form.getValues("recipient_fax")
            }}
          >
            Preview & Send
          </Button>
        </div>
      </div>
    </form>
  );
}
