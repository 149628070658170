/* File Renderer Component */

/* External Imports */
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import path from "path";

/* Local Imports */

// components

// constants

// store
import { FileListItem } from "@/store/services/file";

// styles
import styles from "./styles.module.scss";

interface FileRendererProps {
  file: FileListItem;
}

export default function FileRenderer({ file }: FileRendererProps) {
  /* Local State */
  const [isLoading, setIsLoading] = useState(true);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const filepath = path.join("", file.name);
  const fileExtension = filepath.slice(
    ((filepath.lastIndexOf(".") - 1) >>> 0) + 2
  );

  const isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtension);
  const isPdf = fileExtension === "pdf";

  /* Effects */

  /* Event Handlers */
  const handleLoad = () => {
    setIsLoading(false);
  };

  const renderFileContent = () => {
    if (isImage) {
      return (
        <div className={styles.imageContainer}>
          {isLoading && (
            <div className={styles.loadingPlaceholder}>
              <div className={styles.spinner} />
            </div>
          )}
          <motion.img
            key={file.url}
            src={file.url}
            alt="Displayed File"
            className={clsx(styles.image, !isLoading && styles.loaded)}
            onLoad={handleLoad}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          />
        </div>
      );
    } else if (isPdf) {
      return (
        <AnimatePresence mode="wait">
          <div className={styles.pdfContainer}>
            {isLoading && (
              <div className={styles.loadingPlaceholder}>
                <div className={styles.spinner} />
              </div>
            )}
            <motion.iframe
              key={file.url}
              ref={iframeRef}
              src={file.url}
              className={clsx(styles.pdf, { [styles.loaded]: !isLoading })}
              onLoad={handleLoad}
              style={{
                border: "none",
                width: "100%",
                height: "100%"
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            />
          </div>
        </AnimatePresence>
      );
    } else {
      return (
        <motion.div
          className={styles.unsupported}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <p>Unsupported file type.</p>
        </motion.div>
      );
    }
  };

  return (
    <motion.div
      className={styles.FileRenderer}
      layoutId="file-renderer"
      transition={{
        duration: 0.2,
        ease: "easeInOut"
      }}
    >
      {renderFileContent()}
    </motion.div>
  );
}
