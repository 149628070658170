/* FileAnnotationsReadOnly Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import FileAnnotationReadOnly from "./fileAnnotationReadOnly";

// constants

// store
import {
  FileAnnotation,
  FileId,
  useFileDetailsQuery
} from "@/store/services/file";

// styles
import styles from "./styles.module.scss";

/* FileAnnotationsReadOnly Typescript Interface */
interface FileAnnotationsReadOnlyProps {
  fileId?: FileId;
}

export default function FileAnnotationsReadOnly({
  fileId
}: FileAnnotationsReadOnlyProps) {
  /* Redux */
  const { data: fileDetails } = useFileDetailsQuery(
    {
      fileId: fileId as FileId
    },
    { skip: !fileId }
  );

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.FileAnnotationsReadOnly)}
      data-testid="file-annotations-read-only"
    >
      {fileDetails?.annotations.map((annotation: FileAnnotation) => (
        <FileAnnotationReadOnly
          fileAnnotation={annotation}
          key={annotation.annotation_id}
        />
      ))}
    </div>
  );
}
