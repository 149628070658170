/* AnimatedSidebar */
// A wrapper component that animates the a sidebar in and out of view
/* External Imports */
import { AnimatePresence, motion } from "framer-motion";
/* Local Imports */

// components

// constants

// store

// styles
import styles from "./styles.module.scss";

/* AnimatedSidebar Typescript Interface */
interface AnimatedSidebarProps {
  show: boolean;
  children: React.ReactNode;
}
export default function AnimatedSidebar({
  show,
  children
}: AnimatedSidebarProps) {
  const sidebarVariants = {
    open: { width: 240, transition: { duration: 0.3, ease: "easeInOut" } },
    closed: { width: 0, transition: { duration: 0.3, ease: "easeInOut" } }
  };

  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={styles.AnimatedSidebar}
          initial="closed"
          animate="open"
          exit="closed"
          variants={sidebarVariants}
        >
          <div className={styles.innerContent}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
