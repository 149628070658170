/* Confirmation Modal Template */

// External
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
// components
import Button from "@/components/button";

// constants
import { PAGES, STATUS_KEYS, STYLES } from "@/globals/constants";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// store
import { setModalIsOpen } from "../../modalSlice";
import {
  PatientInfo,
  usePatientDeleteMutation
} from "@/store/services/patient";
import { RootState } from "@/store/store";

// styles
import styles from "../../styles.module.scss";

// utils
import { nameAndDOB } from "@/globals/helpers/formatters";

export interface ConfirmDeletePatientProps {
  patient: PatientInfo;
}

export default function ConfirmDeletePatient({
  patient
}: ConfirmDeletePatientProps) {
  const dispatch = useDispatch();
  const router = useRouter();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const [deletePatient] = usePatientDeleteMutation();

  const handleConfirm = () => {
    deletePatient({ patientId: patient.user_id })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            type: STATUS_KEYS.SUCCESS,
            message: `${nameAndDOB(patient)} has been deleted`
          })
        );
        dispatch(setModalIsOpen(false));
        // route back to patient list
        router.push(PAGES.PATIENTS);
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            type: STATUS_KEYS.ERROR,
            message: `An error occurred while deleting ${nameAndDOB(patient)}`
          })
        );
        dispatch(setModalIsOpen(false));
      });
  };

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  return (
    <div className={styles.ConfirmDeletePatient}>
      <p>
        Are you sure you want to delete {nameAndDOB(patient)} from Pario? They
        will no longer appear in the patient list.
      </p>
      <div className={styles.buttons}>
        <Button onClick={handleCancel} style={STYLES.SECONDARY}>
          Cancel
        </Button>
        {sessionInfo?.is_superadmin && (
          <Button onClick={handleConfirm}>Confirm</Button>
        )}
      </div>
    </div>
  );
}
