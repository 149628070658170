import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const labFlowsheetSlice = createSlice({
  name: "labFlowsheet",
  initialState: {
    selectedDayResultsDate: dayjs().format("YYYY-MM-DD")
  },
  reducers: {
    setSelectDayResultsDate: (state, action) => {
      state.selectedDayResultsDate = action.payload;
    }
  }
});

export const { setSelectDayResultsDate } = labFlowsheetSlice.actions;

export default labFlowsheetSlice.reducer;
