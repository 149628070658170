// stdlib
import path from "path";

// third-party
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";

// components
import Input from "@/components/input";
import Edit from "../../../public/svgs/edit_simple.svg";
import Tag from "../tag";
import TagIcon from "../../../public/svgs/tag.svg";
import ComboboxSelect from "../input/combobox";
import ViewBody from "../viewBody";
import FileRenderer from "./fileRenderer";

// store
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { RootState } from "@/store/store";
import { FileListItem, useFileUpdateMutation } from "@/store/services/file";
import { TagListItem, useTagsListQuery } from "@/store/services/tag";

// globals
import { STATUS_KEYS } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";

interface FileViewerProps {
  file: FileListItem;
  onClose: () => void;
  isReadOnly?: boolean;
}

const FileViewer: React.FC<FileViewerProps> = ({
  file,
  onClose,
  isReadOnly
}) => {
  // react-hook-form hook configuration
  const { control } = useForm();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const router = useRouter();

  const filepath = path.join("", file.name);
  const parts = filepath.split("/");
  const filename = parts[parts.length - 1];
  const [isEditing, setIsEditing] = useState(false);
  const [editedFilename, setEditedFilename] = useState(filename);
  const handleFilepathClick = () => {
    setIsEditing(true);
  };

  const { data: availableTags } = useTagsListQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );
  const tagsById: Record<number, TagListItem> = {};
  for (const tag of availableTags || []) {
    tagsById[tag.tag_id] = tag;
  }

  const [updateFile, updateFileReq] = useFileUpdateMutation();
  useEffect(() => {
    if (updateFileReq.isError) {
      addAlertToToastTrough({
        message: "failed to update file",
        type: STATUS_KEYS.ERROR
      });
    }
  }, [updateFileReq]);

  const handleRenameFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedFilename(event.target.value);
  };

  return (
    <div className={styles.FileViewer} data-cy="file-viewer">
      {!isReadOnly && (
        <div className={styles.header}>
          <div className={styles.fileName}>
            <FaArrowLeft
              className={styles.backIcon}
              onClick={() => {
                router.back();
                onClose();
              }}
            />
            <div className={styles.filepath}>
              {isEditing ? (
                <Input
                  type="text"
                  name="file_name"
                  id="file_name"
                  label="Change file name"
                  hiddenLabel
                  value={editedFilename}
                  onChange={handleRenameFile}
                  onSubmit={() => {
                    setIsEditing(false);
                    updateFile({
                      fileId: file.file_id,
                      fileUpdateRequest: {
                        name: editedFilename,
                        tags: Object.keys(file.tags).map(t => parseInt(t))
                      }
                    });
                  }}
                />
              ) : (
                <>
                  <span onClick={handleFilepathClick}>{filepath}</span>
                  <Edit
                    onClick={handleFilepathClick}
                    stroke={styles.gray500}
                    width={16}
                    height={16}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.tagsContainer}>
            <div className={styles.tagTitle}>
              <TagIcon stroke={styles.gray500} width={14} height={14} />
              <p>Tags</p>
            </div>
            <div className={styles.verticalRule} />
            <div className={styles.currentTags}>
              {availableTags &&
                Object.keys(file.tags).map(tagIdStr => (
                  <Tag
                    key={tagIdStr}
                    type={STATUS_KEYS.INFO}
                    label={tagsById[parseInt(tagIdStr)].name}
                    onRemove={() => {
                      updateFile({
                        fileId: file.file_id,
                        fileUpdateRequest: {
                          name: file.name,
                          tags: Object.keys(file.tags)
                            .map(k => parseInt(k))
                            .filter(k => k != parseInt(tagIdStr))
                        }
                      });
                    }}
                  />
                ))}
            </div>
            <Controller
              name="select_tag"
              control={control}
              render={({ field: { onChange } }) => (
                <ComboboxSelect
                  label="Location"
                  hiddenLabel
                  labelAcc={(tag: TagListItem) => tag.name}
                  placeholder="Add tag"
                  options={availableTags || []}
                  onChange={(tag: TagListItem) => {
                    if (tag) {
                      onChange(tag.name);
                      updateFile({
                        fileId: file.file_id,
                        fileUpdateRequest: {
                          name: file.name,
                          tags: [
                            ...Object.keys(file.tags).map(k => parseInt(k)),
                            tag.tag_id
                          ]
                        }
                      });
                    }
                  }}
                  clearOnSelect
                />
              )}
            />
          </div>
        </div>
      )}
      <ViewBody fileId={file.file_id}>
        <div className={styles.contents}>{<FileRenderer file={file} />}</div>
      </ViewBody>
    </div>
  );
};

export default FileViewer;
