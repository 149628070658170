import clsx from "clsx";
import styles from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import Input from "@/components/input";
import TextArea from "@/components/textArea";
import OptionsMenu from "@/components/optionsMenu";
import ControlledOptionsField from "@/components/forms/generated/options";

export default function LaborProgressUpdate() {
  const form = useForm();
  const { control } = form;

  const temp_vitals = [
    {
      label: "Heart rate",
      id: "heart_rate"
    },
    {
      label: "Blood pressure",
      id: "blood_pressure"
    },
    {
      label: "Temperature",
      id: "temperate"
    },
    {
      label: "Temperature Location",
      id: "temperate_location"
    },
    {
      label: "Maternal Position",
      id: "maternal_position"
    },
    {
      label: "Intake",
      id: "intake"
    },
    {
      label: "Output",
      id: "output"
    },
    {
      label: "Hydrotherapy",
      id: "hydro"
    },
    {
      label: "Water Temp",
      id: "hydro-temp"
    }
  ];

  const temp_intake_output = [
    {
      label: "Food",
      id: "food"
    },
    {
      label: "Drink",
      id: "drink"
    },
    {
      label: "Void",
      id: "void"
    },
    {
      label: "BM",
      id: "bm"
    },
    {
      label: "Recipient",
      id: "recipient"
    }
  ];

  const maternal_medication = [
    {
      label: "Name",
      id: "medication_name"
    },
    {
      label: "Dose",
      id: "medication_dose"
    },
    {
      label: "Units",
      id: "units"
    },
    {
      label: "Route",
      id: "route"
    },
    {
      label: "Site Given",
      id: "site_given"
    },
    {
      label: "Lot Number",
      id: "medication_lot_number"
    },
    {
      label: "Expiration",
      id: "medication_expiration"
    },
    {
      label: "Indication",
      id: "medication_indication"
    }
  ];

  const contractionFields = [
    { name: "freq", type: "text" },
    { name: "dur", type: "text" },
    { name: "strength", type: "text" }
  ];

  const progressFields = [
    { name: "dil", type: "number" },
    { name: "eff", type: "text" },
    { name: "station", type: "number" }
  ];

  return (
    <form className={styles.ProgressUpdateForm}>
      <div className={clsx(styles.spanTwo, styles.columnContainer)}>
        <fieldset className={clsx(styles.bgLight)}>
          <legend>Entry Information</legend>
          <div className={styles.inputs}>
            <div>
              {/* NOTE: I'm using the native datetime picker because we're migrating
              away from pTime anyway and this is a much better option than what we had before */}
              <Input
                type="datetime-local"
                label="Entry time"
                id="time"
                name="time"
              />
            </div>
            <Input
              type="text"
              label="Author"
              placeholder="Author"
              name="author"
              id="author"
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Maternal Vitals & Assessment</legend>
          <div className={styles.inputs}>
            {temp_vitals.map(item => {
              if (item.id === "maternal_position") {
                return (
                  <div className={styles.fullWidth} key={item.id}>
                    <Input
                      label={item.label as string}
                      type="text"
                      id={item.id}
                      key={item.id}
                      name={item.id}
                      placeholder={item.label}
                      noMaxWidth
                    />
                  </div>
                );
              } else if (item.id === "hydro") {
                return (
                  <ControlledOptionsField
                    label="Hydrotherapy"
                    name="hydro"
                    id="hydro"
                    key="hydro"
                    control={control}
                    isHorizontal={false}
                    options={[
                      { label: "Yes", id: "yes" },
                      { label: "No", id: "no" }
                    ]}
                  />
                );
              } else {
                return (
                  <Input
                    label={item.label as string}
                    type="text"
                    id={item.id}
                    key={item.id}
                    name={item.id}
                    placeholder={item.label}
                    noMaxWidth
                  />
                );
              }
            })}
          </div>
        </fieldset>
      </div>
      <div className={clsx(styles.spanFive, styles.columnContainer)}>
        <fieldset>
          <legend>Fetal Heart Tones</legend>
          <div className={clsx(styles.inputs, styles.fitRow)}>
            <div className={styles.halfWidth}>
              <Input
                label="FHT"
                type="text"
                id="fht"
                name="fht"
                placeholder="FHT"
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Baseline Shift?"
                name="baseline_shift"
                id="baseline_shift"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "Yes", id: "yes" },
                  { label: "No", id: "no" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Relative to contractions"
                name="relative_to_contractions"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "before", id: "before" },
                  { label: "during", id: "during" },
                  { label: "after", id: "after" },
                  { label: "All", id: "all" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Accelerations"
                name="accelerations"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "Yes", id: "yes" },
                  { label: "No", id: "no" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Decelerations"
                name="decelerations"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "Yes", id: "yes" },
                  { label: "No", id: "no" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                options={[
                  { label: "Doppler", id: "doppler" },
                  { label: "Fetoscope", id: "fetoscope" },
                  { label: "EFM", id: "efm" }
                ]}
                label="Listening device"
                name={"listening_device"}
                control={control}
                isHorizontal={false}
              />
            </div>
          </div>
        </fieldset>
        <div className={clsx(styles.columnContainer)}>
          <fieldset className={clsx(styles.spanThree)}>
            <legend className={clsx("t4 dark", styles.sectionTitle)}>
              Contractions
            </legend>
            <div className={styles.inputs}>
              {contractionFields.map(({ name, type }) => (
                <Input
                  key={name}
                  type={type}
                  label={name.replace("_", " ")}
                  name={name}
                  id={name}
                  fullWidth
                  fitWidth
                  noMaxWidth
                />
              ))}
            </div>
          </fieldset>

          {/** Cervical Exam section */}
          <fieldset className={clsx(styles.spanThree)}>
            <legend>Cervical Exam</legend>
            <div className={styles.inputs}>
              {progressFields.map(({ name, type }) => (
                <Input
                  key={name}
                  type={type}
                  label={name.replace("_", " ")}
                  name={name}
                  id={name}
                  fullWidth
                  fitWidth
                />
              ))}
            </div>
          </fieldset>
          <fieldset>
            <legend>Medication</legend>
            <div className={styles.inputs}>
              {maternal_medication.map(item => (
                <Input
                  label={item.label as string}
                  type="text"
                  id={item.id}
                  key={item.id}
                  name={item.id}
                  placeholder={item.label}
                />
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      <fieldset className={styles.spanSix}>
        <legend>Notes / Comments</legend>
        <TextArea label="Notes" hiddenLabel name="notes" id="notes" rows={4} />
      </fieldset>
    </form>
  );
}
