import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    pregnancyUpsert: build.mutation<
      PregnancyUpsertApiResponse,
      PregnancyUpsertApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/pregnancy`,
        method: "POST",
        body: queryArg.pregnancyUpsertRequest
      })
    }),
    listPastPregnancies: build.query<
      ListPastPregnanciesApiResponse,
      ListPastPregnanciesApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/past-pregnancies`
      })
    }),
    pregnancyInfo: build.query<PregnancyInfoApiResponse, PregnancyInfoApiArg>({
      query: queryArg => ({ url: `/pregnancy/${queryArg.pregnancyId}/info` })
    }),
    pregnancyLaborInfo: build.query<
      PregnancyLaborInfoApiResponse,
      PregnancyLaborInfoApiArg
    >({
      query: queryArg => ({ url: `/pregnancy/${queryArg.pregnancyId}/labor` })
    }),
    pregnancyLaborSheet: build.query<
      PregnancyLaborSheetApiResponse,
      PregnancyLaborSheetApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/sheet`
      })
    }),
    pregnancyLaborUpsert: build.mutation<
      PregnancyLaborUpsertApiResponse,
      PregnancyLaborUpsertApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/upsert`,
        method: "POST",
        body: queryArg.laborModel
      })
    }),
    pregnancyLaborEventUpsert: build.mutation<
      PregnancyLaborEventUpsertApiResponse,
      PregnancyLaborEventUpsertApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.laborEvent}/upsert`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    pregnancyLaborEventDelete: build.mutation<
      PregnancyLaborEventDeleteApiResponse,
      PregnancyLaborEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.laborEvent}/delete`,
        method: "DELETE"
      })
    }),
    pregnancyLaborRecurringEventUpdate: build.mutation<
      PregnancyLaborRecurringEventUpdateApiResponse,
      PregnancyLaborRecurringEventUpdateApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    pregnancyLaborRecurringEventCreate: build.mutation<
      PregnancyLaborRecurringEventCreateApiResponse,
      PregnancyLaborRecurringEventCreateApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.scope}/create`,
        method: "PUT",
        body: queryArg.laborEventCreate
      })
    }),
    pregnancyLaborRecurringEventDelete: build.mutation<
      PregnancyLaborRecurringEventDeleteApiResponse,
      PregnancyLaborRecurringEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}/delete`,
        method: "DELETE"
      })
    }),
    listEddHistory: build.query<
      ListEddHistoryApiResponse,
      ListEddHistoryApiArg
    >({
      query: queryArg => ({ url: `/pregnancy/${queryArg.pregnancyId}/edd` })
    }),
    createEdd: build.mutation<CreateEddApiResponse, CreateEddApiArg>({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/edd`,
        method: "POST",
        body: queryArg.estimatedDueDate
      })
    }),
    deleteEdd: build.mutation<DeleteEddApiResponse, DeleteEddApiArg>({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/edd/${queryArg.eddId}`,
        method: "DELETE"
      })
    }),
    updateEdd: build.mutation<UpdateEddApiResponse, UpdateEddApiArg>({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/edd/${queryArg.eddId}`,
        method: "PATCH",
        body: queryArg.estimatedDueDateUpdate
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as pregnancy };
export type PregnancyUpsertApiResponse =
  /** status 200 Sent in response to a successful upsert of a pregnancy.
   */ PregnancyId;
export type PregnancyUpsertApiArg = {
  patientId: UserId;
  /** Payload for creating/updating a pregnancy.
   */
  pregnancyUpsertRequest: PregnancyUpsertRequest;
};
export type ListPastPregnanciesApiResponse =
  /** status 200 List of pregnancies for a patient.
   */ PregnancyList;
export type ListPastPregnanciesApiArg = {
  patientId: UserId;
};
export type PregnancyInfoApiResponse =
  /** status 200 Full information for a single pregnancy.
   */ Pregnancy;
export type PregnancyInfoApiArg = {
  pregnancyId: PregnancyId;
};
export type PregnancyLaborInfoApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor.
   */ LaborModel;
export type PregnancyLaborInfoApiArg = {
  pregnancyId: PregnancyId;
};
export type PregnancyLaborSheetApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor sheet.
   */ LaborSheet;
export type PregnancyLaborSheetApiArg = {
  pregnancyId: PregnancyId;
};
export type PregnancyLaborUpsertApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PregnancyLaborUpsertApiArg = {
  pregnancyId: PregnancyId;
  /** Payload for creating/updating a labor.
   */
  laborModel: LaborModel;
};
export type PregnancyLaborEventUpsertApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type PregnancyLaborEventUpsertApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  laborEvent: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type PregnancyLaborEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PregnancyLaborEventDeleteApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  laborEvent: string;
};
export type PregnancyLaborRecurringEventUpdateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type PregnancyLaborRecurringEventUpdateApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type PregnancyLaborRecurringEventCreateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type PregnancyLaborRecurringEventCreateApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Payload for creating a labor.
   */
  laborEventCreate: LaborEventCreate;
};
export type PregnancyLaborRecurringEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PregnancyLaborRecurringEventDeleteApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
};
export type ListEddHistoryApiResponse =
  /** status 200 List of EDD history for a patient.
   */ EstimatedDueDate[];
export type ListEddHistoryApiArg = {
  pregnancyId: PregnancyId;
};
export type CreateEddApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CreateEddApiArg = {
  pregnancyId: PregnancyId;
  /** The body of the request to create an estimated due date.
   */
  estimatedDueDate: EstimatedDueDate;
};
export type DeleteEddApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DeleteEddApiArg = {
  pregnancyId: PregnancyId;
  eddId: string;
};
export type UpdateEddApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UpdateEddApiArg = {
  pregnancyId: PregnancyId;
  eddId: string;
  /** The body of the request to create an estimated due date.
   */
  estimatedDueDateUpdate: EstimatedDueDateUpdate;
};
export type PregnancyId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type UserId = number;
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type GbsStatus = "POSITIVE" | "NEGATIVE" | "DECLINED";
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type PregnancyUpsertRequest = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  state?: PregnancyState;
  flow_sheet?: PrenatalFlowSheet;
  outcome?: PregnancyOutcome;
  desired_birthplace?: string;
  gbs_status?: GbsStatus;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** In days
   */
  gestational_age_at_delivery?: number;
  length_of_labor_minutes?: number;
  living?: boolean;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  birthplace?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  completed?: number;
  comment?: string;
  is_past_pregnancy?: boolean;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
};
export type PregnancyListItem = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  outcome?: PregnancyOutcome;
  delivery_or_loss_date?: string;
  birthplace?: string;
};
export type PregnancyList = PregnancyListItem[];
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: GbsStatus;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type LaborSheetEventType =
  | "EVENT"
  | "PROGRESS_UPDATE"
  | "MEDICATION"
  | "NOTES"
  | "RECURRING_EVENT"
  | "INFANT_PROGRESS_UPDATE"
  | "INFANT_MEDICATION";
export type LaborEventAuthor = {
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
};
export type LaborEventInfo = {
  event_id: string;
  stage: string;
  type: LaborSheetEventType;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: LaborEventAuthor[];
  forms?: object;
};
export type LaborSheetEvents = LaborEventInfo[];
export type LaborSheet = {
  [key: string]: LaborSheetEvents;
};
export type ErrorId = number;
export type LaborEventUpsertResponse = {
  stage: string;
  labor_event_id?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
};
export type LaborEventUpdate = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  forms?: object;
};
export type IdType =
  | "form"
  | "group"
  | "message"
  | "practice"
  | "patient"
  | "medical_assistant"
  | "administrative"
  | "biller"
  | "location"
  | "pregnancy"
  | "provider"
  | "template"
  | "user"
  | "medication"
  | "progress_update"
  | "note"
  | "infant_medication"
  | "infant_progress_update"
  | "recurring_event";
export type LaborEventCreate = {
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  forms?: object;
};
export type EstimatedDueDateUpdate = {
  method?: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
  /** If true, this will set the new EDD as the active EDD for the pregnancy.
    Does nothing if false.
     */
  set_active?: boolean;
};
export const {
  usePregnancyUpsertMutation,
  useListPastPregnanciesQuery,
  useLazyListPastPregnanciesQuery,
  usePregnancyInfoQuery,
  useLazyPregnancyInfoQuery,
  usePregnancyLaborInfoQuery,
  useLazyPregnancyLaborInfoQuery,
  usePregnancyLaborSheetQuery,
  useLazyPregnancyLaborSheetQuery,
  usePregnancyLaborUpsertMutation,
  usePregnancyLaborEventUpsertMutation,
  usePregnancyLaborEventDeleteMutation,
  usePregnancyLaborRecurringEventUpdateMutation,
  usePregnancyLaborRecurringEventCreateMutation,
  usePregnancyLaborRecurringEventDeleteMutation,
  useListEddHistoryQuery,
  useLazyListEddHistoryQuery,
  useCreateEddMutation,
  useDeleteEddMutation,
  useUpdateEddMutation
} = injectedRtkApi;
