import React, { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface WeightInputProps {
  value: Weight;
  onChange: (value: Weight) => void;
  hiddenLabel?: boolean;
  showGramsConversion?: boolean;
  horizontalLayout?: boolean;
  submit?: () => void;
}

export default function WeightInput({
  value,
  onChange,
  submit,
  hiddenLabel = false,
  showGramsConversion = false,
  horizontalLayout = false
}: WeightInputProps) {
  const [isOzFocused, setIsOzFocused] = useState(false);
  const [isLbsFocused, setIsLbsFocused] = useState(false);
  const [pounds, setPounds] = useState(value?.pounds || 0);
  const [displayedOunces, setDisplayedOunces] = useState(
    (value?.ounces || 0).toFixed(2)
  );

  const handlePoundsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lbs = parseInt(e.target.value.trim()) || 0;
    setPounds(lbs);
    const oz = parseFloat(displayedOunces) || 0;

    onChange({
      pounds: lbs,
      ounces: oz,
      grams: (lbs * 16 + oz) * 28.34952
    });
    submit && submit();
  };

  const handlePoundsBlur = () => {
    setIsLbsFocused(false);
  };

  const handlePoundsFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsLbsFocused(true);
    e.target.select();
  };

  const handleOuncesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayedOunces(e.target.value.trim());
  };

  const handleOuncesBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsOzFocused(false);
    const totalOunces = pounds * 16 + Math.max(0, parseFloat(e.target.value));
    const roundedOunces = Math.round(totalOunces * 100) / 100;
    const newPounds = Math.floor(roundedOunces / 16);
    const newOunces = parseFloat((roundedOunces % 16).toFixed(2));

    setPounds(newPounds);
    setDisplayedOunces(newOunces.toFixed(2));

    onChange({
      pounds: newPounds,
      ounces: newOunces,
      grams: roundedOunces * 28.34952
    });
    submit && submit();
  };

  const handleOuncesFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsOzFocused(true);
    e.target.select();
  };

  useEffect(() => {
    const lbs = pounds;
    const oz = parseFloat(displayedOunces) || 0;
    const grams = (lbs * 16 + oz) * 28.34952;

    // If the value isn't different from the current state, we don't need to update the state
    if (
      (value?.pounds === lbs && value?.ounces === oz) ||
      value?.grams === grams
    ) {
      return;
    }

    if (value?.grams) {
      const totalOunces = parseFloat((value.grams / 28.34952).toFixed(2));
      const calculatedPounds = Math.floor(totalOunces / 16);
      const calculatedOunces = parseFloat((totalOunces % 16).toFixed(2));

      if (!isLbsFocused) {
        setPounds(calculatedPounds);
      }
      if (!isOzFocused) {
        setDisplayedOunces(calculatedOunces.toFixed(2));
      }
    } else {
      if (!isLbsFocused) {
        setPounds(value?.pounds || 0);
      }
      if (!isOzFocused) {
        setDisplayedOunces((value?.ounces || 0).toFixed(2));
      }
    }
  }, [value, isOzFocused, isLbsFocused]);

  return (
    <div className={styles.WeightInput} data-cy="weight_input">
      <div className={clsx({ [styles.horizontalLayout]: horizontalLayout })}>
        <label htmlFor="pounds" style={hiddenLabel ? { display: "none" } : {}}>
          Weight
        </label>
        <div className={styles.weightInputContainer}>
          <div className={styles.inputWithUnit}>
            <input
              type="text"
              id="pounds"
              name="pounds"
              placeholder="lbs"
              min="0"
              required
              onChange={handlePoundsChange}
              value={pounds}
              onBlur={handlePoundsBlur}
              onFocus={handlePoundsFocus}
            />
            <span>lbs</span>
          </div>
          <div className={styles.inputWithUnit}>
            <input
              type="text"
              id="ounces"
              name="ounces"
              placeholder="oz"
              required
              onChange={handleOuncesChange}
              onBlur={handleOuncesBlur}
              onFocus={handleOuncesFocus}
              value={displayedOunces}
            />
            <span>oz</span>
          </div>
        </div>
      </div>
      {showGramsConversion && (
        <p>
          Grams:{" "}
          {((pounds * 16 + parseFloat(displayedOunces)) * 28.34952).toFixed(2)}
        </p>
      )}
    </div>
  );
}

export function getWeightObjectFromGrams(grams: number): Weight {
  if (!grams || isNaN(grams)) {
    return {
      pounds: 0,
      ounces: 0,
      grams: 0
    } as Weight;
  }
  const totalOunces = parseFloat((grams / 28.34952).toFixed(2));
  const pounds = Math.floor(totalOunces / 16);
  const ounces = parseFloat((totalOunces - pounds * 16).toFixed(2));
  const weight: Weight = {
    pounds,
    ounces,
    grams
  };
  return weight;
}

export type Weight = {
  pounds?: number;
  ounces?: number;
  grams?: number;
};
