/* External */
import { ReactElement, useState } from "react";
import clsx from "clsx";

/* Local */

// components
import Icon from "@/components/icons";

// constants
import { METRIC_LABELS, METRICS } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";
import { GetColSpan } from "./helpers";

export interface HeadProps {
  headers: string[];
  hasEmpty?: boolean;
  hasEmptyEnd?: boolean;
  defaultSortHeader?: string;
  unsortedKeys?: string[];
  onSort: ([metric, sortDirection]: [string, sortDirection]) => void;
  selectAllElement?: ReactElement;
}

export const ASC = "ascending";
export const DESC = "descending";

export type sortDirection = "ascending" | "descending";

export default function SortHead({
  headers = [],
  hasEmpty = false,
  defaultSortHeader,
  unsortedKeys = [],
  onSort,
  hasEmptyEnd,
  selectAllElement
}: HeadProps) {
  /* State */
  const [firstMetric] = headers;
  const [[sortMetric, direction], setSort] = useState<[string, sortDirection]>([
    defaultSortHeader || firstMetric,
    ASC
  ]);

  // TODO: call API to sort data - sorting in frontend for now
  const handleSort = (metric: string) => {
    let newDirection = direction;
    // if same button clicked, reverse sort order
    if (sortMetric === metric) {
      newDirection = direction === ASC ? DESC : ASC;
    }
    setSort([metric, newDirection]);
    onSort([metric, newDirection]);
  };

  return (
    <thead>
      <tr>
        {/* empty header */}
        {hasEmpty && (
          <th className={styles.checkboxHeader}>{selectAllElement}</th>
        )}
        {headers &&
          headers.map(metric => (
            // display sort direction for accessibility - none if this header is not the current sort metric
            <th
              key={metric}
              className={clsx({ [styles.highlight]: sortMetric === metric })}
              aria-sort={sortMetric === metric ? direction : "none"}
              data-cy={metric}
              colSpan={GetColSpan(metric)}
            >
              <button
                onClick={() => handleSort(metric)}
                type="button"
                // for screen readers
                aria-label={`Sort by
                  ${METRIC_LABELS[metric] || metric} in
                  ${direction === ASC ? DESC : ASC} order`}
                // title for general accessibility
                title={`Sort by ${METRIC_LABELS[metric] || metric} in ${
                  direction === ASC ? DESC : ASC
                } order`}
                aria-current={sortMetric === metric}
                // sort button is disabled for metrics user should not be allowed to sort
                disabled={[
                  METRICS.NOTE,
                  METRICS.LINK,
                  METRICS.APPOINTMENT_TYPE,
                  ...unsortedKeys
                ].includes(metric)}
              >
                {/* populate button value with metric's display label if it exists, otherwise default to metric  */}
                {METRICS.NOTE === metric ? "" : METRIC_LABELS[metric] || metric}
                {/* screen readers don't need to see icon */}
                <span aria-hidden="true">
                  {sortMetric === metric && (
                    <Icon
                      svg="arrow_down"
                      width={10}
                      height={10}
                      flipped={direction === ASC} // determines the rotation of the icon & allows transition animation
                    />
                  )}
                </span>
              </button>
            </th>
          ))}
        {hasEmptyEnd && <th className={styles.checkboxHeader}></th>}
        {hasEmpty && <th className={styles.collapse}></th>}
      </tr>
    </thead>
  );
}
