// External
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Store
import {
  TaskCreatePayload,
  TaskType,
  useTaskCreateMutation
} from "@/store/services/task";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "@/components/modal/modalSlice";

import { STATUS_KEYS } from "@/globals/constants";

// Components
import TaskTypeForm from "./taskTypeForm";
import TaskInfoForm from "./taskInfoForm";
import Skeleton from "react-loading-skeleton";

interface CreateTaskFlowProps {
  patientId: number;
}

export default function CreateTaskFlow({ patientId }: CreateTaskFlowProps) {
  // Store
  const dispatch = useDispatch();
  const [createTask, createTaskResponse] = useTaskCreateMutation();

  // Local state
  const [formState, setFormState] = useState<
    "TASK_TYPE" | "TASK_INFO" | "LOADING"
  >("TASK_TYPE");
  const [taskType, setTaskType] = useState<TaskType>();

  // Effects
  useEffect(() => {
    if (createTaskResponse.isSuccess) {
      dispatch(
        addAlertToToastTrough({
          message: "Task assigned",
          type: STATUS_KEYS.SUCCESS
        })
      );
      dispatch(setModalIsOpen(false));
    } else if (createTaskResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Error assigning task",
          type: STATUS_KEYS.ERROR
        })
      );
      // Reset whole flow on error
      setFormState("TASK_TYPE");
      setTaskType(undefined);
    } else if (createTaskResponse.isLoading) {
      setFormState("LOADING");
    }
  }, [createTaskResponse]);

  // Handlers
  const handleTaskTypeSubmit = (taskType: TaskType) => {
    // We don't need more info for demographics
    if (taskType === "DEMOGRAPHICS") {
      createTask({
        taskCreatePayload: {
          patient_assignee: patientId,
          task_type: taskType
        }
      });
      return;
    }
    setTaskType(taskType);
    setFormState("TASK_INFO");
  };

  const handleTaskInfoSubmit = (taskInfoPayloads: Array<TaskCreatePayload>) => {
    taskInfoPayloads.forEach((payload: TaskCreatePayload) => {
      createTask({
        taskCreatePayload: payload
      });
    });
  };

  return (
    <>
      {formState === "TASK_TYPE" && (
        <TaskTypeForm onSubmit={handleTaskTypeSubmit} />
      )}
      {formState === "TASK_INFO" && taskType && (
        <TaskInfoForm
          patientId={patientId}
          taskType={taskType}
          onSubmit={handleTaskInfoSubmit}
        />
      )}
      {formState === "LOADING" && (
        <>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </>
      )}
    </>
  );
}
