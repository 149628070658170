// External
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

// Components
import Alert from "@/components/alert";
import Button from "@/components/button";
import PlayIcon from "../../../public/svgs/play.svg";
import StopIcon from "../../../public/svgs/stop.svg";
import LoadingIcon from "../../../public/svgs/loading-icon.svg";

// store
import { RootState } from "@/store/store";
import {
  NablaNote,
  TranscriptItem,
  useGenerateNoteMutation
} from "@/store/services/ai";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { msToTime } from "@/globals/helpers/formatters";
import {
  BACKEND_HOST,
  BACKEND_PORT,
  BACKEND_WEBSOCKET_PROTOCOL
} from "@/globals/constants/environment";

// Helpers
import { AudioRecorderLogger } from "./audioRecorderLogger";
import { getUserMediaStream } from "./helpers";

// styles
import styles from "./styles.module.scss";
import { useReportFrontendErrorMutation } from "@/store/services/system";

interface AudioRecorderProps {
  onNoteGeneration: (
    noteContent: Array<string>,
    rawNoteData: NablaNote
  ) => void;
  onStartTranscribing?: () => void;
  // Todo(Landon): Type for transcript data
  onTranscriptChange: (transcriptData: Array<Record<string, any>>) => void;
  recordingIsDisabled?: boolean;
}

interface AudioState {
  // The context is the parent component for handling audio in the browser. It's composed
  // of nodes that do various things
  context: AudioContext | null;
  // The PCM worker node converts the raw stream data to the PCM format
  pcmWorker: AudioWorkletNode | null;
  // The media stream is the node that connects the actual stream and the audio context
  mediaSource: MediaStreamAudioSourceNode | null;
  // The input stream for media
  stream: MediaStream | null;
}

// A type to facilate that state machine of the audio recorder
type RecorderStatus =
  | "idle"
  | "requesting_permissions"
  | "initializing"
  | "recording"
  | "error";

export default function AudioRecorder({
  onNoteGeneration,
  onStartTranscribing,
  onTranscriptChange,
  recordingIsDisabled = false
}: AudioRecorderProps) {
  // Logger class
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const router = useRouter();

  const [logError] = useReportFrontendErrorMutation();

  const logger = useMemo(() => {
    let encounterId;
    if (router.isReady && sessionInfo) {
      encounterId = parseInt(router.query.encounterId as string);
      return new AudioRecorderLogger(sessionInfo, encounterId, logError);
    } else {
      return null;
    }
  }, [sessionInfo, router, logError]);

  

  // Refs for handling cleanup
  const audioStateRef = useRef<AudioState>({
    context: null,
    pcmWorker: null,
    mediaSource: null,
    stream: null
  });
  const websocketRef = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef<number>(0);

  // Constants
  const MAX_RECONNECT_ATTEMPTS = 3;
  const RAW_PCM_16_WORKER_NAME = "raw-pcm-16-worker";

  // State
  const [status, setStatus] = useState<RecorderStatus>("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [transcriptData, setTranscriptData] = useState<
    Array<Record<string, any>>
  >([]);

  const [isMicrophoneVolumeError, setIsMicrophoneVolumeError] =
    useState<boolean>(false);

  // API
  const [
    generateNote,
    { isSuccess: isNoteSuccess, isLoading: isNoteLoading, data }
  ] = useGenerateNoteMutation();

  const cleanup = useCallback(async () => {
    console.log("Cleaning up audio resources");
    logger?.logEvent("Cleaning up audio resources");

    let cleanupLogMessage = "Cleaning up audio resources. ";

    const { context, pcmWorker, mediaSource, stream } = audioStateRef.current;

    try {
      if (pcmWorker) {
        pcmWorker.port.onmessage = null;
        pcmWorker.port.close();
        pcmWorker.disconnect();
        cleanupLogMessage += "pcmWorker successfully closed and disconnected. ";
      } else {
        cleanupLogMessage += "WARN: pcmWorker was falsey for some reason. "
      }

      if (mediaSource) {
        mediaSource.disconnect();
        cleanupLogMessage +=
          "mediaSource successfully closed and disconnected. ";
      } else {
        cleanupLogMessage += "WARN: mediaSource was falsey for some reason. "
      }

      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        cleanupLogMessage += "All stream tracks stopped. "
      } else {
        cleanupLogMessage += "WARN: stream was falsy for some reason. "
      }

      if (context && context.state !== "closed") {
        await context.close();
        cleanupLogMessage += "Audio Context successfully closed. ";
      } else {
        cleanupLogMessage += "ERROR: Audio Context was falsy for some reason. This is probably an error. "
      }

      if (websocketRef.current) {
        websocketRef.current.close();
        websocketRef.current = null;
        cleanupLogMessage += "WebSocket closed successfully. ";
      } else {
        cleanupLogMessage += "WARN: WebSocket closed prematurely, this could indicate an error with the backend. ";
      }

      audioStateRef.current = {
        context: null,
        pcmWorker: null,
        mediaSource: null,
        stream: null
      };
    } catch (error) {
      console.error("Error during cleanup", error);
      cleanupLogMessage += `There was an error during cleanup: ${error}`
    }

    logger?.logCleanupMessage(cleanupLogMessage)
  }, []);

  // Websocket step
  const setupWebsocket = useCallback(() => {
    if (websocketRef.current) {
      websocketRef.current.close();
    }

    console.log("Setting up Websocket connection");
    logger?.logEvent("Setting up WebSocket connection");
    logger?.logWebSocketConnectionStarted(reconnectAttempts.current);
    const ws = new WebSocket(
      `${BACKEND_WEBSOCKET_PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}/api/v1/transcribe`
    );

    ws.onopen = () => {
      console.log("Websocket connection established");
      logger?.logWebSocketConnectionSuccess();
      reconnectAttempts.current = 0;
    };

    ws.onclose = async event => {
      console.warn("WebSocket connection closed:", event.code);
      logger?.logEvent("WebSocket closed");

      if (status === "recording") {
        // If the websocket closed but the status is recording, we attempt to reconnect
        logger?.logWebSocketDisconnected();
        if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
          console.log("Attempting to reconnect...");
          logger?.logEvent("Attempting to reconnect...");
          reconnectAttempts.current++;
          setupWebsocket();
        } else {
          setErrorMessage("Connection lost. Please try recording again");
          setStatus("error");
          logger?.logWebSocketError(new Error("Connection lost"));
          await cleanup();
        }
      } else {
        logger?.logEvent("WebSocket closed successfully");
        console.log("WebSocket successfully closed");
      }
    };

    ws.onerror = error => {
      console.error("WebSocket error:", error);
      logger?.logWebSocketError(new Error("WebSocket returned an error"));
      setErrorMessage(
        "Connection error. Please check your internet connection"
      );
      setStatus("error");
    };

    ws.onmessage = message => {
      if (typeof message.data === "string") {
        try {
          const data = JSON.parse(message.data);
          console.info("Websocket message data: ", data);
          if (data.object === "transcript_item") {
            if (data.text == "") {
              console.warn("The websocket has returned empty text. Either there's a microphone error or something is wrong in the backend")
            }
            setTranscriptData(prev => {
              const existingIndex = prev.findIndex(item => item.id === data.id);
              if (existingIndex >= 0) {
                const newData = [...prev];
                newData[existingIndex] = data;
                return newData;
              }
              return [...prev, data];
            });
          }
        } catch (error) {
          logger?.logEvent("WebSocket sent malformed message");
          console.error("Error processing WebSocket message:", error);
        }
      }
    };

    websocketRef.current = ws;
  }, [status]);

  const initializeAudioRecording = useCallback(async () => {
    try {
      setStatus("requesting_permissions");
      console.log("Requesting microphone permissions...");

      // First log general info about the user's audio inputs (this will prompt for permissions if needed)
      logger?.logInitialMicrophoneData();

      // Then actually attempt to get reference to the proper microphone that will be used.
      // This will throw more nuanced errors if necessary
      let stream: MediaStream;
      try {
        stream = await getUserMediaStream();
      } catch (error: any) {
        console.error(error);
        setErrorMessage(
          "Something went wrong with your microphone, please check your microphone settings and try again."
        );
        logger?.logMicrophoneFailure(error.message);
        throw new Error(error.message);
      }

      // We forsure have a microphone at this point so we can log a success
      logger?.logMicrophoneSuccess();

      setStatus("initializing");
      console.log("Initializing audio context...");

      const context = new AudioContext({ sampleRate: 16000 });
      const url = new URL("rawPcm16Processor.js", import.meta.url);

      await context.audioWorklet.addModule(url);

      const pcmWorker = new AudioWorkletNode(context, RAW_PCM_16_WORKER_NAME, {
        outputChannelCount: [1],
        processorOptions: {
          debug: true
        }
      });

      pcmWorker.port.onmessage = msg => {
        if (websocketRef.current?.readyState !== WebSocket.OPEN) {
          console.warn("WebSocket not ready for audio data");
          return;
        }

        if (msg.data.type === "warning") {
          // This will get sent once per recording session
          if (msg.data.message === "mic_volume_too_low") {
            logger?.logEvent("Microphone volume too low warning given");
            setIsMicrophoneVolumeError(true);
            return;
          }
        }

        if (msg.data instanceof Int16Array) {
          setIsMicrophoneVolumeError(false);
        }

        const pcm16iSamples = msg.data;

        const audioAsBase64String = btoa(
          String.fromCodePoint(...new Uint8Array(pcm16iSamples.buffer))
        );

        websocketRef.current.send(
          JSON.stringify({
            object: "audio_chunk",
            payload: audioAsBase64String,
            stream_id: "stream1"
          })
        );
      };

      pcmWorker.port.start();

      const mediaSource = context.createMediaStreamSource(stream);
      mediaSource.connect(pcmWorker);

      // store all the audio-related objects in a ref
      audioStateRef.current = {
        context,
        pcmWorker,
        mediaSource,
        stream
      };

      setStatus("recording");
      console.log("Recording started successfully");
      logger?.logEvent("Recording started successfully");
    } catch (error) {
      console.error("Error initializing audio: ", error);

      if (error instanceof DOMException) {
        switch (error.name) {
          case "NotAllowedError":
            setErrorMessage(
              "Microphone access denied. Please allow microphone access and try again."
            );
            break;
          case "NotFoundError":
            setErrorMessage(
              "No microphone found. Please connect a microphone and try again."
            );
            break;
          default:
            setErrorMessage(
              "An error occurred while accessing the microphone."
            );
        }
      } else {
        setErrorMessage(
          "An unexpected error occurred while setting up the recording."
        );
      }

      logger?.logAudioConnectionError(errorMessage);

      setStatus("error");
      await cleanup();
    }
  }, [cleanup]);

  // Start recording
  const startRecording = useCallback(async () => {
    setTranscriptData([]);
    setRecordingTime(0);
    setErrorMessage(null);

    setupWebsocket();
    await initializeAudioRecording();
  }, [setupWebsocket, initializeAudioRecording]);

  // Stop recording
  const stopRecording = useCallback(async () => {
    console.log("Stopping recording...");
    logger?.logEvent("Stopping recording...");
    if (websocketRef.current?.readyState === WebSocket.OPEN) {
      websocketRef.current.send(JSON.stringify({ object: "end" }));
    }

    await cleanup();
    setStatus("idle");
  }, [cleanup]);

  // Recording timer
  useEffect(() => {
    let interval: number;

    if (status === "recording") {
      interval = window.setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    }

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [status]);

  // clean up on unmount
  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  // Update transcript
  useEffect(() => {
    onTranscriptChange(transcriptData);
  }, [transcriptData, onTranscriptChange]);

  useEffect(() => {
    if (!data || !data.note || !data.note.sections) {
      return;
    }

    const content = data.note.sections.map((section: any) => {
      let contentStr = `<p class="ph-no-capture"><strong>${section.title}</strong></p><ul>`;
      const bulletPoints = section.text.split("\n");
      bulletPoints.forEach((point: string) => {
        if (point.charAt(0) === "-") {
          contentStr += `<li class="ph-no-capture">${point.substring(1)}</li>`;
        } else {
          contentStr += `<li class="ph-no-capture">${point}</li>`;
        }
      });
      contentStr += "</ul>";
      return contentStr;
    });

    onNoteGeneration(content, data.note);
  }, [isNoteSuccess]);

  const handleButtonClick = async () => {
    if (status === "recording") {
      await stopRecording();
    } else {
      onStartTranscribing && onStartTranscribing();
      await startRecording();
    }
  };

  const handleGenerateNote = () => {
    if (status === "recording") {
      stopRecording();
    }

    // If we've already fired the generate trigger, don't fire
    // it again
    if (isNoteLoading) {
      return;
    }

    const sanitizedData = transcriptData.filter(
      item => item.object == "transcript_item"
    );

    generateNote({
      generateNoteRequest: {
        transcript_items: sanitizedData as TranscriptItem[]
      }
    });
  };

  // Rendering variables
  const isRecording = status === "recording";
  const isInitializing =
    status === "requesting_permissions" || status === "initializing";

  const isGenerationDisabled = () => {
    if (isNoteLoading || !transcriptData.length) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.recordingContainer}>
      {errorMessage && (
        // TODO(Landon): Update Alert styles to include ERROR
        <Alert type={STATUS_KEYS.WARNING} message={errorMessage} />
      )}
      {isMicrophoneVolumeError && (
        <Alert
          type={STATUS_KEYS.WARNING}
          message={
            "No audio detected. If this seems like a mistake, please check your microphone settings."
          }
        />
      )}
      <div className={styles.buttonContainer}>
        <Button
          onClick={handleButtonClick}
          style={STYLES.SECONDARY}
          nativeButtonProps={{
            disabled: recordingIsDisabled || isInitializing
          }}
        >
          {isRecording ? (
            <>
              {msToTime(recordingTime * 1000)}
              <span className={styles.stopIcon}>
                <StopIcon />
              </span>
            </>
          ) : (
            <>
              {isInitializing ? "Initializing..." : "Start recording"}
              <span className={styles.playIcon}>
                <PlayIcon />
              </span>
            </>
          )}
        </Button>
        <Button
          onClick={handleGenerateNote}
          style={STYLES.SECONDARY}
          nativeButtonProps={{ disabled: isGenerationDisabled() }}
        >
          {isNoteLoading ? (
            <>
              <LoadingIcon className={styles.loadingIcon} /> Generating...
            </>
          ) : (
            "Generate Note"
          )}
        </Button>
      </div>
    </div>
  );
}
