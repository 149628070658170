// External
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// Globals, constants, helpers
import { METRICS } from "@/globals/constants";

// Components
import Button from "@/components/button";
import Datagrid, { GridDataType } from "@/components/datagrid";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Input from "@/components/input";
import { setIds } from "@/components/datagrid/datagridSlice";
import Pagination from "@/components/pagination";

// Store
import { RootState } from "@/store/store";
import {
  TaskCreatePayload,
  useTaskListAssignablesQuery
} from "@/store/services/task";
import { TaskType } from "@/store/services/encounter";

// Styles
import styles from "../../styles.module.scss";

interface TaskInfoFormProps {
  taskType: TaskType;
  patientId: number;
  onSubmit: (taskCreatePayload: Array<TaskCreatePayload>) => void;
}

export default function TaskInfoForm({
  taskType,
  patientId,
  onSubmit
}: TaskInfoFormProps) {
  const dispatch = useDispatch();

  const selectedRowIds = useSelector(
    (state: RootState) => state.dataGrid.selectedRowIds
  );

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  // Non-reactive page size constant, change this to change the amount of
  // forms per page
  const pageSize = 20;

  const form = useForm();
  const { register, handleSubmit } = form;

  const { data: assignables } = useTaskListAssignablesQuery({
    page: page - 1,
    pagesz: pageSize,
    search: search
  });

  const availableForms: GridDataType = useMemo(() => {
    if (!assignables) return [];

    if (!assignables.items || assignables.items.length === 0) return [];

    return assignables.items
      .map(item => ({
        id: item.resource_id,
        metrics: {
          task_type: item.resource_type,
          resource_id: item.resource_id,
          form_title: item.display
        }
      }))
      .sort((a, b) => a.metrics.form_title.localeCompare(b.metrics.form_title));
  }, [assignables]);

  const [selectedForms, setSelectedForms] = useState<GridDataType>([]);

  const handleFormSelected = (id: number) => {
    if (selectedRowIds.indexOf(id) === -1) {
      const newForm = availableForms.find(form => form.id === id);
      if (!newForm) return;
      setSelectedForms(prev => [...prev, newForm]);
    } else {
      setSelectedForms(prev => prev.filter(form => form.id !== id));
    }
  };

  const onUploadSubmit = (data: any) => {
    onSubmit([
      {
        task_type: "UPLOAD",
        patient_assignee: patientId,
        upload: {
          prompt: data.taskDescription
        }
      }
    ]);
  };

  const onGenericSubmit = (data: any) => {
    onSubmit([
      {
        task_type: "GENERIC",
        patient_assignee: patientId,
        generic: {
          task_text: data.taskDescription,
          task_title: data.taskTitle
        }
      }
    ]);
  };

  const handleFormSubmit = () => {
    const payloads: Array<TaskCreatePayload> = selectedForms.map(form => ({
      task_type: form.metrics.task_type as TaskType,
      patient_assignee: patientId,
      ...(form.metrics.task_type === "FORM"
        ? { form: { form_id: form.metrics.resource_id as number } }
        : { file: { template_file_id: form.metrics.resource_id as number } })
    }));

    onSubmit(payloads);
    dispatch(setIds([]));
    setSelectedForms([]); // Clear selected forms after submission
  };

  switch (taskType) {
    case "FORM":
    case "FILE":
      return (
        <div
          className={styles.TaskCreationInfo}
          data-cy="task-creation-info-modal"
        >
          <div className={styles.titleContainer}>
            <p>
              <strong>Which forms would you like to assign?</strong>
            </p>
            <Input
              type="text"
              label="Search"
              placeholder="Search..."
              hiddenLabel
              id="search"
              name="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
          <Datagrid
            data={availableForms}
            gridType={METRICS.FORM_LIST}
            selectableRows
            onCheckboxClick={handleFormSelected}
            selectAllEnabled
          />
          <Pagination
            onClick={setPage}
            selectedPage={page}
            nextEnabled={assignables?.has_next_page}
            pages={
              assignables?.total_items &&
              Math.ceil(assignables.total_items / pageSize)
            }
            oneIndexed={true}
          />
          <div className={styles.buttonContainer}>
            <Button
              type="button"
              onClick={() => handleFormSubmit()}
              nativeButtonProps={{ disabled: selectedRowIds.length === 0 }}
            >
              Submit
            </Button>
          </div>
        </div>
      );
    case "UPLOAD":
      return (
        <div
          className={styles.TaskCreationInfo}
          data-cy="task-creation-info-modal"
        >
          <form onSubmit={handleSubmit(onUploadSubmit)}>
            <ControlledTextArea
              label="What should they upload?"
              placeholder="Enter instructions here"
              name="taskDescription"
              required
              id="taskDescription"
              form={form}
            />
            <div className={styles.buttonCotainer}>
              <Button type="submit">Create Task</Button>
            </div>
          </form>
        </div>
      );
    case "GENERIC":
      return (
        <div
          className={styles.TaskCreationInfo}
          data-cy="task-creation-info-modal"
        >
          <form onSubmit={handleSubmit(onGenericSubmit)}>
            <Input
              type="text"
              name="taskTitle"
              id="taskTitle"
              label="Title of the task"
              register={register}
            />
            <ControlledTextArea
              label="What should they do?"
              placeholder="Enter instructions here"
              name="taskDescription"
              required
              id="taskDescription"
              form={form}
            />
            <div className={styles.buttonContainer}>
              <Button type="submit">Create Task</Button>
            </div>
          </form>
        </div>
      );
    case "DEMOGRAPHICS":
      throw new Error(
        "taskCreationInfo should not be used for demographics tasks"
      );
  }
}
