/* LabFlowsheetFileViewer Name */
/* External Imports */
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

/* Local Imports */

// components
import FileRenderer from "@/components/fileExplorer/fileRenderer";
import FileAnnotationsReadOnly from "@/components/fileAnnotations/fileAnnotationListReadOnly";
import AnimatedSidebar from "@/components/common/animatedSidebar";
import FileViewerNav from "./fileViewerNav";
import FileNavigation from "./fileNavigation";
import FileQuestion from "../../../../../../public/svgs/file-question-02.svg";

// constants

// store
import {
  FileId,
  useFilesQuery,
  FileListItem,
  useFileListQuery
} from "@/store/services/file";
import { UserId } from "@/store/services/patient";
import {
  LabFlowsheetId,
  LabFlowsheetTemplateId,
  useLabFlowsheetAttachFileMutation,
  useLabFlowsheetCreateMutation,
  useLabFlowsheetRemoveFileMutation
} from "@/store/services/lab";
import { RootState } from "@/store/store";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

/* LabFlowsheetFileViewer Typescript Interface */
interface LabFlowsheetFileViewerProps {
  attachedFileIds?: FileId[];
  patientId: UserId;
  labFlowsheetId?: LabFlowsheetId;
  activeFlowsheetTemplateId: LabFlowsheetTemplateId;
}

export default function LabFlowsheetFileViewer({
  attachedFileIds,
  patientId,
  labFlowsheetId,
  activeFlowsheetTemplateId
}: LabFlowsheetFileViewerProps) {
  /* Redux */
  const { selectedDayResultsDate } = useSelector(
    (state: RootState) => state.labFlowsheet
  );
  const { data: attachedFiles } = useFilesQuery({ fileIds: attachedFileIds });
  const { data: patientLabFiles, isLoading: isFilesLoading } = useFileListQuery(
    {
      id: patientId,
      scope: "patient",
      tagName: "labs"
    }
  );

  const [attachFileToLabFlowsheet] = useLabFlowsheetAttachFileMutation();
  const [removeFileFromLabFlowsheet] = useLabFlowsheetRemoveFileMutation();
  const [createFlowsheet] = useLabFlowsheetCreateMutation();

  /* Local State */
  const [selectedFile, setSelectedFile] = useState<FileListItem>();
  const [showAnnotations, setShowAnnotations] = useState(false);
  const [showFileList, setShowFileList] = useState(true);

  /* Memoized Values */

  const unattachedPatientLabFiles = useMemo(() => {
    if (!patientLabFiles || !attachedFiles?.length) return patientLabFiles;
    // Filter out lab files that are already attached
    return patientLabFiles.filter(
      labFile =>
        !attachedFiles.find(
          attachedFile => attachedFile.file_id === labFile.file_id
        )
    );
  }, [patientLabFiles, attachedFiles]);

  /* Effects */

  // Default render the first file in the list of attached files
  useEffect(() => {
    if (attachedFiles && attachedFiles.length > 0) {
      const firstFile = attachedFiles[0];
      setSelectedFile(firstFile);
    }
  }, [attachedFiles]);

  /* Event Handlers */

  const toggleShowAnnotations = () => setShowAnnotations(!showAnnotations);
  const toggleShowFileList = () => setShowFileList(!showFileList);

  const attachFile = (file: FileListItem, labFlowsheetId: LabFlowsheetId) => {
    attachFileToLabFlowsheet({
      labFlowsheetId,
      labFlowsheetFileAttach: {
        file_date: selectedDayResultsDate,
        file_id: file.file_id
      }
    })
      .unwrap()
      .then(() => {
        console.log("file added successfully");
        addAlertToToastTrough({
          message: "File added successfully",
          type: "success"
        });
      })
      .catch(() => {
        console.error("Failed to add file");
        addAlertToToastTrough({
          message: "Failed to add file",
          type: "error"
        });
      });
  };

  const handleRemoveFile = (file: FileListItem) => {
    // By the time this function is called, labFlowsheetId will always be defined
    removeFileFromLabFlowsheet({
      labFlowsheetId: labFlowsheetId as LabFlowsheetId,
      labFlowsheetFileAttach: {
        file_date: selectedDayResultsDate,
        file_id: file.file_id
      }
    })
      .unwrap()
      .then(() => {
        console.log("file removed successfully");
        addAlertToToastTrough({
          message: "File removed successfully",
          type: "success"
        });
      })
      .catch(() => {
        console.error("Failed to remove file");
        addAlertToToastTrough({
          message: "Failed to remove file",
          type: "error"
        });
      });
  };

  const handleAttachFile = (file: FileListItem) => {
    if (!labFlowsheetId) {
      createFlowsheet({
        patientId,
        labFlowsheetCreateParams: { template_id: activeFlowsheetTemplateId }
      })
        .unwrap()
        .then(res => {
          attachFile(file, res.lab_flowsheet_id);
        })
        .catch(() => {
          console.error("Failed to create flowsheet");
          addAlertToToastTrough({
            message: "Failed to create flowsheet",
            type: "error"
          });
        });
    } else {
      attachFile(file, labFlowsheetId);
    }
  };

  return (
    <div className={styles.LabFlowsheetFileViewer}>
      <FileViewerNav
        showAnnotations={showAnnotations}
        showFileList={showFileList}
        selectedFileName={selectedFile?.name}
        onToggleShowAnnotations={toggleShowAnnotations}
        onToggleShowFileList={toggleShowFileList}
      />

      <div className={styles.contentWrapper}>
        <AnimatedSidebar show={showFileList}>
          <FileNavigation
            attachedFiles={attachedFiles}
            unattachedFiles={unattachedPatientLabFiles}
            selectedFileId={selectedFile?.file_id}
            onFileSelect={setSelectedFile}
            onAttachFile={handleAttachFile}
            onRemoveFile={handleRemoveFile}
          />
        </AnimatedSidebar>
        <motion.div
          className={styles.renderer}
          layout="position"
          transition={{ duration: 0.3 }}
        >
          {selectedFile ? (
            <FileRenderer file={selectedFile} />
          ) : (
            <div
              className={styles.noFileSelected}
              data-testid="no-file-selected"
            >
              <div className={styles.content}>
                <FileQuestion
                  stroke={styles.gray400}
                  width={160}
                  strokeWidth={1}
                />
                <h3 className="gray400">No file selected</h3>
                <p className="xLight">
                  Click the <span className={styles.spanDecorator}>Files</span>{" "}
                  button to select files
                </p>
              </div>
            </div>
          )}
        </motion.div>
        <AnimatedSidebar show={showAnnotations}>
          <FileAnnotationsReadOnly fileId={selectedFile?.file_id} />
        </AnimatedSidebar>
      </div>
    </div>
  );
}
