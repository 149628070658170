import Button from "@/components/button";
import Input from "@/components/input";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import React from "react";
import { useForm } from "react-hook-form";
import styles from "../styles.module.scss";
import { useDispatch } from "react-redux";
import { setModalIsOpen } from "../modalSlice";
import { usePayorCreateMutation } from "../../../store/services/generated/payor";
import Skeleton from "react-loading-skeleton";
import { Address } from "@/store/services/patient";
import { setPayorId } from "@/components/forms/insuranceSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import {
  rightPaneContentGoBack,
  setRightPaneContent
} from "@/components/drawer/drawerSlice";
import { MODAL_TYPES } from "../dispatcher";

type PayorFormData = {
  name: string;
  phoneNumber: string;
  address: {
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
  };
};

interface AddNewPayorModalProps {
  // If this is invoked in the drawer
  isDrawer?: boolean;
}

function AddNewPayorModal({ isDrawer = false }: AddNewPayorModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<PayorFormData>();

  const dispatch = useDispatch();

  const [createPayor, createPayorResponse] = usePayorCreateMutation();

  const onSubmit = (data: PayorFormData) => {
    // Handle form submission here
    createPayor({
      payorCreateRequest: {
        name: data.name,
        address: data.address as Address,
        payor_phone: data.phoneNumber
      }
    })
      .unwrap()
      .then(payorId => {
        if (payorId) {
          dispatch(setPayorId(payorId));
          if (isDrawer) {
            dispatch(rightPaneContentGoBack({}));
          } else {
            dispatch(setModalIsOpen(false));
          }
          dispatch(
            addAlertToToastTrough({
              type: STATUS_KEYS.SUCCESS,
              message: "Successfully added the payor."
            })
          );
        } else {
          dispatch(
            addAlertToToastTrough({
              type: STATUS_KEYS.ERROR,
              message: "Something went wrong adding the payor."
            })
          );
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            type: STATUS_KEYS.ERROR,
            message: "Something went wrong adding the payor."
          })
        );
      });
  };

  const onClose = () => {
    if (isDrawer) {
      dispatch(rightPaneContentGoBack({}));
    } else {
      dispatch(setModalIsOpen(false));
    }
  };

  return (
    <div className={styles.AddNewPayorModal}>
      {createPayorResponse.isLoading ? (
        <>
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.wrapper}>
            <div className="field">
              <Input
                type="text"
                name="name"
                register={register}
                label="Payor name"
                id="name"
                required
                error={errors.name && "Field is required"}
              />
            </div>

            <div className="field">
              <Input
                type="text"
                name="phoneNumber"
                register={register}
                label="Payor phone number"
                id="phoneNumber"
              />
            </div>

            <Input
              type="text"
              name="address.line1"
              register={register}
              label="Address line 1"
              id="address.line1"
              required
              error={errors.address?.line1 && "Field is required"}
            />
            <Input
              type="text"
              name="address.line2"
              register={register}
              label="Address line 2"
              id="address.line2"
            />
            <Input
              type="text"
              name="address.line3"
              register={register}
              label="Address line 3"
              id="address.line3"
            />
            <Input
              type="text"
              name="address.city"
              register={register}
              label="City"
              id="address.city"
              required
              error={errors.address?.city && "Field is required"}
            />
            <Input
              type="text"
              name="address.state"
              register={register}
              label="State"
              id="address.state"
              required
              error={errors.address?.state && "Field is required"}
            />
            <Input
              type="text"
              name="address.zip"
              register={register}
              label="Zip code"
              id="address.zip"
              required
              error={errors.address?.zip && "Field is required"}
            />
            {errors.address && (
              <p className="help is-danger">{errors.address.message}</p>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button type="button" style={STYLES.SECONDARY} onClick={onClose}>
              {isDrawer ? "Back" : "Cancel"}
            </Button>

            <Button type="submit" style={STYLES.PRIMARY}>
              Create
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default AddNewPayorModal;
