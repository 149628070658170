/* StaffPicker  */
/* External Imports */
import { useMemo } from "react";
import Select, { StylesConfig, GroupBase } from "react-select";

/* Local Imports */

// components
import { FORMAT } from "@/globals/helpers/formatters";

// constants

// store
import { useStaffUserListQuery } from "@/store/services/staff";
import { StaffInfo } from "@/store/services/generated/staff";

import { UserId } from "@/store/services/user";

// styles
import {
  SelectCompactStyles,
  SelectCompactTheme,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

// Type the styles configuration
const typedSelectStyles: StylesConfig<
  StaffOptionType,
  false,
  GroupBase<StaffOptionType>
> = {
  ...SelectCompactStyles
} as StylesConfig<StaffOptionType, false, GroupBase<StaffOptionType>>;

export type StaffOptionType = {
  value: StaffInfo;
  label: string;
};

/* StaffPicker Typescript Interface */
interface StaffPickerProps {
  initialStaffId?: UserId;
  onUpdateStaff: (staffId?: UserId) => void;
  isCompact?: boolean;
  isClearable?: boolean;
  // TRUE if only schedulable staff should be shown
  // FALSE if only non-schedulable staff should be shown
  // undefined if all staff should be shown
  isSchedulableStaff?: boolean;
}

export default function StaffPicker({
  initialStaffId,
  onUpdateStaff,
  isCompact = false,
  isClearable = false,
  isSchedulableStaff
}: StaffPickerProps) {
  /* Redux */
  const { data: staffList } = useStaffUserListQuery({
    isSchedulable: isSchedulableStaff,
    includeSuperadmins: false,
    includeInactive: false
  });

  /* Local State */

  // memoize the staff options
  const options: StaffOptionType[] = useMemo(() => {
    if (!staffList) return [];

    return staffList.map(staff => ({
      value: staff,
      label: FORMAT.name(staff)
    }));
  }, [staffList]);

  /* Effects */

  /* Event Handlers */

  return (
    <Select<StaffOptionType, false>
      options={options}
      value={
        initialStaffId
          ? options.find(option => option.value.user_id === initialStaffId)
          : null
      }
      onChange={selectedOption => {
        onUpdateStaff(
          selectedOption ? selectedOption.value.user_id : undefined
        );
      }}
      isClearable={isClearable}
      theme={isCompact ? SelectCompactTheme : SelectDefaultTheme}
      styles={isCompact ? typedSelectStyles : {}}
      placeholder="Select staff..."
    />
  );
}
