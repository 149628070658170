/* LabFlowsheetForm Name */
/* External Imports */
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";

/* Local Imports */

// components
import Button from "@/components/button";
import Icon from "@/components/icons";
import Input from "@/components/input";
import BasicAccordion from "@/components/accordions/basic";
import Delete from "../../../../../public/svgs/delete_dynamic.svg";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import {
  LabFlowsheetId,
  LabFlowsheetInsertEntry,
  LabFlowsheetResultsByDay,
  LabFlowsheetTemplateId,
  LabFlowsheetUpdateEntry,
  LabFlowsheetEntryId,
  useLabFlowsheetCreateMutation,
  useLabFlowsheetInsertEntriesMutation,
  useLabFlowsheetUpdateEntriesMutation,
  useLabFlowsheetDeleteDayMutation,
  LabFlowsheetTemplateTest,
  useLabFlowsheetReadQuery,
  LabFlowsheetTemplateAnalyte,
  useLabFlowsheetUpdateDateMutation,
  useLabFlowsheetDeleteEntriesMutation,
  LabFlowsheetUpdateDateApiArg
} from "@/store/services/lab";
import { UserId } from "@/store/services/patient";
import { RootState } from "@/store/store";
import { setSelectDayResultsDate } from "@/components/labFlowSheet/slice";
// styles
import styles from "./styles.module.scss";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { ConfirmUpdateLabFlowsheetDateProps } from "@/components/modal/templates/confirmUpdateLabFlowsheetDate";
import Error from "next/error";

/* LabFlowsheetForm Typescript Interface */
export interface LabFlowsheetFormProps {
  activeFlowsheetTemplateId: LabFlowsheetTemplateId;
  patientId: UserId;
  templateTests: LabFlowsheetTemplateTest[];
  dayResults?: LabFlowsheetResultsByDay;
  labFlowsheetId?: LabFlowsheetId;
}

export default function LabFlowsheetForm({
  activeFlowsheetTemplateId,
  patientId,
  templateTests,
  dayResults,
  labFlowsheetId
}: LabFlowsheetFormProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { selectedDayResultsDate } = useSelector(
    (state: RootState) => state.labFlowsheet
  );

  const [createLabFlowsheet] = useLabFlowsheetCreateMutation();
  const [createEntries, { isLoading: isCreating }] =
    useLabFlowsheetInsertEntriesMutation();
  const [updateEntries, { isLoading: isUpdating }] =
    useLabFlowsheetUpdateEntriesMutation();
  const [deleteEntriesAndRemoveFiles, { isLoading: isDeleting }] =
    useLabFlowsheetDeleteDayMutation();
  const [updateDate] = useLabFlowsheetUpdateDateMutation();
  const [deleteEntries, { isLoading: isDeletingEntries }] =
    useLabFlowsheetDeleteEntriesMutation();

  const { data: labFlowsheet } = useLabFlowsheetReadQuery(
    {
      labFlowsheetId: labFlowsheetId as LabFlowsheetId
    },
    { skip: !labFlowsheetId }
  );

  /* Local State */

  /* Memoized Variables */
  const [dayResultsDate, dayResultsTests] = useMemo(() => {
    if (dayResults) {
      return [dayResults.date, dayResults.tests];
    }
    return [dayjs().format("YYYY-MM-DD"), []];
  }, [dayResults]);

  /* Event Handlers */
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.currentTarget));

    // Create a type for the dynamic keys
    type LabFlowsheetFormKey =
      `${string}--${string}--${LabFlowsheetTemplateAnalyte["analyte_id"]}--${string}`;

    // Make all properties optional using Partial
    type LabFlowsheetFormData = Partial<
      {
        test_date: string;
      } & {
        [K in LabFlowsheetFormKey]: string;
      }
    >;

    // identify unique analytes
    const uniqueAnalytes = new Set(
      Object.keys(data).map(key => {
        const [testName, analyteName, attribute, entryId] = key.split("--");
        return `${testName}--${analyteName}--${entryId}`;
      })
    );

    const labFlowsheetInsertEntries: LabFlowsheetInsertEntry[] = [];
    const labFlowsheetUpdateEntries: LabFlowsheetUpdateEntry[] = [];
    const labFlowsheetDeleteEntries: LabFlowsheetEntryId[] = []

    // Populate the labFlowsheetEntries array
    for (const uniqueAnalyte of uniqueAnalytes) {
      const [testName, analyteName, entryId] = uniqueAnalyte.split("--");
      if (!testName || !analyteName || testName === "test_date") {
        continue;
      }

      const date = data["test_date"].toString();
      const formattedDate = dayjs(date).format("YYYY-MM-DD");

      const entry: LabFlowsheetInsertEntry = {
        test_name: testName,
        entry_date: formattedDate,
        analyte_name: analyteName,
        interpretation:
          data[
            `${testName}--${analyteName}--interpretation--${entryId}`
          ].toString().trim() || "",
        analyte_value:
          data[`${testName}--${analyteName}--value--${entryId}`].toString().trim() ||
          "",
        analyte_units:
          data[`${testName}--${analyteName}--units--${entryId}`].toString().trim(),
        from_health_gorilla: false
      };

      // If entryId is "new", it means the entry is new and should be created
      if (entryId === "new" && entry.analyte_value) {
        labFlowsheetInsertEntries.push(entry);
      } else if (entry.analyte_value) {
        // If entryId is not "new", it means the entry is an existing entry and should be updated
        labFlowsheetUpdateEntries.push({
          entry_id: entryId,
          ...entry
        });
      } else if (entryId !== "new" && !entry.analyte_value.trim()) {
        // entry is not new and has no value, so it should be deleted
        labFlowsheetDeleteEntries.push(entryId)
      }
    }

    // delete entries that have no value
    if (labFlowsheetDeleteEntries.length) {
      try {
        await deleteEntries({
          labFlowsheetId: labFlowsheetId as LabFlowsheetId,
          body: labFlowsheetDeleteEntries
        }).unwrap();
      } catch (error) {
        onError("Failed to delete entries");
        return;
      }
    }

    const handleLabFlowsheetOperation = async (
      newFlowsheetId?: LabFlowsheetId
    ) => {
      try {
        if (labFlowsheetId) {
          // if there are already analytes for the day, update them
          if (dayResults?.tests?.length && labFlowsheetUpdateEntries.length) {
            const updateLabFlowsheetEntry = {
              labFlowsheetId: labFlowsheetId as LabFlowsheetId,
              body: labFlowsheetUpdateEntries
            };

            await updateEntries(updateLabFlowsheetEntry).unwrap();
          }

          if (labFlowsheetInsertEntries.length) {
            // if there are no analytes for the day, create them
            // there could be just files or no data at all
            // or if there are new analytes, create them
            const createLabFlowsheetEntry = {
              labFlowsheetId: labFlowsheetId as LabFlowsheetId,
              body: labFlowsheetInsertEntries
            };

            await createEntries(createLabFlowsheetEntry).unwrap();
          }
        } else {
          const createLabFlowsheetEntry = {
            labFlowsheetId: newFlowsheetId as LabFlowsheetId,
            body: labFlowsheetInsertEntries
          };

          await createEntries(createLabFlowsheetEntry).unwrap();
        }

        onSuccess();
        addAlertToToastTrough({
          message: dayResults
            ? "Lab Flowsheet entry updated"
            : "Lab Flowsheet entry created",
          type: STATUS_KEYS.SUCCESS
        });
      } catch (error:
        | Error<{
            data: {
              user_facing: boolean;
              extra_data: {
                message: string;
              }[];
            };
          }>
        | any) {
        let errorMessage = dayResults
          ? "Failed to update lab flowsheet entry"
          : "Failed to create lab flowsheet entry";
        const hasCustomMessage = error.data?.user_facing;
        if (hasCustomMessage) {
          if (error.data.extra_data?.[0].message) {
            errorMessage = error.data.extra_data[0].message;
          }
        }
        onError(errorMessage);
      }
    };

    if (!labFlowsheetId) {
      try {
        const { lab_flowsheet_id } = await createLabFlowsheet({
          patientId,
          labFlowsheetCreateParams: { template_id: activeFlowsheetTemplateId }
        }).unwrap();
        addAlertToToastTrough({
          message: "Lab Flowsheet created",
          type: STATUS_KEYS.SUCCESS
        });
        // Retry the original operation
        await handleLabFlowsheetOperation(lab_flowsheet_id);
      } catch (createError) {
        addAlertToToastTrough({
          message: "Failed to create lab flowsheet",
          type: STATUS_KEYS.ERROR
        });
      }
    } else {
      await handleLabFlowsheetOperation();
    }
  };

  const handleDeleteEntriesAndRemoveFiles = () => {
    deleteEntriesAndRemoveFiles({
      labFlowsheetId: labFlowsheetId as LabFlowsheetId,
      date: dayResultsDate
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Lab flowsheet entry deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setRightPaneOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete lab flowsheet entry",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const onSuccess = () => {
    dispatch(
      addAlertToToastTrough({
        message: "Lab flowsheet entry saved",
        type: STATUS_KEYS.SUCCESS
      })
    );
  };

  const onError = (message: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type: STATUS_KEYS.ERROR
      })
    );
  };

  const [dateAlreadyExists, setDateAlreadyExists] = useState(false);

  // Handle date input changes
  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;

    // if the new date is the same as the previous date, do nothing
    if (dayResults?.date === newDate) {
      return;
    }

    // if this is a new entry, just update the date in the store
    if (!dayResults) {
      dispatch(setSelectDayResultsDate(newDate));
      return;
    }

    // check if the new date already has data in the results_by_day array
    const dataCouldBeOverwritten = labFlowsheet?.results_by_day?.find(
      ({ date }) => date === newDate
    );

    const updateDatePayload: LabFlowsheetUpdateDateApiArg = {
      labFlowsheetId: labFlowsheetId as LabFlowsheetId,
      labFlowsheetUpdateDateParams: {
        old_date: dayResults?.date,
        new_date: newDate
      }
    };

    // if the new date already has data, prompt the user to confirm overwriting
    if (dataCouldBeOverwritten) {
      // dispatch the confirmation modal
      const modalProps: ConfirmUpdateLabFlowsheetDateProps = {
        updateDatePayload
      };

      dispatch(
        setModalContent({
          type: MODAL_TYPES.CONFIRM_UPDATE_LAB_FLOWSHEET_DATE,
          props: { ...modalProps, title: "Update Lab Flowsheet Date" }
        })
      );
      dispatch(setModalIsOpen(true));
    } else {
      // just transition data to the new date without confirmation
      updateDate(updateDatePayload)
        .unwrap()
        .then(() => {
          // update the date in the store on success
          dispatch(setSelectDayResultsDate(newDate));
          dispatch(
            addAlertToToastTrough({
              message: "Lab Flowsheet Date Updated",
              type: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setModalIsOpen(false));
        })
        .catch(error => {
          dispatch(
            addAlertToToastTrough({
              message: "Error updating lab flowsheet date",
              type: STATUS_KEYS.ERROR
            })
          );
        });
    }
  };

  // Watch for date changes and validate
  useEffect(() => {
    const updatingDate = dayResults?.date;
    const currentDateColumnExists = labFlowsheet?.results_by_day?.find(
      ({ date }) => date === selectedDayResultsDate
    );
    // if the current date column exists and it's not the same as the updating date
    // set the dateAlreadyExists flag to true
    if (currentDateColumnExists && selectedDayResultsDate !== updatingDate) {
      setDateAlreadyExists(true);
    } else {
      setDateAlreadyExists(false);
    }
  }, [selectedDayResultsDate, labFlowsheet?.results_by_day, dayResults?.date]);

  //
  return (
    <form
      className={clsx(styles.LabFlowsheetForm)}
      onSubmit={handleSubmit}
      data-testid={"lab-flowsheet-form"}
    >
      {/* date */}
      <div className={"flex alignEnd"}>
        <Input
          type="date"
          label="Date"
          name="test_date"
          id="test_date"
          value={selectedDayResultsDate}
          onChange={handleDateChange}
          required
          hiddenLabel
          error={
            dateAlreadyExists
              ? "Date already has entries, please edit that column"
              : undefined
          }
        />
        <Button
          type="submit"
          loading={isCreating || isUpdating}
          nativeButtonProps={{ disabled: dateAlreadyExists }}
        >
          <Icon svg="save-white" />
          Save Entries
        </Button>
      </div>

      {/* iterate over the template tests array to build a form */}
      <div className={styles.templateTests}>
        {templateTests?.map(test => (
          <BasicAccordion title={test.name} key={test.test_id} smallTitle>
            <div className={styles.templateTest}>
              {test.analytes.map(analyte => {
                const analyteEntry = dayResultsTests
                  ?.find(({ test_name }) => {
                    return test_name === test.name;
                  })
                  ?.analytes.find(({ name }) => name === analyte.name);

                let entryId = "new";
                if (analyteEntry) {
                  entryId = analyteEntry.entry_id;
                }

                return (
                  <div key={analyte.analyte_id}>
                    <p className={styles.analyteName}>{analyte.name}</p>
                    <div className={styles.analyteRow}>
                      <Input
                        type="text"
                        label="value"
                        name={`${test.name}--${analyte.name}--value--${entryId}`}
                        id={`${test.name}--${analyte.name}--value--${entryId}`}
                        value={analyteEntry?.value}
                        isCondensed
                        disabled={
                          dateAlreadyExists || analyteEntry?.from_health_gorilla
                        }
                      />
                      <Input
                        type="text"
                        label="units"
                        name={`${test.name}--${analyte.name}--units--${entryId}`}
                        id={`${test.name}--${analyte.name}--units--${entryId}`}
                        value={analyteEntry?.units}
                        isCondensed
                        disabled={
                          dateAlreadyExists || analyteEntry?.from_health_gorilla
                        }
                      />
                      {/* interpretation */}
                      <Input
                        type="text"
                        label="high/low/abnormal"
                        name={`${test.name}--${analyte.name}--interpretation--${entryId}`}
                        id={`${test.name}--${analyte.name}--interpretation--${entryId}`}
                        value={analyteEntry?.interpretation}
                        isCondensed
                        disabled={
                          dateAlreadyExists || analyteEntry?.from_health_gorilla
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </BasicAccordion>
        ))}
        {dayResults && (
          <Button
            style={STYLES.DELETE}
            onClick={handleDeleteEntriesAndRemoveFiles}
          >
            <Delete stroke={styles.errorText} width={15} height={17} />
            Delete Entries and Remove Files
          </Button>
        )}
      </div>
    </form>
  );
}
