/* FileNavigation Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import FileList from "./fileList";
import Folder from "../../../../../../../public/svgs/folder.svg";
import FolderCheck from "../../../../../../../public/svgs/folder-check.svg";

// constants

// store
import { FileId, FileListItem } from "@/store/services/file";

// styles
import styles from "./styles.module.scss";

/* FileNavigation Typescript Interface */
interface FileNavigationProps {
  attachedFiles?: FileListItem[];
  unattachedFiles?: FileListItem[];
  selectedFileId?: FileId;
  onFileSelect: (file: FileListItem) => void;
  onAttachFile: (file: FileListItem) => void;
  onRemoveFile: (file: FileListItem) => void;
}

export default function FileNavigation({
  attachedFiles,
  unattachedFiles,
  selectedFileId,
  onFileSelect,
  onAttachFile,
  onRemoveFile
}: FileNavigationProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.FileNavigation)} data-testid="file-navigation">
      <div className={styles.navHeader}>
        <FolderCheck stroke={styles.gray300} height={20} width={30} />
        Added Files
      </div>
      {attachedFiles && (
        <FileList
          files={attachedFiles}
          onClick={onFileSelect}
          onActionClick={onRemoveFile}
          selectedFileId={selectedFileId}
          isRemove
        />
      )}
      <div className={clsx(styles.navHeader, styles.secondary)}>
        <Folder stroke={styles.gray500} height={20} width={30} />
        Other Lab Files
      </div>
      {unattachedFiles && (
        <FileList
          files={unattachedFiles}
          onClick={onFileSelect}
          onActionClick={onAttachFile}
          selectedFileId={selectedFileId}
          isAdd
        />
      )}
    </div>
  );
}
