/* LabResults Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
/* Local Imports */

// components
import { TestResults } from "@/components/labOrdering/testResults";
import { LabOrderInfo, PatientInfo, UserId } from "@/store/services/patient";
import Annotation from "@/components/annotations";
import AddAnnotation from "@/components/annotations/addAnnotation";
import Tag from "@/components/tag";
import Button from "@/components/button";
import Icon from "@/components/icons";
import Delete from "../../../../../public/svgs/delete_dynamic.svg";

// constants
import { PAGES, STATUS_KEYS, STYLES } from "@/globals/constants";
import { MODAL_TYPES } from "../../dispatcher";

// store
import { RootState } from "@/store/store";
import {
  useLabDeleteOrderMutation,
  useLabToggleOrderReviewedMutation,
  useLabOrderAnnotationListQuery,
  useLabOrderAnnotationCreateMutation,
  useLabOrderAnnotationDeleteMutation,
  useLabOrderAnnotationUpdateMutation,
  LabOrderAnnotation,
  LabOrderAnnotationId
} from "@/store/services/lab";
import { usePracticeInfoQuery } from "@/store/services/practice";
import {
  openOrderDymoLabels,
  openOrderLetterLabels,
  openOrderRequisition,
  openOrderResults
} from "@/components/labOrdering/utils";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalContent, setModalIsOpen } from "../../modalSlice";

// styles
import styles from "../../styles.module.scss";

// utils
import { nameAndDOB } from "@/globals/helpers/formatters";
import AssignGenericStaffTask from "../../triggers/assignGenericStaffTask";

/* LabResults Typescript Interface */
export interface LabResultsProps {
  order: LabOrderInfo;
}

export default function LabResults({ order }: LabResultsProps) {
  /* Redux */

  const dispatch = useDispatch();
  const router = useRouter();
  const [toggleOrderReviewed] = useLabToggleOrderReviewedMutation();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: practice } = usePracticeInfoQuery();
  const [deleteOrder] = useLabDeleteOrderMutation();
  const { data: labOrderAnnotations } = useLabOrderAnnotationListQuery({
    orderId: order.order_id
  });

  // lab order annotations
  const [createAnnotation, { isLoading: isCreating }] =
    useLabOrderAnnotationCreateMutation();
  const [deleteAnnotation, { isLoading: isUpdating }] =
    useLabOrderAnnotationDeleteMutation();
  const [updateAnnotation] = useLabOrderAnnotationUpdateMutation();

  /* Local State */
  const electronicOrderingSupported =
    practice?.metadata?.health_gorilla?.accounts?.[order.lab_id]?.enabled;
  const [addAnnotationOpen, setAddAnnotationOpen] = useState(false);
  const [openAnnotationIds, setOpenAnnotationIds] = useState<
    (LabOrderAnnotationId | null)[]
  >([]);

  /* Effects */

  function renderAddButton() {
    return (
      <button
        className={styles.addAnnotation}
        onClick={() => setAddAnnotationOpen(true)}
        data-cy="add-annotation-button"
      >
        +
      </button>
    );
  }

  /* Event Handlers */
  const handleToggleOrderReviewed = () => {
    toggleOrderReviewed({ orderId: order.order_id });
  };

  const handleViewRequisition = () => {
    openOrderRequisition(order.order_id);
  };

  const handleViewResults = () => {
    openOrderResults(order.order_id);
  };

  const handleViewEncounter = () => {
    // route to encounter associated with this order
    router.push(`${PAGES.ENCOUNTERS}/${order.encounter_id}`);
    dispatch(setRightPaneOpen(false));
  };

  // go to patient chart
  const handleViewPatientChart = () => {
    router.push(`${PAGES.PATIENTS}/${order.patient.user_id}`);
    dispatch(setRightPaneOpen(false));
  };

  const handleAddAnnotationClose = () => {
    setAddAnnotationOpen(false);
  };

  const handleLabOrderAnnotationDelete = (
    annotationId: LabOrderAnnotation["annotation_id"]
  ) => {
    // delete the annotation
    deleteAnnotation({
      orderAnnotationId: annotationId,
      orderId: order.order_id
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleLabOrderAnnotationUpdate = (
    annotationId: LabOrderAnnotation["annotation_id"],
    annotation: string
  ) => {
    // if the annotation id is not already present in the open annotation ids, add it
    if (!openAnnotationIds.includes(annotationId)) {
      setOpenAnnotationIds([...openAnnotationIds, annotationId]);
    }
    // update the annotation
    updateAnnotation({
      orderAnnotationId: annotationId,
      orderId: order.order_id,
      labOrderAnnotationUpdateRequest: {
        annotation_id: annotationId,
        annotation
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
        // remove the annotation id from the open annotation ids
        setOpenAnnotationIds(
          openAnnotationIds.filter(id => id !== annotationId)
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleLabOrderAnnotationCreate = (annotation: string) => {
    // create the annotation
    createAnnotation({
      orderId: order.order_id,
      labOrderAnnotationCreateRequest: {
        annotation,
        author_id: sessionInfo?.user_id as UserId,
        order_id: order.order_id
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Created",
            type: STATUS_KEYS.SUCCESS
          })
        );
        setAddAnnotationOpen(false);
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to create annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <div className={clsx(styles.LabResults)}>
      <div className={styles.resultsWrapper}>
        <div className={styles.orderInfo}>
          <div className={styles.orderHeader}>
            <h2 data-cy="lab-order-results-title">
              {order.label} for {nameAndDOB(order.patient as PatientInfo)}
            </h2>
            <div className={styles.testMetadata} data-cy="lab-order-metadata">
              <div className="flex">
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(order.order_id as string);
                    alert("Copied full ID " + order.order_id + " to clipboard");
                  }}
                  title={"Click to copy full ID to clipboard"}
                >
                  <Tag
                    label={"Order ID | " + (order.order_id as string).slice(-5)}
                    type={STATUS_KEYS.INFO_GREY}
                  />
                </button>
                <Tag
                  label={
                    order.fulfilled
                      ? `Fulfilled | ${dayjs(order.fulfilled).format(
                          "MM/DD/YYYY hh:mm A"
                        )}`
                      : "Pending"
                  }
                  type={STATUS_KEYS.INFO_GREY}
                />
                <Tag
                  label={order.reviewed ? "Reviewed" : "Not Reviewed"}
                  type={
                    order.reviewed ? STATUS_KEYS.SUCCESS : STATUS_KEYS.WARNING
                  }
                  customIconSvgPath={
                    order.reviewed
                      ? "check_success_outline"
                      : "x_failure_outline"
                  }
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                style={order.reviewed ? STYLES.DELETE : STYLES.PRIMARY}
                onClick={handleToggleOrderReviewed}
                dataCy="lab-order-results-toggle-review"
              >
                <Icon
                  svg={order.reviewed ? "x_failure_outline" : "check-done-wh"}
                />
                {order.reviewed ? "Mark Unreviewed" : "Mark Reviewed"}
              </Button>
              {order.sent && (
                <>
                  <Button
                    style={STYLES.SECONDARY}
                    onClick={e => {
                      e?.stopPropagation();
                      openOrderDymoLabels(order.order_id);
                    }}
                    nativeButtonProps={{
                      disabled:
                        order.status === "NEW" || !electronicOrderingSupported
                    }}
                    dataCy="lab-order-results-print-labels"
                  >
                    <Icon svg="printer" />
                    Dymo Labels
                  </Button>
                  <Button
                    style={STYLES.SECONDARY}
                    onClick={e => {
                      e?.stopPropagation();
                      openOrderLetterLabels(order.order_id);
                    }}
                    nativeButtonProps={{
                      disabled:
                        order.status === "NEW" || !electronicOrderingSupported
                    }}
                    dataCy="lab-order-results-print-labels"
                  >
                    <Icon svg="printer" />
                    Sheet Labels
                  </Button>
                </>
              )}
              <Button
                style={STYLES.SECONDARY}
                onClick={handleViewRequisition}
                dataCy="lab-order-results-view-requisition"
              >
                <Icon svg="eye" />
                Requisition
              </Button>
              <Button
                style={STYLES.SECONDARY}
                onClick={handleViewResults}
                nativeButtonProps={{
                  disabled: ![
                    "FULFILLED",
                    "IN_PROGRESS",
                    "REVIEWED",
                    "CORRECTED"
                  ].includes(order.status)
                }}
                dataCy="lab-order-results-view-results"
              >
                <Icon svg="eye" />
                Results
              </Button>
              <Button
                style={STYLES.SECONDARY}
                onClick={() => {
                  dispatch(
                    setModalContent({
                      type: MODAL_TYPES.PATIENT_RECALL,
                      props: { title: "Patient Recall", patient: order.patient }
                    })
                  );
                  dispatch(setModalIsOpen(true));
                }}
                dataCy="lab-order-results-patient-recall"
              >
                <Icon svg="recall" />
                Create Recall
              </Button>
              <AssignGenericStaffTask />
              <Button
                style={STYLES.SECONDARY}
                onClick={handleViewPatientChart}
                dataCy="lab-order-results-view-patient-chart"
              >
                <Icon svg="user" />
                Patient Chart {"->"}
              </Button>
              <Button
                style={STYLES.SECONDARY}
                onClick={handleViewEncounter}
                dataCy="lab-order-results-view-encounter"
              >
                <Icon svg="encounter" />
                Encounter {"->"}
              </Button>
              {!order.sent && order.status === "NEW" && (
                <Button
                  style={STYLES.DELETE}
                  dataCy="lab-delete-button"
                  onClick={() => {
                    deleteOrder({ orderId: order.order_id })
                      .unwrap()
                      .then(res => {
                        dispatch(setRightPaneOpen(false));
                        dispatch(
                          addAlertToToastTrough({
                            message: "Successfully deleted order",
                            type: STATUS_KEYS.SUCCESS
                          })
                        );
                      })
                      .catch(err => {
                        dispatch(setRightPaneOpen(false));
                        dispatch(
                          addAlertToToastTrough({
                            message: "Something went wrong deleting the order",
                            type: STATUS_KEYS.ERROR
                          })
                        );
                      });
                  }}
                >
                  <Delete stroke={styles.errorText} width={15} height={17} />
                  Delete
                </Button>
              )}
            </div>
          </div>
        </div>
        {order.tests?.map((test, i) => (
          <TestResults resultID={i} key={test.code} order={order} test={test} />
        ))}
      </div>
      {/* Order Annotations */}

      <div className={styles.annotationWrapper}>
        {/* either display already existing annotations, or empty state prompting user to add some */}

        <>
          <div className={styles.overflow} data-cy="lab-annotations-wrapper">
            {labOrderAnnotations?.map((annotation: LabOrderAnnotation) => (
              <Annotation
                key={annotation.annotation_id}
                author={annotation.author_id}
                annotationId={annotation.annotation_id}
                created={annotation.created}
                updated={annotation.updated}
                annotation={annotation.annotation}
                isEditing={openAnnotationIds.includes(annotation.annotation_id)}
                onDelete={id =>
                  handleLabOrderAnnotationDelete(id as LabOrderAnnotationId)
                }
                onAnnotationUpdate={(annotationId, annotation) => {
                  handleLabOrderAnnotationUpdate(
                    annotationId as LabOrderAnnotationId,
                    annotation
                  );
                }}
                onAnnotationCreate={handleLabOrderAnnotationCreate}
                isSubmitting={isCreating || isUpdating}
              />
            ))}
          </div>

          <div className={styles.emptyState}>
            <div className={styles.addWrapper}>
              {renderAddButton()}

              {addAnnotationOpen && (
                <AddAnnotation
                  onCancel={handleAddAnnotationClose}
                  onUpdate={(id, annotation) =>
                    handleLabOrderAnnotationUpdate(
                      id as LabOrderAnnotationId,
                      annotation
                    )
                  }
                  onCreate={handleLabOrderAnnotationCreate}
                  isEditing={false}
                  isSubmitting={isCreating || isUpdating}
                />
              )}
            </div>
            <div className="tMd Med dark">Add Comments</div>
            {/* // TODO: move into copy constants */}
            <p className="xLight">Add annotations to the document</p>
          </div>
        </>
      </div>
    </div>
  );
}
