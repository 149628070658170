import { FORMAT } from "@/globals/helpers/formatters";
import { ReportFrontendErrorApiArg } from "@/store/services/system";
import { Cookie } from "@/store/services/user";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/query";
import posthog from "posthog-js";

type UserMetaData = {
  name: string;
  userId: number;
  email: string;
  practice: number;
  encounterId?: number;
}

//This is a crazy type because RTK has a lot of crazy types
// we normally don't touch, this just gives us auto-complete. MutationTrigger<any> 
// would work too but then you don't get auto-complete
type MutationTriggerType = MutationTrigger<MutationDefinition<ReportFrontendErrorApiArg, BaseQueryFn<any>, any, number, "backend">>;

export class AudioRecorderLogger {
  private sessionInfo: Cookie;
  private userId: number;
  private userMetaData: UserMetaData;
  private encounterId: number;
  // We pass in the RTK query trigger from the component since classes can't
  // use React hooks. This is a crazy type because RTK has a lot of crazy types
  // we normally don't touch, this just gives us auto-complete. MutationTrigger<any> 
  // would work too but then you don't get auto-complete
  private errorLogMutation: MutationTriggerType

  // The type we pass in is really generic because it makes it easier. We only need the specific
  // typing for autocomplete
  constructor(sessionInfo: Cookie, encounterId: number, logErrorMutation: MutationTrigger<any>) {
    this.sessionInfo = sessionInfo;
    this.userId = sessionInfo.user_id;
    this.encounterId = encounterId;
    this.userMetaData = {
      name: FORMAT.name(this.sessionInfo),
      userId: this.userId,
      email: this.sessionInfo.email,
      practice: this.sessionInfo.practice_id,
      encounterId: this.encounterId,
    };
    this.errorLogMutation = logErrorMutation as MutationTriggerType;
  }

  // Helper function just to display all the user meta data
  private userMetaDataToString(metaData: UserMetaData) {
    let baseString: string = `User: ${metaData.name} (UserId: ${metaData.userId}, Email: ${metaData.email}) with practice ${metaData.practice}`;
    if (metaData.encounterId) {
      baseString += ` in encounter ${metaData.encounterId}`
    }
    return baseString;
  }

  //////////////////////////////////
  // Microphone logging
  public async logInitialMicrophoneData() {
    // Request permissions, if we already have permissions, this won't reprompt it
    await navigator.mediaDevices.getUserMedia({ audio: true });

    // get all audio devices
    const devices = await navigator.mediaDevices.enumerateDevices();
    // filter for audio inputs (ie microphones)
    const audioDevices = devices.filter(device => device.kind === "audioinput");
    const audioDeviceNames = audioDevices.map(device => device.label)

    // Send posthog event with number of devices.
    posthog.capture("user_mic_data", {
      deviceNumber: audioDevices.length,
      deviceNames: audioDeviceNames,
      ...this.userMetaData
    });

    // Send separate event for user with no mic
    if (audioDevices.length === 0) {
      posthog.capture("user_no_mic", {
        ...this.userMetaData
      });
    }
  }

  public logMicrophoneFailure(errorMessage: string = "") {
    posthog.capture("user_mic_failure", {
      errorMessage,
      ...this.userMetaData
    });
    const userDataString = this.userMetaDataToString(this.userMetaData);
    const arg: ReportFrontendErrorApiArg = {
      reportSystemError: {
        message: userDataString + " - ERROR: user_mic_failure",
      }
    }
    this.errorLogMutation(arg);
  }

  public logMicrophoneSuccess() {
    posthog.capture("user_mic_success", {
      ...this.userMetaData
    });
  }

  public logMicrophoneTooQuiet() {
    posthog.capture("user_mic_too_quiet", {
      ...this.userMetaData
    })
  }

  public logMicrophoneSendingValidAudio() {
    posthog.capture("user_mic_sending_valid_audio", {
      ...this.userMetaData
    })
  }

  //////////////////////////////////
  // WebSocket logging
  public logWebSocketConnectionStarted(connectionAttemptNumber: number = 1) {
    posthog.capture("user_websocket_connection_started", {
      connectionAttemptNumber,
      ...this.userMetaData
    })
  }

  public logWebSocketConnectionSuccess() {
    posthog.capture("user_websocket_connection_success", {
      ...this.userMetaData
    });
  }

  public logWebSocketConnectionFailure() {
    posthog.capture("user_websocket_connection_failed", {
      ...this.userMetaData
    });
    const userDataString = this.userMetaDataToString(this.userMetaData);
    const arg: ReportFrontendErrorApiArg = {
      reportSystemError: {
        message: userDataString + " - ERROR: user_websocket_connection_failed",
      }
    }
    this.errorLogMutation(arg);
  }

  public logWebSocketDisconnected() {
    posthog.capture("user_websocket_disconnected", {
      ...this.userMetaData
    })
  }

  public logWebSocketError(error: Error) {
    posthog.capture("user_websocket_error", {
      error,
      ...this.userMetaData
    })
    const userDataString = this.userMetaDataToString(this.userMetaData);
    const arg: ReportFrontendErrorApiArg = {
      reportSystemError: {
        message: userDataString + " - ERROR: user_websocket_error",
      }
    }
    this.errorLogMutation(arg);
  }

  //////////////////////////////////
  // Audio Connection logging
  public logCleanupMessage(message: string) {
    posthog.capture("user_cleanup_audio_recording", {
      message,
      ...this.userMetaData
    })
  }

  public logAudioConnectionError(error: any) {
    posthog.capture("user_audio_connection_error", {
      error,
      ...this.userMetaData
    })
    const userDataString = this.userMetaDataToString(this.userMetaData);
    const arg: ReportFrontendErrorApiArg = {
      reportSystemError: {
        message: userDataString + " - ERROR: user_audio_connection_error",
      }
    }
    this.errorLogMutation(arg);
  }

  public logEvent(message: string) {
    posthog.capture("user_audio_recording_event", {
      message,
      ...this.userMetaData
    })
  }
}
