/* Create Appointment Modal Template
 * Hic sunt dracones.
 */

// External
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { sanitize } from "isomorphic-dompurify";
import libphonenumber from "google-libphonenumber";
import Select from "react-select";

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

// styles
import styles from "./styles.module.scss";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import Checkbox from "@/components/input/checkbox";
import BasicAccordion from "@/components/accordions/basic";
import Icon from "@/components/icons";
import Toggle from "@/components/input/toggle";
import GeneratedInput from "@/components/input/generated";
import Tag from "@/components/tag";
import ContentRenderer from "@/components/textArea/contentRenderer";
import ControlledTextArea from "../textArea/controlledTextArea";
import DashboardCard from "../cards/dashboardCard";

// icons
import Edit from "../../../public/svgs/edit_simple.svg";
import Delete from "../../../public/svgs/delete_dynamic.svg";

// constants
import {
  STYLES,
  METRIC_LABELS,
  STATUS_KEYS,
  METRICS,
  APPOINTMENT_STATUS_TYPES,
  PAGES,
  CHART_STATUS_MAP,
  STATE_CODES
} from "@/globals/constants";
import {
  FORMAT,
  apptLocationToUpsert,
  nameAndDOB
} from "@/globals/helpers/formatters";

// helpers
import {
  useCustomPatientTypes,
  useCustomPracticeLocations,
  useCustomPatientSexOptions,
  useCustomCalendarSlotInterval
} from "@/globals/helpers/customHooks";

// store
import {
  useAppointmentUpsertMutation,
  AppointmentInfo,
  AppointmentUpsertRequest,
  AppointmentTasks,
  AppointmentLocation,
  useAppointmentStartMutation
} from "@/store/services/scheduling";
import {
  AppointmentTypeDetails,
  usePracticeListAppointmentTypesQuery
} from "@/store/services/practice";
import { LocationListItem } from "@/store/services/location";
import { useFileListQuery } from "@/store/services/file";
import { AppointmentStatus } from "@/store/services/scheduling";
import {
  PatientInfo,
  PatientUpsertRequest,
  Sex,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { Cookie } from "@/store/services/user";
import {
  usePregnancyUpsertMutation,
  useCreateEddMutation,
  useUpdateEddMutation,
  useLazyListEddHistoryQuery
} from "@/store/services/pregnancy";
import { useGetFeatureFlagsQuery } from "@/store/services/system";
import {
  convertUtcIntToLocalDatetime,
  convertUtcIntToLocalDate,
  generateTimeSlots,
  getUtcStartAndEndFromDatetime
} from "../scheduling/calendars/utils";
import { TaskType } from "@/store/services/task";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import { RootState } from "@/store/store";
import {
  usePracticeSearchUsersQuery,
  usePracticeGetFormsQuery,
  useLazyPatientGetListQuery,
  ProviderListItem
} from "@/store/services/practice";
import { useLazyPatientGetInfoQuery } from "@/store/services/patient";
import { calculateEDD } from "@/globals/helpers";
import { usePracticeGetProvidersQuery } from "@/store/services/practice";
import { setRightPaneOpen } from "../drawer/drawerSlice";
import {
  SelectDefaultTheme,
  SelectDefaultStyles
} from "@/styles/themes/selectDefaultTheme";
import PatientCheckout from "../patientCheckout";
import LoadingSpinner from "../modal/templates/loadingSpinner";

type AppointmentFormType = {
  patient: PatientUpsertRequest;
  appointment: AppointmentUpsertRequest;
  date?: string;
  start?: dayjs.Dayjs;
  end?: dayjs.Dayjs;
  location?: LocationListItem | AppointmentLocation;
  provider?: ProviderListItem;
  LMP?: string;
};

export interface AppointmentFormProps {
  appointment?: Partial<AppointmentInfo>;
  isReadOnly?: boolean;
  isDefaultEmpty?: boolean;
  isDefaultEditMode?: boolean;
  isCheckingOut?: boolean;
}

export default function Appointment({
  appointment,
  isReadOnly = false,
  isDefaultEmpty = false,
  isDefaultEditMode = false,
  isCheckingOut = false
}: AppointmentFormProps) {
  const dispatch = useDispatch();
  const router = useRouter();
  const CustomPatientTypes = useCustomPatientTypes();
  const CustomPatientSexOptions = useCustomPatientSexOptions();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { locationFilter, providerFilter } = useSelector(
    (state: RootState) => state.calendar
  );

  // API calls
  const [listPatients, { data: listing, isLoading: isPatientsLoading }] =
    useLazyPatientGetListQuery();
  const [getPatientInfo] = useLazyPatientGetInfoQuery();
  const [upsertAppointment, { isLoading: isUpsertingAppointment }] =
    useAppointmentUpsertMutation();
  const [createEdd] = useCreateEddMutation();
  const [updateEdd] = useUpdateEddMutation();
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const [upsertPatient, { isLoading: isUpsertingPatient }] =
    usePatientUpsertMutation();
  const [upsertPregnancy, { isLoading: isUpsertingPregnancy }] =
    usePregnancyUpsertMutation();
  const locations = useCustomPracticeLocations();
  const [isLoading, setIsLoading] = useState(false);
  const { data: users } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );
  const { data: allProviders } = usePracticeGetProvidersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    { skip: !sessionInfo?.practice_id }
  );
  const { data: formFiles, isLoading: isFormFilesLoading } = useFileListQuery(
    {
      scope: "practice",
      id: sessionInfo?.practice_id as number,
      tagName: "form",
      pagesz: 1000
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const patients = useMemo(
    () =>
      (listing?.patients || []).map(p => {
        return {
          value: p,
          label: nameAndDOB(p)
        };
      }),
    [listing]
  );

  const locationOptions = locations.map(l => {
    if (typeof l === "string") {
      const label = l
        .substring(0, 1)
        .toUpperCase()
        .concat(l.substring(1).toLocaleLowerCase());
      return {
        label: label,
        value: l
      };
    } else {
      return {
        label: l.name,
        value: l.location_id
      };
    }
  });

  const { data: forms, isLoading: isFormsLoading } = usePracticeGetFormsQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip:
        !isDefaultEmpty &&
        !apptLocationToUpsert(appointment?.appointment_location) &&
        locationFilter.length === 0
    }
  );

  const [getEddList] = useLazyListEddHistoryQuery();
  const [startAppt] = useAppointmentStartMutation();

  const interval = useCustomCalendarSlotInterval();
  const stringSlots = generateTimeSlots(interval);

  const availableForms: AppointmentTasks = useMemo(() => {
    return (forms || [])
      .map(form => ({
        task_type: "FORM" as TaskType,
        resource_id: form.form_id,
        display: form.title
      }))
      .concat(
        (formFiles || []).map(file => ({
          task_type: "FILE" as TaskType,
          resource_id: file.file_id,
          display: file.name
        }))
      )
      .sort((a, b) => a.display.localeCompare(b.display));
  }, [forms, formFiles]);

  // Local state
  // memoized values
  const providers = useMemo(() => {
    if (providerFilter && providerFilter.length > 0) {
      return allProviders?.filter(provider =>
        providerFilter.includes(provider.user_id)
      );
    } else {
      return allProviders; // this will be all providers when scheduling from the facepage
    }
  }, [allProviders, providerFilter]);

  const [isEditMode, setIsEditMode] = useState(isDefaultEditMode);
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false);
  const [patient, setPatient] = useState<PatientInfo | null>(
    appointment?.patient ? appointment?.patient : null
  );
  const [patientParent, setPatientParent] = useState<PatientInfo | null>(null);
  const [hasTimeError, setHasTimeError] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);

  const defaultLocation =
    typeof appointment?.appointment_location === "object"
      ? appointment?.appointment_location?.location_id
      : appointment?.appointment_location;
  const defaultValues: AppointmentFormType = {
    patient: {
      ...appointment?.patient,
      // @ts-ignore // appointment.patient.dob is a number but the form ingests a string
      dob: appointment?.patient?.dob
        ? convertUtcIntToLocalDate(appointment?.patient?.dob as number).format(
            "YYYY-MM-DD"
          )
        : undefined
    },
    appointment: {
      ...appointment,
      status: "SCHEDULED",
      patient_id: appointment?.patient?.user_id || -1,
      appointment_location: defaultLocation,
      appointment_type_id: appointment?.appointment_type
        ?.appointment_type_id as string,
      starts: appointment?.starts || -1,
      ends: appointment?.ends || -1,
      flagged: appointment?.flagged || false,
      // @ts-ignore
      provider: appointment?.provider
    }
  };
  if (defaultLocation) {
    defaultValues.appointment.appointment_location = defaultLocation;
  }
  if (appointment && providers) {
    defaultValues.provider =
      getDefaultProvider(appointment, providerFilter, providers, sessionInfo) ||
      undefined;
    defaultValues.appointment.provider_id = defaultValues.provider
      ?.user_id as number;
  }
  if (appointment?.starts) {
    defaultValues.date = convertUtcIntToLocalDatetime(
      appointment.starts
    ).format("YYYY-MM-DD");

    const stringStart = convertUtcIntToLocalDatetime(
      defaultValues.appointment.starts
    ).format("h:mm A");
    // @ts-ignore
    // We will be migrating timestamps to strings
    defaultValues.appointment.starts = stringStart;
  }
  if (appointment?.ends) {
    const stringEnd = convertUtcIntToLocalDatetime(
      defaultValues.appointment.ends
    ).format("h:mm A");
    // @ts-ignore
    // We will be migrating timestamps to strings
    defaultValues.appointment.ends = stringEnd;
  }
  defaultValues.patient.sex = "FEMALE";
  if (appointment?.patient?.sex) {
    defaultValues.patient.sex = appointment?.patient?.sex;
  }

  type FormData = {
    [K in keyof AppointmentFormType]: AppointmentFormType[K];
  };
  const form = useForm<FormData>({ defaultValues });
  const appointmentTypeId = form.watch("appointment.appointment_type_id");
  const patientType = form.watch("patient.practice_data.type");

  const { data: appointmentTypes } = usePracticeListAppointmentTypesQuery();
  const apptTypeOptions = useMemo(() => {
    return appointmentTypes?.map(appt => ({
      label: METRIC_LABELS[appt.appointment_type] || appt.appointment_type,
      value: appt
    }));
  }, [appointmentTypes]);
  const appointmentType = useMemo(
    () =>
      appointmentTypes?.find(
        appt => appt.appointment_type_id == appointmentTypeId
      ),
    [appointmentTypeId, appointmentTypes]
  );

  const [patientLmp, setPatientLmp] = useState<string>("");

  // set global loading state
  useEffect(() => {
    setIsLoading(
      isPatientsLoading || isFormsLoading || isFormFilesLoading || !locations
    );
  }, [isPatientsLoading, isFormsLoading, isFormFilesLoading, locations]);

  // Whenever the patient search query changes, update the patient list
  useEffect(() => {
    const encType = appointmentTypes?.find(
      appt => appt.appointment_type == appointmentType?.appointment_type
    )?.encounter_type;
    if (encType === "LABOR") {
      listPatients({ query, type: "OB" });
    } else {
      listPatients({ query });
    }
  }, [query, appointmentType]);

  // update the form fields when "appointment" prop changes
  useEffect(() => {
    if (appointment && sessionInfo && users && locations) {
      form.reset(defaultValues);
    }
  }, [appointment, form.reset, sessionInfo, users, locations, providerFilter]);

  useEffect(() => {
    setIsEditMode(isDefaultEditMode);
  }, [isDefaultEditMode]);

  useEffect(() => {
    // set patient parent
    if (patient && patient.parent_ids && patient.parent_ids.length > 0) {
      getPatientInfo({ patientId: patient.parent_ids[0] as number })
        .unwrap()
        .then(parent => {
          setPatientParent(parent);
        })
        .catch(err => {
          console.error(err);
        });
    }
    // Set lmp
    if (patient && patient.pregnancy) {
      getPatientInfo({ patientId: patient.user_id })
        .unwrap()
        .then(p => {
          const lmp = p.pregnancy?.lmp?.last_menstrual_period;
          if (lmp) {
            setPatientLmp(dayjs(lmp).format("MM/DD/YYYY"));
          } else if (form.getValues("LMP") && form.getValues("LMP") != "") {
            setPatientLmp(dayjs(form.getValues("LMP")).format("MM/DD/YYYY"));
          } else {
            setPatientLmp("-");
          }
        });
    }
  }, [patient]);

  /* Event handlers */
  const handleDeleteAppointment = () => {
    upsertAppointment({
      appointmentUpsertRequest: {
        ...(appointment as AppointmentInfo),
        id: appointment?.appointment_id,
        appointment_type_id: appointment?.appointment_type
          ?.appointment_type_id as string,
        status: "CANCELED",
        patient_id: appointment?.patient?.user_id as number,
        provider_id: appointment?.provider?.user_id as number,
        appointment_location: apptLocationToUpsert(
          appointment?.appointment_location
        )
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Appointment succesfully deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setRightPaneOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Oops! Appointment could not be removed",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const onSubmit: SubmitHandler<AppointmentFormType> = async data => {
    const handleUpsertPregnancy = async (patient: PatientInfo) => {
      let eddID;
      if (patient.pregnancy) {
        const eddList = await getEddList({
          pregnancyId: patient.pregnancy?.pregnancy_id as number
        }).unwrap();

        if (eddList && eddList.length > 0) {
          const lastLmpEdd = eddList.find(edd => {
            return edd.method == "LMP";
          });
          eddID = lastLmpEdd?.estimate_id;
        }
      }

      let pregID = patient.pregnancy?.pregnancy_id;
      if (!pregID) {
        try {
          // Create the pregnancy
          pregID = await upsertPregnancy({
            patientId: patient.user_id,
            pregnancyUpsertRequest: {}
          }).unwrap();
          dispatch(
            addAlertToToastTrough({
              message: "Pregnancy created",
              type: STATUS_KEYS.SUCCESS
            })
          );
        } catch (err) {
          dispatch(
            addAlertToToastTrough({
              message: "oops! patient pregnancy could not be updated",
              type: STATUS_KEYS.ERROR
            })
          );
        }
      }

      if (!data.LMP) {
        return;
      }

      if (eddID) {
        try {
          // Update the EDD
          await updateEdd({
            eddId: eddID,
            pregnancyId: pregID as number,
            estimatedDueDateUpdate: {
              method: "LMP",
              estimated_due_date: calculateEDD(data.LMP).format("YYYY-MM-DD"),
              date_of_method: data.LMP
            }
          }).unwrap();
        } catch (err) {
          dispatch(
            addAlertToToastTrough({
              message: "oops! patient pregnancy could not be updated",
              type: STATUS_KEYS.ERROR
            })
          );
        }
      } else {
        try {
          await createEdd({
            pregnancyId: pregID as number,
            estimatedDueDate: {
              method: "LMP",
              date_of_method: data.LMP,
              estimated_due_date: calculateEDD(data.LMP).format("YYYY-MM-DD")
            }
          }).unwrap();
        } catch (err) {
          dispatch(
            addAlertToToastTrough({
              message: "oops! patient pregnancy could not be updated",
              type: STATUS_KEYS.ERROR
            })
          );
        }
      }
    };

    const handleUpsertAppt = (patientID?: number) => {
      // Parse the date and time strings
      const [utcStart, utcEnd] = getUtcStartAndEndFromDatetime(
        data.date as string,
        // @ts-ignore
        // We will be migrating timestamps to strings
        data.appointment.starts as string,
        // @ts-ignore
        // We will be migrating timestamps to strings
        data.appointment.ends as string
      );

      const appointmentUpsertRequest: AppointmentUpsertRequest = {
        id: appointment?.appointment_id,
        flagged: data.appointment?.flagged,
        appointment_type_id: data.appointment.appointment_type_id,
        patient_id: patientID || (data.patient.user_id as number),
        provider_id: data.provider?.user_id as number,
        appointment_location: data.appointment.appointment_location,
        starts: utcStart as number,
        ends: utcEnd as number,
        status: data.appointment.status,
        chief_complaint: data.appointment.chief_complaint,
        attachments: data.appointment?.attachments,
        tasks: data.appointment?.tasks
      };
      upsertAppointment({
        appointmentUpsertRequest
      })
        .unwrap()
        .then(_ => {
          dispatch(
            addAlertToToastTrough({
              message: "Appointment succesfully updated",
              type: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setRightPaneOpen(false));
        })
        .catch(err => {
          dispatch(
            addAlertToToastTrough({
              message: "Oops, something went wrong. Please try again",
              type: STATUS_KEYS.ERROR
            })
          );
        });
    };

    // In this case we're don't have a patient selected but we don't have
    // the new patient toggle turned on either.
    if (!isNewPatient && !data.patient) {
      dispatch(
        addAlertToToastTrough({
          message: "Please either select a patient or toggle new patient.",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    if (form.formState.dirtyFields.patient || isNewPatient) {
      const normalizedDOB = data?.patient?.dob?.toString().replaceAll("-", "");
      upsertPatient({
        patientUpsertRequest: {
          ...data.patient,
          first_name: data.patient.first_name?.trim(),
          last_name: data.patient.last_name?.trim(),
          dob: parseInt(normalizedDOB || ""),
          parent_ids: patientParent?.user_id ? [patientParent?.user_id] : []
        }
      })
        .unwrap()
        .then(patient => {
          dispatch(
            addAlertToToastTrough({
              message: `Patient chart successfully ${
                isNewPatient ? "created" : "updated"
              }`,
              type: STATUS_KEYS.SUCCESS
            })
          );
          data.appointment.patient_id = patient.user_id;
          form.setValue("patient.user_id", patient?.user_id);
          form.setValue("appointment.patient_id", patient?.user_id);
          // upsert appointment with new patient
          handleUpsertAppt(patient?.user_id);
          // if the patient is of type OB or PROSPECTIVE, has an LMP set in the form
          // upsert the patient's pregnancy EDD (OB patients will always have a pregnancy
          // and PROSPECTIVE patients need a pregnancy to add the LMP)
          if (
            (patient.practice_data.type === METRICS.OB ||
              patient.practice_data.type === METRICS.PROSPECTIVE) &&
            data.LMP
          ) {
            handleUpsertPregnancy(patient);
          }
        })
        .catch(err => {
          dispatch(
            addAlertToToastTrough({
              message: err.data.message,
              type: STATUS_KEYS.ERROR
            })
          );
        });
    } else {
      if (patient && patientType === METRICS.OB) {
        handleUpsertPregnancy(patient);
      }
      handleUpsertAppt();
    }
  };

  const handleUpdateAppointmentStatus = (status: AppointmentStatus) => {
    if (appointment && status && status !== appointment.status) {
      upsertAppointment({
        appointmentUpsertRequest: {
          id: appointment.appointment_id,
          appointment_type_id: appointment?.appointment_type
            ?.appointment_type_id as string,
          patient_id: appointment?.patient?.user_id as number,
          provider_id: appointment?.provider?.user_id as number,
          starts: appointment?.starts as number,
          ends: appointment.ends as number,
          appointment_location: apptLocationToUpsert(
            appointment?.appointment_location
          ),
          status
        }
      })
        .unwrap()
        .then(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Appointment succesfully updated",
              type: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setRightPaneOpen(false));
        })
        .catch(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Oops! Appointment could not be updated",
              type: STATUS_KEYS.ERROR
            })
          );
        });
    }
  };

  const handleSetAppointmentFlagged = async (flagged: boolean) => {
    if (!appointment?.appointment_id) return;

    try {
      await upsertAppointment({
        appointmentUpsertRequest: {
          id: appointment.appointment_id,
          appointment_type_id: appointment?.appointment_type
            ?.appointment_type_id as string,
          patient_id: appointment?.patient?.user_id as number,
          provider_id: appointment?.provider?.user_id as number,
          starts: appointment?.starts as number,
          ends: appointment.ends as number,
          appointment_location: apptLocationToUpsert(
            appointment?.appointment_location
          ),
          flagged: flagged
        }
      }).unwrap();
    } catch (err) {
      dispatch(
        addAlertToToastTrough({
          message: "Failed to toggle flagged status",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  };

  const formatPhone = (phone: string) => {
    if (!phone) return "";
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse(phone, "US");
    let phoneStr = phoneUtil.formatInOriginalFormat(number, "US") || "";
    return phoneStr;
  };

  const handleOpenAppointment = (appointment: AppointmentInfo) => async () => {
    dispatch(setRightPaneOpen(false));
    if (!appointment.encounter_id) {
      startAppt({ appointmentId: appointment.appointment_id })
        .unwrap()
        .then(encounterId => {
          router.push(`${PAGES.ENCOUNTERS}/${encodeURIComponent(encounterId)}`);
        })
        .catch(() => {
          console.error("Failed to start appointment");
        });
    } else {
      router.push(
        `${PAGES.ENCOUNTERS}/${encodeURIComponent(appointment.encounter_id)}`
      );
    }
  };

  return !isLoading ? (
    <form
      className={clsx(styles.CreateAppointmentForm)}
      onSubmit={form.handleSubmit(onSubmit)}
      data-cy="create-appointment-form"
    >
      <div className={styles.header}>
        <div></div>
        <div className={styles.actions}>
          {appointment?.appointment_id && (
            <Controller
              // @ts-ignore
              name="select_appt_status"
              control={form.control}
              defaultValue={
                appointment && appointment.status
                  ? {
                      value: appointment?.status,
                      label: METRIC_LABELS[appointment.status]
                    }
                  : null
              }
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <>
                  <Select
                    options={Object.keys(APPOINTMENT_STATUS_TYPES).map(
                      item => ({
                        label: METRIC_LABELS[item],
                        value: item
                      })
                    )}
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                    onChange={(v: any) => {
                      onChange(v);
                      handleUpdateAppointmentStatus(v.value);
                    }}
                    openMenuOnFocus
                    value={value}
                    placeholder="Appointment Status"
                  />
                </>
              )}
            />
          )}
          <Checkbox
            id="flagged"
            name="appointment.flagged"
            onChange={e => {
              form.setValue("appointment.flagged", e.target.checked);
              handleSetAppointmentFlagged(e.target.checked);
            }}
            register={form.register}
            label="Flagged"
          />
        </div>
      </div>
      {/* Appointment Info */}
      {/* set open to default if appointment is for new patient */}
      <div className={styles.actionHeader}>
        <h2 className="dark smBld">
          {isEditMode || !appointment?.patient?.first_name
            ? "Schedule Appointment"
            : `${appointment?.patient?.first_name}'s Appointment`}
        </h2>

        <div className="flex">
          {!isReadOnly && !isEditMode && (
            <button type="button" onClick={() => setIsEditMode(true)}>
              <Edit stroke={styles.gray500} width={20} height={20} />
            </button>
          )}
          {isEditMode && (
            <>
              <Button
                style={STYLES.SECONDARY}
                nativeButtonProps={{
                  disabled:
                    isUpsertingAppointment ||
                    isUpsertingPatient ||
                    isUpsertingPregnancy
                }}
                onClick={() => setIsEditMode(false)}
              >
                Back
              </Button>
              <Button
                type="submit"
                nativeButtonProps={{
                  disabled:
                    hasTimeError ||
                    !appointmentType ||
                    (!isNewPatient && !patient)
                }}
                loading={
                  isUpsertingAppointment ||
                  isUpsertingPatient ||
                  isUpsertingPregnancy
                }
              >
                Save
              </Button>
            </>
          )}
        </div>
      </div>
      <DashboardCard title="Appointment Detail">
        <div className={styles.grid}>
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Appointment Type*</div>
            {isEditMode ? (
              <div className="flex fullWidth" data-cy="appointment-type">
                <Controller
                  name="appointment.appointment_type_id"
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        openMenuOnFocus
                        options={apptTypeOptions}
                        theme={SelectDefaultTheme}
                        styles={SelectDefaultStyles}
                        value={apptTypeOptions?.find(
                          t => t.value.appointment_type_id === value
                        )}
                        onChange={newValue => {
                          const item = newValue as {
                            value: AppointmentTypeDetails;
                            label: string;
                          };
                          if (!item.value) return;
                          onChange(item.value.appointment_type_id);
                          // Parse the date and time strings
                          const starts = form.getValues("appointment.starts");
                          const startDatetimeStr = `${dayjs().format(
                            "YYYY-MM-DD"
                          )} ${starts}`;

                          // Create a Day.js object
                          const startTime = dayjs(
                            startDatetimeStr,
                            "YYYY-MM-DD h:mm A"
                          );

                          let defaultDuration = 30;
                          // Find the object in the appointmentTypes array for the selected appointment type and get the
                          // default duration from it
                          if (appointmentTypes) {
                            const selectedApptType = appointmentTypes?.find(
                              appt =>
                                appt.appointment_type_id ==
                                item.value?.appointment_type_id
                            );
                            if (selectedApptType) {
                              defaultDuration =
                                selectedApptType?.default_duration;
                              const defaultAppointmentTasks =
                                selectedApptType?.default_tasks;
                              if (defaultAppointmentTasks) {
                                form.setValue(
                                  "appointment.tasks",
                                  defaultAppointmentTasks
                                );
                              } else {
                                form.setValue("appointment.tasks", []);
                              }
                            }
                          }

                          // Calculate the end time as the start time + the default duration of the appointment type
                          // if there is no default duration, default to 30 minutes
                          const endTime = startTime.add(
                            defaultDuration as number,
                            "minute"
                          );
                          const endSlot = endTime.format("h:mm A");
                          // @ts-ignore: This needs to be fixed
                          form.setValue("appointment.ends", endSlot as number);

                          // Using dayjs, Check if end time is before start time
                          const timeErr = endTime?.isSameOrBefore(startTime);
                          setHasTimeError(timeErr);
                        }}
                      />
                      {form.formState.errors?.appointment?.type && (
                        <p className="error">Required</p>
                      )}
                    </>
                  )}
                  rules={{ required: true }}
                />
              </div>
            ) : (
              <p className="dark">
                {appointment?.appointment_type &&
                  (METRIC_LABELS[
                    appointment?.appointment_type?.appointment_type
                  ] ||
                    appointment?.appointment_type?.appointment_type)}
              </p>
            )}
          </div>
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Location*</div>
            {isEditMode ? (
              <Controller
                name="appointment.appointment_location"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      id="location"
                      openMenuOnFocus
                      theme={SelectDefaultTheme}
                      styles={SelectDefaultStyles}
                      options={locationOptions}
                      value={{
                        value: value,
                        label: locationOptions?.find(l => l.value === value)
                          ?.label
                      }}
                      onChange={newValue => {
                        const location = newValue as {
                          value: LocationListItem | AppointmentLocation;
                          label: string;
                        };
                        if (location) {
                          if (typeof location === "string") {
                            onChange(location);
                            form.setValue(
                              "appointment.appointment_location",
                              location
                            );
                          } else if (typeof location.value === "string") {
                            onChange(location.value);
                            form.setValue(
                              "appointment.appointment_location",
                              location.value
                            );
                          } else {
                            onChange(location.value);
                            // @ts-ignore
                            form.setValue(
                              "appointment.appointment_location",
                              // @ts-ignore
                              location.value
                            );
                          }
                        }
                      }}
                    />
                    {form.formState.errors?.appointment
                      ?.appointment_location && (
                      <p className="error">
                        {
                          form.formState.errors?.appointment
                            ?.appointment_location?.message as string
                        }
                      </p>
                    )}
                  </>
                )}
                rules={{ required: true }}
              />
            ) : (
              <p className="dark">
                {typeof appointment?.appointment_location === "object"
                  ? appointment.appointment_location?.name
                  : appointment?.appointment_location?.toLocaleLowerCase()}
              </p>
            )}
          </div>
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Provider*</div>
            {isEditMode ? (
              <Controller
                name="provider"
                control={form.control}
                defaultValue={defaultValues.provider}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      openMenuOnFocus
                      theme={SelectDefaultTheme}
                      styles={SelectDefaultStyles}
                      options={providers?.map(provider => ({
                        label: FORMAT.name(provider),
                        value: provider
                      }))}
                      //@ts-ignore
                      onChange={newValue => onChange(newValue.value)}
                      value={{
                        value: value,
                        label: value && FORMAT.name(value)
                      }}
                    />
                    {form.formState.errors?.provider && (
                      <p className="error">Required</p>
                    )}
                  </>
                )}
                rules={{ required: true }}
              />
            ) : (
              <p className="dark">
                {appointment?.provider && FORMAT.name(appointment?.provider)}
              </p>
            )}
          </div>
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Date*</div>
            {isEditMode ? (
              <Input
                type="date"
                label="appointment date"
                hiddenLabel
                id="date"
                name="date"
                fullWidth
                register={form.register}
              />
            ) : (
              <p className="dark">
                {appointment?.starts &&
                  convertUtcIntToLocalDate(
                    appointment?.starts as number
                  )?.format("MM/DD/YYYY")}
              </p>
            )}
          </div>
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Time*</div>
            {isEditMode ? (
              <div className="flex">
                <Controller
                  name="appointment.starts"
                  control={form.control}
                  // NOTE: The type definition for the value is not correct. They are actually strings like "11:00 AM"
                  // or "1:00 PM", and that's what we need to save to the appointment.starts field.
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="appt-start"
                      openMenuOnFocus
                      styles={SelectDefaultStyles}
                      theme={SelectDefaultTheme}
                      options={stringSlots}
                      filterOption={(candidate, input) => {
                        // Normalize the input
                        const normalizedInput = input
                          .toLowerCase()
                          .replace(/\s/g, "");

                        // Parse the candidate time (assuming it's in the label)
                        const [targetHour, targetMinute, targetPeriod] =
                          candidate.label
                            .match(/(\d+):(\d+)\s*(AM|PM)/i)
                            ?.slice(1) || [];
                        if (!targetHour || !targetMinute || !targetPeriod)
                          return false;

                        // Create regex patterns based on the candidate time
                        const hourPattern = targetHour.padStart(2, "0");
                        const minutePattern = targetMinute.padStart(2, "0");
                        const periodPattern = targetPeriod.toLowerCase();

                        const patterns = [
                          `^${hourPattern}:?${minutePattern}${periodPattern}$`,
                          `^${parseInt(targetHour)}:?${minutePattern}${periodPattern}$`,
                          `^${parseInt(targetHour)}${periodPattern}$`,
                          `^${parseInt(targetHour)}:?${minutePattern}$`,
                          `^${parseInt(targetHour)}$`
                        ];

                        // Test the input against all patterns
                        return (
                          patterns.some(pattern =>
                            new RegExp(pattern).test(normalizedInput)
                          ) ||
                          candidate.label
                            .toLowerCase()
                            .includes(normalizedInput)
                        );
                      }}
                      value={stringSlots.find(
                        slot => slot.value === value.toString()
                      )}
                      onChange={option => {
                        const slot = option as { value: string; label: string };
                        if (!slot) return;

                        // Parse the selected start time
                        const tz = dayjs.tz.guess();
                        const dateStr = appointment?.starts
                          ? convertUtcIntToLocalDatetime(
                              appointment?.starts
                            ).format("YYYY-MM-DD")
                          : dayjs().format("YYYY-MM-DD");
                        const startTime = dayjs(
                          `${dateStr} ${slot.value}`,
                          "YYYY-MM-DD h:mm A"
                        ).tz(tz);
                        onChange(slot.value);

                        // Determine the default duration associated with this appointment
                        let duration = 30;
                        const selectedApptType = appointmentTypes?.find(
                          appt =>
                            appt.appointment_type ==
                            appointmentType?.appointment_type
                        );
                        if (selectedApptType) {
                          duration = selectedApptType.default_duration;
                        }

                        // Prepopulate the end time with (startTime + duration) formatted as h:mm A
                        const endTime = startTime.add(duration, "minute");
                        // @ts-ignore
                        form.setValue(
                          "appointment.ends",
                          // @ts-ignore
                          endTime.format("h:mm A")
                        );
                      }}
                      placeholder="Start Time"
                    />
                  )}
                  rules={{ required: true }}
                />

                <Controller
                  name="appointment.ends"
                  control={form.control}
                  // NOTE: The type definition for the value is not correct. They are actually strings like "11:00 AM"
                  // or "1:00 PM", and that's what we need to save to the appointment.starts field.
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="appt-end"
                      openMenuOnFocus
                      styles={SelectDefaultStyles}
                      theme={SelectDefaultTheme}
                      options={stringSlots}
                      filterOption={(candidate, input) => {
                        // Normalize the input
                        const normalizedInput = input
                          .toLowerCase()
                          .replace(/\s/g, "");

                        // Parse the candidate time (assuming it's in the label)
                        const [targetHour, targetMinute, targetPeriod] =
                          candidate.label
                            .match(/(\d+):(\d+)\s*(AM|PM)/i)
                            ?.slice(1) || [];
                        if (!targetHour || !targetMinute || !targetPeriod)
                          return false;

                        // Create regex patterns based on the candidate time
                        const hourPattern = targetHour.padStart(2, "0");
                        const minutePattern = targetMinute.padStart(2, "0");
                        const periodPattern = targetPeriod.toLowerCase();

                        const patterns = [
                          `^${hourPattern}:?${minutePattern}${periodPattern}$`,
                          `^${parseInt(targetHour)}:?${minutePattern}${periodPattern}$`,
                          `^${parseInt(targetHour)}${periodPattern}$`,
                          `^${parseInt(targetHour)}:?${minutePattern}$`,
                          `^${parseInt(targetHour)}$`
                        ];

                        // Test the input against all patterns
                        return (
                          patterns.some(pattern =>
                            new RegExp(pattern).test(normalizedInput)
                          ) ||
                          candidate.label
                            .toLowerCase()
                            .includes(normalizedInput)
                        );
                      }}
                      value={{
                        value: value,
                        label: value
                      }}
                      onChange={option => {
                        const slot = option as { value: string; label: string };
                        if (!slot) return;

                        // Parse the selected end time
                        const dateStr = appointment?.starts
                          ? convertUtcIntToLocalDatetime(
                              appointment?.starts
                            ).format("YYYY-MM-DD")
                          : dayjs().format("YYYY-MM-DD");
                        const endTime = dayjs(
                          `${dateStr} ${slot.value}`,
                          "YYYY-MM-DD h:mm A"
                        );
                        onChange(slot.value);

                        const timeErr = endTime.isSameOrBefore(
                          dayjs(
                            form.getValues("appointment.starts"),
                            "YYYYMMDDHHmmss"
                          )
                        );
                        setHasTimeError(timeErr);
                      }}
                      placeholder="End Time"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            ) : (
              <p className="dark">
                {appointment?.starts &&
                  convertUtcIntToLocalDatetime(appointment?.starts).format(
                    "h:mm a"
                  )}
                -
                {appointment?.ends &&
                  convertUtcIntToLocalDatetime(appointment?.ends).format(
                    "h:mm a"
                  )}
              </p>
            )}
          </div>
        </div>
      </DashboardCard>
      {
        <BasicAccordion title="Note" style={STYLES.SECONDARY}>
          <div className={styles.content}>
            {isEditMode ? (
              <ControlledTextArea
                form={form}
                hiddenLabel
                label="appointment note"
                placeholder="Add a note about the appointment here."
                id="appointment.chief_complaint"
                name="appointment.chief_complaint"
              />
            ) : (
              <ContentRenderer
                content={sanitize(
                  appointment?.chief_complaint ||
                    "No notes added for this appointment"
                )}
              />
            )}
          </div>
        </BasicAccordion>
      }
      <h2 className="dark smBld">Patient Information</h2>
      <BasicAccordion
        title="General Information"
        open={!isCheckingOut}
        style={STYLES.TERTIARY}
      >
        <div className={styles.grid}>
          {isEditMode && (
            <>
              {isEditMode ? (
                <div className="flex fullWidth" data-cy="new-patient-toggle">
                  <Toggle
                    label={"New Patient"}
                    onChange={() => setIsNewPatient(!isNewPatient)}
                    labelIsRight
                  />
                  <div></div>
                </div>
              ) : (
                <div></div>
              )}
              <div
                className={clsx(styles.row, {
                  [styles.isEditMode]: isEditMode
                })}
              >
                {" "}
                {!isNewPatient ? (
                  <div className="t4 xLight">Select Patient</div>
                ) : (
                  <div></div>
                )}
                {!isNewPatient && isEditMode && (
                  <Controller
                    name="patient"
                    control={form.control}
                    //@ts-ignore
                    defaultValue={patient}
                    render={({ field: { value } }) => (
                      <>
                        <Select
                          id="patient-select"
                          openMenuOnFocus
                          theme={SelectDefaultTheme}
                          styles={SelectDefaultStyles}
                          onInputChange={newValue => setQuery(newValue)}
                          filterOption={(candidate, input) => true} // Filtering is done server-side
                          isLoading={isPatientsLoading}
                          options={patients}
                          value={patients.find(
                            p => p.value.user_id === value?.user_id
                          )}
                          onChange={async newValue => {
                            //@ts-ignore some type kinks to iron out still
                            const patient = newValue?.value;
                            const p = await getPatientInfo({
                              patientId: patient?.user_id as number
                            }).unwrap();
                            if (p) {
                              setPatient(p);
                              form.setValue(
                                "appointment.patient_id",
                                p.user_id
                              );
                              form.setValue("patient.user_id", p.user_id);
                              form.setValue("patient.first_name", p.first_name);
                              form.setValue("patient.last_name", p.last_name);
                              if (p.phone) {
                                const phoneUtil =
                                  libphonenumber.PhoneNumberUtil.getInstance();
                                const phone = phoneUtil?.parse(p.phone, "US");
                                let phoneStr =
                                  phoneUtil.formatInOriginalFormat(
                                    phone,
                                    "US"
                                  ) || "";
                                form.setValue("patient.mobile", phoneStr);
                              }
                              form.setValue(
                                "patient.dob",
                                dayjs(`${p.dob}`, "YYYYMMDD").format(
                                  "YYYY-MM-DD"
                                ) as unknown as number
                              );

                              form.setValue("patient.sex", p.sex);
                              if (featureFlags?.pronouns_enabled) {
                                form.setValue("patient.pronouns", p.pronouns);
                              }
                              form.setValue("patient.email", p.email);

                              if (p.user_id) {
                                getPatientInfo({ patientId: p.user_id })
                                  .unwrap()
                                  .then(patient => {
                                    form.setValue("patient", patient);
                                    const phoneUtil =
                                      libphonenumber.PhoneNumberUtil.getInstance();
                                    const phone = phoneUtil.parse(
                                      p.phone,
                                      "US"
                                    );
                                    let phoneStr =
                                      phoneUtil.formatInOriginalFormat(
                                        phone,
                                        "US"
                                      ) || "";
                                    form.setValue("patient.mobile", phoneStr);
                                    form.setValue(
                                      "patient.address.line1",
                                      patient.address?.line1
                                    );
                                    form.setValue(
                                      "patient.address.line2",
                                      patient.address?.line2
                                    );
                                    form.setValue(
                                      "patient.address.line3",
                                      patient.address?.line3
                                    );
                                    form.setValue(
                                      "patient.address.city",
                                      patient.address?.city
                                    );
                                    form.setValue(
                                      "patient.address.state",
                                      patient.address?.state
                                    );
                                    form.setValue(
                                      "patient.address.zip",
                                      patient.address?.zip
                                    );
                                    form.setValue(
                                      "patient.practice_data.type",
                                      patient.practice_data.type
                                    );
                                    // We only want to do this if the lmp actually exists otherwise
                                    // dayjs will default to today.
                                    if (patient.pregnancy?.lmp) {
                                      form.setValue(
                                        "LMP",
                                        patient.pregnancy.lmp
                                          .last_menstrual_period
                                      );
                                    }
                                  })
                                  .catch(err => {
                                    console.error(err);
                                  });
                              }
                            }
                          }}
                        />
                      </>
                    )}
                    rules={{ required: true }}
                  />
                )}
              </div>
            </>
          )}

          {!isEditMode && appointment?.patient && (
            <div
              className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
            >
              <div className="t4 xLight">Patient</div>
              {appointment?.patient && (
                <div className="flex dark t4">
                  {FORMAT.name(appointment?.patient)}
                  <button
                    type="button"
                    onClick={() => {
                      router.push(
                        `${PAGES.PATIENTS}/${encodeURIComponent(
                          appointment?.patient?.user_id as number
                        )}`
                      );
                      dispatch(setRightPaneOpen(false));
                    }}
                  >
                    <Icon svg="open_page" width={14} height={14} />
                  </button>
                </div>
              )}
            </div>
          )}

          {isEditMode && (
            <div
              className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
            >
              <div className="t4 xLight">First Name*</div>
              <div className="flex fullWidth" data-cy="first-name">
                <GeneratedInput<AppointmentFormType>
                  id={"patient.first_name"}
                  name={"patient.first_name"}
                  placeholder={"first name"}
                  type="text"
                  register={form.register}
                  errors={form.formState.errors}
                  label="First Name"
                  hiddenLabel
                  fullWidth
                  required
                />
              </div>
            </div>
          )}

          {isEditMode && (
            <div
              className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
            >
              <div className="t4 xLight">Last Name*</div>
              <div className="flex fullWidth" data-cy="last-name">
                <GeneratedInput<AppointmentFormType>
                  id={"patient.last_name"}
                  name={"patient.last_name"}
                  placeholder={"last name"}
                  type="text"
                  register={form.register}
                  errors={form.formState.errors}
                  label="Last Name"
                  hiddenLabel
                  required
                  fullWidth
                />
              </div>
            </div>
          )}

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Date of Birth*</div>
            {!isEditMode ? (
              <p className="dark">
                {dayjs(`${form.getValues("patient.dob")}`, "YYYYMMDD").format(
                  "MM/DD/YYYY"
                )}
              </p>
            ) : (
              <div className="flex fullWidth" data-cy="dob">
                <GeneratedInput<AppointmentFormType>
                  id={"patient.dob"}
                  name={"patient.dob"}
                  type="date"
                  register={form.register}
                  errors={form.formState.errors}
                  label="Date of Birth"
                  fullWidth
                  required
                  hiddenLabel
                />
              </div>
            )}
          </div>
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Sex</div>
            {!isEditMode ? (
              <p className="dark">
                {form.getValues("patient.sex")
                  ? METRIC_LABELS[form.getValues("patient.sex") as string]
                  : ""}
              </p>
            ) : (
              <Controller
                name="patient.sex"
                control={form.control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <Select
                        id="patient-sex"
                        openMenuOnFocus
                        styles={SelectDefaultStyles}
                        theme={SelectDefaultTheme}
                        options={CustomPatientSexOptions.map(item => ({
                          label: item
                            ?.substring(0, 1)
                            ?.concat(item?.substring(1)?.toLowerCase()),
                          value: item as Sex
                        }))}
                        onChange={v => {
                          if (typeof v === "string") {
                            onChange(v);
                          } else {
                            onChange((v as Record<string, string>)["value"]);
                          }
                        }}
                        value={(() => {
                          if (typeof value === "string") {
                            return {
                              value: value,
                              label: value
                                ?.substring(0, 1)
                                ?.concat(value?.substring(1)?.toLowerCase())
                            };
                          }

                          return value;
                        })()}
                      />
                      {form.formState.errors?.patient?.practice_data?.type && (
                        <p className="error">Required</p>
                      )}
                    </>
                  );
                }}
              />
            )}
          </div>

          {featureFlags?.pronouns_enabled && (
            <div
              className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
            >
              <div className="t4 xLight">Pronouns</div>
              {!isEditMode ? (
                <p className="dark">{form.getValues("patient.pronouns")}</p>
              ) : (
                <GeneratedInput<AppointmentFormType>
                  label="Pronouns"
                  id={"patient-pronouns"}
                  name={"patient.pronouns"}
                  register={form.register}
                  placeholder={"she/her, he/him, they/them"}
                  errors={form.formState.errors}
                  fullWidth
                  hiddenLabel
                />
              )}
            </div>
          )}
          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Patient Type</div>
            {!isEditMode ? (
              <p className="dark">
                {METRIC_LABELS[patient?.practice_data?.type || "PROSPECTIVE"]}
              </p>
            ) : (
              <div className="flex fullWidth" data-cy="patient-type">
                <Controller
                  name="patient.practice_data.type"
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        openMenuOnFocus
                        styles={SelectDefaultStyles}
                        theme={SelectDefaultTheme}
                        options={CustomPatientTypes.map(item => ({
                          value: item,
                          label: METRIC_LABELS[item]
                        }))}
                        //@ts-ignore
                        onChange={newValue => onChange(newValue.value)}
                        value={{ value: value, label: METRIC_LABELS[value] }}
                      />
                      {form.formState.errors?.patient?.practice_data?.type && (
                        <p className="error">Required</p>
                      )}
                    </>
                  )}
                />
              </div>
            )}
          </div>
          {/* only display last menstrual period input if selected patient type is OB */}
          {(patientType === METRICS.OB ||
            patientType === METRICS.PROSPECTIVE) && (
            <div
              className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
            >
              <div className="t4 xLight">LMP</div>
              {!isEditMode ? (
                <p className="dark">{patientLmp}</p>
              ) : (
                <div className="flex fullWidth" data-cy="lmp">
                  <GeneratedInput<AppointmentFormType>
                    id={"LMP"}
                    name={"LMP"}
                    type="date"
                    register={form.register}
                    errors={form.formState.errors}
                    label="LMP"
                    fullWidth
                    hiddenLabel
                  />
                </div>
              )}
            </div>
          )}

          {patientType === METRICS.INFANT && (
            <div
              className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
            >
              <div className="t4 xLight">Parent*</div>
              {!isEditMode ? (
                patientParent ? (
                  <div className="flex dark t4">
                    {FORMAT.name(patientParent as PatientInfo)}
                    <button
                      type="button"
                      onClick={() => {
                        router.push(
                          `${PAGES.PATIENTS}/${encodeURIComponent(
                            patientParent?.user_id as number
                          )}`
                        );
                        dispatch(setRightPaneOpen(false));
                      }}
                    >
                      <Icon svg="open_page" width={14} height={14} />
                    </button>
                  </div>
                ) : (
                  "N/A"
                )
              ) : (
                <>
                  <Select
                    id="patient-parent"
                    openMenuOnFocus
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    defaultValue={{
                      value: patientParent,
                      label: patientParent ? FORMAT.name(patientParent) : ""
                    }}
                    options={([...(users?.patients || [])] || [])
                      .sort((a, b) => a.last_name.localeCompare(b.last_name))
                      .map(item => ({
                        label: FORMAT.name(item),
                        value: item
                      }))}
                    value={{
                      label: patientParent ? FORMAT.name(patientParent) : "",
                      value: patientParent
                    }}
                    onChange={v => {
                      //@ts-ignore
                      const patient = v?.value;
                      if (patient) {
                        setPatientParent(patient);
                        if (isNewPatient) {
                          form.setValue(
                            "patient.address.line1",
                            patient.address?.line1
                          );
                          form.setValue(
                            "patient.address.line2",
                            patient.address?.line2
                          );
                          form.setValue(
                            "patient.address.line3",
                            patient.address?.line3
                          );
                          form.setValue(
                            "patient.address.city",
                            patient.address?.city
                          );
                          form.setValue(
                            "patient.address.state",
                            patient.address?.state
                          );
                          form.setValue(
                            "patient.address.zip",
                            patient.address?.zip
                          );
                        }
                      }
                    }}
                  />
                </>
              )}
            </div>
          )}
          {patientType !== METRICS.INFANT && (
            <>
              {isEditMode && (
                <div className="note">
                  <Icon svg="lightbulb" width={12} />
                  Patient email and phone number must be unique
                </div>
              )}
              <div
                className={clsx(styles.row, {
                  [styles.isEditMode]: isEditMode
                })}
              >
                <div className="t4 xLight">Email{!patientParent && "*"}</div>
                {!isEditMode ? (
                  <p className="dark">{form.getValues("patient.email")}</p>
                ) : (
                  <div className="flex fullWidth" data-cy="email">
                    <GeneratedInput<AppointmentFormType>
                      id={"patient.email"}
                      name={"patient.email"}
                      type="email"
                      register={form.register}
                      errors={form.formState.errors}
                      label="Email"
                      fullWidth
                      required={!patientParent}
                      hiddenLabel
                    />
                  </div>
                )}
              </div>
              <div
                className={clsx(styles.row, {
                  [styles.isEditMode]: isEditMode
                })}
              >
                <div className="t4 xLight">Phone{!patientParent && "*"}</div>
                {!isEditMode ? (
                  // @ts-ignore (patient going in as mobile, coming back as phone)
                  <p className="dark">
                    {formatPhone(
                      form.getValues("patient.mobile") ||
                        patient?.phone ||
                        ("" as string)
                    )}
                  </p>
                ) : (
                  <div className="flex fullWidth" data-cy="phone">
                    <GeneratedInput<AppointmentFormType>
                      defaultValue={formatPhone(patient?.phone || "")}
                      id={"patient.mobile"}
                      name={"patient.mobile"}
                      type="tel"
                      register={form.register}
                      errors={form.formState.errors}
                      label="Mobile"
                      fullWidth
                      required={!patientParent}
                      hiddenLabel
                    />
                  </div>
                )}
              </div>
            </>
          )}

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Addr. Line 1</div>
            {!isEditMode ? (
              <p className="dark">{form.getValues("patient.address.line1")}</p>
            ) : (
              <GeneratedInput<AppointmentFormType>
                id={"addr-line1"}
                name={"patient.address.line1"}
                type="text"
                register={form.register}
                errors={form.formState.errors}
                label="Address Line 1"
                fullWidth
                hiddenLabel
              />
            )}
          </div>

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Addr. Line 2</div>
            {!isEditMode ? (
              <p className="dark">{form.getValues("patient.address.line2")}</p>
            ) : (
              <GeneratedInput<AppointmentFormType>
                id={"addr-line2"}
                name={"patient.address.line2"}
                type="text"
                register={form.register}
                errors={form.formState.errors}
                label="Address Line 2"
                fullWidth
                hiddenLabel
              />
            )}
          </div>

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Addr. Line 3</div>
            {!isEditMode ? (
              <p className="dark">{form.getValues("patient.address.line3")}</p>
            ) : (
              <GeneratedInput<AppointmentFormType>
                id={"addr-line3"}
                name={"patient.address.line3"}
                type="text"
                register={form.register}
                errors={form.formState.errors}
                label="Address Line 3"
                fullWidth
                hiddenLabel
              />
            )}
          </div>

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">City</div>
            {!isEditMode ? (
              <p className="dark">{form.getValues("patient.address.city")}</p>
            ) : (
              <GeneratedInput<AppointmentFormType>
                id={"addr-city"}
                name={"patient.address.city"}
                type="text"
                register={form.register}
                errors={form.formState.errors}
                label="City"
                fullWidth
                hiddenLabel
              />
            )}
          </div>

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">State</div>
            {!isEditMode ? (
              <p className="dark">{form.getValues("patient.address.state")}</p>
            ) : (
              <Controller
                name="patient.address.state"
                control={form.control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <>
                    <Select
                      id="patient-state"
                      openMenuOnFocus
                      styles={SelectDefaultStyles}
                      theme={SelectDefaultTheme}
                      options={STATE_CODES.map(item => ({
                        value: item,
                        label: item
                      }))}
                      value={{ value: value, label: value }}
                      //@ts-ignore
                      onChange={newValue => {
                        //@ts-ignore
                        onChange(newValue.value);
                      }}
                    />
                  </>
                )}
              />
            )}
          </div>

          <div
            className={clsx(styles.row, { [styles.isEditMode]: isEditMode })}
          >
            <div className="t4 xLight">Zip</div>
            {!isEditMode ? (
              <p className="dark">{form.getValues("patient.address.zip")}</p>
            ) : (
              <GeneratedInput<AppointmentFormType>
                id={"addr-zip"}
                name={"patient.address.zip"}
                type="text"
                register={form.register}
                errors={form.formState.errors}
                label="Zip"
                fullWidth
                hiddenLabel
              />
            )}
          </div>
        </div>
      </BasicAccordion>

      <BasicAccordion
        title="Attachments"
        open={!isCheckingOut}
        style={STYLES.TERTIARY}
      >
        {isEditMode ? (
          <div className={styles.content}>
            <Controller
              name={"appointment.tasks"}
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="task-assignments"
                  openMenuOnFocus
                  styles={SelectDefaultStyles}
                  theme={SelectDefaultTheme}
                  isMulti
                  options={availableForms.map(item => ({
                    label: item.display,
                    value: item
                  }))}
                  onChange={val => {
                    // @ts-ignore
                    onChange(val.map(item => item.value));
                  }}
                  value={value?.map(item => ({
                    label: item.display,
                    value: item
                  }))}
                />
              )}
            />
          </div>
        ) : (
          <div>
            <p className="t4 tMd dark padded">
              {appointment?.tasks?.length && appointment?.tasks?.length > 0
                ? "Forms (to be completed by patients):"
                : "No forms assigned to patient"}
            </p>
            <div className={styles.tagContainer}>
              {appointment?.tasks?.map(task => (
                <Tag
                  key={task.task_id}
                  label={task.display}
                  type={STATUS_KEYS.INFO_GREY}
                />
              ))}
            </div>
          </div>
        )}
      </BasicAccordion>
      {featureFlags?.patient_checkout_enabled && appointment?.encounter_id && (
        <PatientCheckout
          patient={patient}
          encounterId={appointment?.encounter_id}
          appointmentData={appointment}
          isOpen={isCheckingOut}
        />
      )}

      <div className={styles.buttons}>
        <>
          {isEditMode ? (
            <div className={appointment?.appointment_id ? "grid2" : "grid2"}>
              {appointment?.appointment_id && (
                <Button
                  type="button"
                  onClick={handleDeleteAppointment}
                  style={STYLES.DELETE}
                  nativeButtonProps={{
                    disabled:
                      isUpsertingAppointment ||
                      isUpsertingPatient ||
                      isUpsertingPregnancy
                  }}
                >
                  <Delete stroke={styles.errorText} width={15} height={17} />
                  Delete
                </Button>
              )}
              <div className="flex fullWidth" data-cy="save">
                <Button
                  style={STYLES.FULL_WIDTH}
                  type="submit"
                  nativeButtonProps={{
                    disabled:
                      hasTimeError ||
                      !appointmentType ||
                      (!isNewPatient && !patient)
                  }}
                  loading={
                    isUpsertingAppointment ||
                    isUpsertingPatient ||
                    isUpsertingPregnancy
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            !sessionInfo?.is_biller &&
            appointment &&
            appointment?.status && (
              <div
                className="flex fullWidth"
                data-cy="schedule-open-appointment"
              >
                <Button
                  style={STYLES.FULL_WIDTH}
                  onClick={handleOpenAppointment(
                    appointment as AppointmentInfo
                  )}
                >
                  {CHART_STATUS_MAP[appointment.status]?.label}
                </Button>
              </div>
            )
          )}
        </>
      </div>
    </form>
  ) : (
    <div className={styles.loading}>
      Fetching appointment information...
      <LoadingSpinner />
    </div>
  );
}

function getDefaultProvider(
  appointment: Partial<AppointmentInfo> | undefined,
  providerFilter: number[],
  providers: ProviderListItem[],
  sessionInfo: Cookie | null
): ProviderListItem | null {
  let defaultProvider: ProviderListItem | null = null;
  if (appointment?.provider) {
    const matchingProvider = providers.find(
      ({ user_id }) => user_id === appointment.provider?.user_id
    );
    defaultProvider = matchingProvider ? matchingProvider : defaultProvider;
  } else if (providerFilter !== null && providerFilter.length == 1) {
    const matchingProvider = providers.find(
      ({ user_id }) => user_id === providerFilter[0]
    );
    defaultProvider = matchingProvider ? matchingProvider : defaultProvider;
  } else if (
    sessionInfo?.is_superadmin ||
    sessionInfo?.is_ma ||
    sessionInfo?.is_provider
  ) {
    const matchingProvider = providers.find(
      ({ user_id }) => user_id === sessionInfo.user_id
    );
    defaultProvider = matchingProvider ? matchingProvider : defaultProvider;
  }
  return defaultProvider;
}
