/* ViewClaim Name */
/* External Imports */
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { useEffect, useState } from "react";

/* Local Imports */

// components
import Skeleton from "react-loading-skeleton";
import Tag from "@/components/tag";
import DashboardCard from "@/components/cards/dashboardCard";
import ClaimTimeline from "@/components/claimTimeline";
import StandardFlow from "@/components/flows/standardFlow";
import TabMenu from "@/components/tabbed";
import ClaimOutcome from "@/components/claimOutcome";
import EmptyState from "@/components/cards/emptyState";
import SuperbillContent from "@/components/superbill";
import FileUploader from "@/components/fileExplorer/upload";
import Icon from "@/components/icons";
import Button from "@/components/button";
import FileViewer from "@/components/fileExplorer/fileViewer";

// constants
import {
  METRIC_LABELS,
  METRICS,
  STATUS_KEYS,
  STYLES
} from "@/globals/constants";
import { MODAL_TYPES } from "../dispatcher";
// store
import { EncounterId, useEncounterInfoQuery } from "@/store/services/encounter";
import { ClaimId, ClaimListItem } from "@/store/services/claim";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import {
  useUploadClaimEobMutation,
  useUploadClaimHcfaMutation,
  useListClaimsQuery
} from "@/store/services/claim";
import {
  FileId,
  FileListItem,
  useFilesQuery,
  useFileBatchUpdateMutation
} from "@/store/services/file";
import { removeAlertFromToastTrough } from "@/components/toastTrough/toastSlice";
import { RootState } from "@/store/store";
import { setModalIsOpen } from "../modalSlice";

// utils
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// styles
import styles from "../styles.module.scss";
import FileContent, { FileType } from "@/components/fileContent";

/* ViewClaim Typescript Interface */
interface ViewClaimProps {
  claimId: ClaimId;
  encounterId: EncounterId;
  isSelfService?: boolean;
}

export default function ViewClaim({
  claimId,
  encounterId,
  isSelfService = false
}: ViewClaimProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: encounter, isLoading: encounterIsLoading } =
    useEncounterInfoQuery({ encounterId });
  // get the claim
  const {
    data: claims,
    isLoading: isClaimsLoading,
    isError: isClaimsError
  } = useListClaimsQuery({ claimId });

  const claim = claims?.items[0];

  const [uploadClaimEob] = useUploadClaimEobMutation();
  const [uploadClaimHcfa] = useUploadClaimHcfaMutation();
  const [batchUpdate, { error, isError, isSuccess }] =
    useFileBatchUpdateMutation();

  const { data: eobs } = useFilesQuery(
    { fileIds: claim?.eob_ids },
    { skip: !claim }
  );
  const { data: hcfas } = useFilesQuery(
    { fileIds: claim?.hcfa_ids as FileId[] },
    { skip: !claim }
  );

  /* Local State */
  const [selectedFiles, setSelectedFile] = useState<{
    hcfa: FileListItem | null;
    eob: FileListItem | null;
    ["lab result"]: FileListItem | null;
  }>({ hcfa: null, eob: null, "lab result": null });

  /* Effects */

  /* Event Handlers */

  const handleFileUpload = (files: FileList, fileType: FileType) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      formData.append(f.name, f, f.name);
    }

    const toastId = v4();
    dispatch(
      addAlertToToastTrough({
        message: "Uploading files...",
        type: STATUS_KEYS.LOADING,
        id: toastId
      })
    );

    const uploadHandler = fileType === "eob" ? uploadClaimEob : uploadClaimHcfa;

    // @ts-ignore
    // TODO: @luca to update types
    uploadHandler({ claimId, body: formData })
      .then(() => {
        dispatch(removeAlertFromToastTrough(toastId));
      })
      .catch(() => {
        dispatch(removeAlertFromToastTrough(toastId));
      });
  };

  const handleViewFile = (file: FileListItem, fileType: FileType) => {
    // view file locally
    if (file.file_id === selectedFiles[fileType]?.file_id) {
      setSelectedFile(prev => ({ ...prev, [fileType]: null }));
    } else {
      setSelectedFile(prev => ({ ...prev, [fileType]: file }));
    }
  };

  const handleReleaseFilesToPatient = (
    fileId: FileId,
    releaseToPatient: boolean
  ) => {
    batchUpdate({
      fileBatchUpdateRequest: {
        file_ids: [fileId],
        viewable_by_patient: releaseToPatient
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Successfully ${releaseToPatient ? "shared" : "hid"} file`,
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        addAlertToToastTrough({
          message: `Faile to ${releaseToPatient ? "share" : "hide"} file`,
          type: STATUS_KEYS.ERROR
        });
      });
  };

  function renderFileContent(fileType: "eob" | "hcfa", files?: FileListItem[]) {
    return (
      <div className="padded flexCol">
        {sessionInfo?.is_biller && (
          <FileUploader
            onFilesAdded={file => handleFileUpload(file, fileType)}
          />
        )}
        {files && files.length > 0 ? (
          <div className={styles.fileList}>
            {files.map(file => (
              <div
                className={clsx(styles.file, {
                  [styles.selected]:
                    file.file_id === selectedFiles[fileType]?.file_id
                })}
                key={file.file_id}
              >
                <div className="flex">
                  <Icon svg="file_highlighted" width={30} height={30} />
                  <div>
                    <p className={styles.fileName}>{file.name}</p>
                    <p className="t5 gray400">
                      uploaded at{" "}
                      {convertUtcIntToLocalDatetime(file.created).format(
                        "hh:mma on MM/DD/YYYY"
                      )}
                    </p>
                    <div className="flex t5 gray400">
                      <Icon
                        svg={
                          file.viewable_by_patient
                            ? "check_circle_blue"
                            : "hide"
                        }
                        width={12}
                      />
                      {file.viewable_by_patient
                        ? "Visible to Patient"
                        : "Hidden from Patient"}
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <Button
                    style={STYLES.SECONDARY}
                    onClick={() => handleViewFile(file, fileType)}
                  >
                    <Icon
                      svg={
                        file.file_id === selectedFiles[fileType]?.file_id
                          ? "hide"
                          : "eye"
                      }
                    />
                    {file.file_id === selectedFiles[fileType]?.file_id
                      ? "Hide File"
                      : "View File"}
                  </Button>
                  <a href={file.url} download>
                    <Button style={STYLES.SECONDARY}>
                      <Icon svg={"arrow_down"} />
                      Download File
                    </Button>
                  </a>
                  <Button
                    style={STYLES.SECONDARY}
                    onClick={() =>
                      handleReleaseFilesToPatient(
                        file.file_id,
                        !file.viewable_by_patient
                      )
                    }
                  >
                    <Icon svg={file.viewable_by_patient ? "hide" : "share"} />
                    {file.viewable_by_patient
                      ? "Hide from Patient"
                      : "Share with Patient"}
                  </Button>
                </div>
              </div>
            ))}
            {selectedFiles[fileType] && (
              <FileViewer
                isReadOnly
                file={selectedFiles[fileType] as FileListItem}
                onClose={() =>
                  setSelectedFile(prev => ({ ...prev, [fileType]: null }))
                }
              />
            )}
          </div>
        ) : (
          !sessionInfo?.is_biller && <EmptyState />
        )}
      </div>
    );
  }

  return (
    <div className={clsx(styles.ViewClaim)}>
      {/* ViewClaim Content */}
      <TabMenu
        tabs={[
          { id: "superbill", label: "Superbill" },
          { id: "encounter", label: "Encounter" },
          { id: "claimOutcome", label: "Claim " + claimId.slice(-5) }
        ]}
      >
        {tab => {
          switch (tab) {
            case "superbill":
              return (
                <>
                  {encounterIsLoading && (
                    <div>
                      <Skeleton count={10.5} />
                    </div>
                  )}
                  {encounter && (
                    <SuperbillContent
                      encounterId={encounter.encounter_id}
                      isClaimView
                    />
                  )}
                </>
              );
            case "encounter":
              return (
                <>
                  {encounterIsLoading && (
                    <div>
                      <Skeleton count={10.5} />
                    </div>
                  )}
                  {encounter && (
                    <StandardFlow encounter={encounter} readOnly isPetrified />
                  )}
                </>
              );
            case "claimOutcome":
              return (
                <>
                  <DashboardCard
                    title="Claim"
                    customRightElement={
                      <button
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(claimId);
                          dispatch(
                            addAlertToToastTrough({
                              message:
                                "Copied full claim ID " +
                                claimId +
                                " to clipboard",
                              type: STATUS_KEYS.INFO
                            })
                          );
                        }}
                        title={"Click to copy full claim ID to clipboard"}
                      >
                        <Tag
                          label={`${
                            METRIC_LABELS[METRICS.CLAIM_ID]
                          }: ${claimId.slice(-5)}`}
                          type={STATUS_KEYS.INFO_GREY}
                        />
                      </button>
                    }
                  >
                    {encounter ? (
                      <ClaimOutcome claimId={claimId} encounter={encounter} />
                    ) : (
                      <Skeleton count={5} />
                    )}
                  </DashboardCard>
                  {/* render hcfa files */}
                  <DashboardCard title="HCFA 1500 / UB-04">
                    <FileContent
                      fileType="hcfa"
                      files={hcfas}
                      onFileUpload={handleFileUpload}
                      selectedFile={selectedFiles.hcfa}
                      onFileSelect={handleViewFile}
                      hasUploadPermission={!!sessionInfo?.is_biller}
                    />
                  </DashboardCard>

                  <DashboardCard title="Explanation of Benefit">
                    <FileContent
                      fileType="eob"
                      files={eobs}
                      onFileUpload={handleFileUpload}
                      selectedFile={selectedFiles.eob}
                      onFileSelect={handleViewFile}
                      hasUploadPermission={!!sessionInfo?.is_biller}
                    />
                  </DashboardCard>
                </>
              );

            default:
              return <div></div>;
          }
        }}
      </TabMenu>

      <DashboardCard
        title="Audit"
        customRightElement={
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(claimId);
              dispatch(
                addAlertToToastTrough({
                  message: "Copied full claim ID " + claimId + " to clipboard",
                  type: STATUS_KEYS.INFO
                })
              );
            }}
            title={"Click to copy full claim ID to clipboard"}
          >
            <Tag
              label={`${METRIC_LABELS[METRICS.CLAIM_ID]}: ${claimId.slice(-5)}`}
              type={STATUS_KEYS.INFO_GREY}
            />
          </button>
        }
      >
        <ClaimTimeline
          claimId={claimId}
          isSelfService={isSelfService}
          claimStatus={claim?.status}
        />
      </DashboardCard>
    </div>
  );
}
