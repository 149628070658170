import * as generated from "./generated/patient";

const patient = generated.patient.enhanceEndpoints({
  addTagTypes: ["Patient-Files", "Recalls"],
  endpoints: {
    patientUpsert: {
      invalidatesTags: (_res, _err, req) => [
        {
          type: "Patients",
          id:
            req.patientUpsertRequest.parent_ids &&
            req.patientUpsertRequest.parent_ids[0]
        },
        "Patients"
      ]
    },
    patientGetInfo: {
      providesTags: (_resp, _err, req) => [
        { type: "Patients", id: req.patientId },
        { type: "Problems", id: req.patientId }
      ]
    },
    patientSubmitForm: {
      invalidatesTags: (_res, _err, req) => [
        { type: "Patients", id: req.patientId },
        { type: "Forms" }
      ]
    },
    patientGetForm: {
      providesTags: ["Forms"]
    },
    patientGetFiles: {
      providesTags: (_res, _err, req) => [
        { type: "Patient-Files", id: req.patientId }
      ]
    },
    patientUploadFiles: {
      invalidatesTags: (_res, _err, req) => [
        { type: "Patient-Files", id: req.patientId }
      ]
    },
    patientDeleteFiles: {
      invalidatesTags: (_res, _err, req) => [
        { type: "Patient-Files", id: req.patientId }
      ]
    },
    patientArchive: {
      invalidatesTags: ["Patients"]
    },
    patientUnarchive: {
      invalidatesTags: ["Patients"]
    },
    patientArchiveDiagnosis: {
      invalidatesTags: (_resp, _err, req) => [
        { type: "Patients", id: req.patientId },
        { type: "Problems", id: req.patientId }
      ]
    },
    patientGetTransactions: {
      providesTags: ["Transactions"]
    },
    patientCreateTransaction: {
      invalidatesTags: ["Transactions"]
    },
    patientUpdateTransaction: {
      invalidatesTags: ["Transactions"]
    },
    patientGetBalance: {
      providesTags: ["Transactions"]
    },
    patientGetInfantGrowthSheet: {
      providesTags: ["InfantFlowSheet"]
    },
    getPatientRecall: {
      providesTags: ["Recalls"]
    },
    listPatientRecalls: {
      providesTags: ["Recalls"]
    },
    createPatientRecall: {
      invalidatesTags: ["Recalls"]
    },
    deletePatientRecall: {
      invalidatesTags: ["Recalls"]
    },
    updatePatientRecall: {
      invalidatesTags: ["Recalls"]
    },
    patientCloseDoseSpot: {
      invalidatesTags: ["Patients"]
    },
    createtHealthHistoryEntry: {
      invalidatesTags: ["Patients"]
    },
    getHealthHistory: {
      providesTags: ["Patients"]
    },
    updateHealthHistoryEntry: {
      invalidatesTags: ["Patients"]
    },
    deleteHealthHistoryEntry: {
      invalidatesTags: ["Patients"]
    },
    taskGeneratePdf: {
      invalidatesTags: ["Tasks"]
    },
    patientDelete: {
      invalidatesTags: ["Patients"]
    }
  }
});

export * from "./generated/patient";
export default patient;
export const {
  usePatientUpsertMutation,
  usePatientGetInfoQuery,
  useLazyPatientGetInfoQuery,
  usePatientGetFormQuery,
  useLazyPatientGetFormQuery,
  usePatientSubmitFormMutation,
  usePatientGetFilesQuery,
  useLazyPatientGetFilesQuery,
  usePatientUploadFilesMutation,
  usePatientDeleteFilesMutation,
  usePatientArchiveMutation,
  usePatientGetDoseSpotSsoUrlQuery,
  useLazyPatientGetDoseSpotSsoUrlQuery,
  usePatientUnarchiveMutation,
  usePatientDiagnosisHistoryQuery,
  useLazyPatientDiagnosisHistoryQuery,
  usePatientArchiveDiagnosisMutation,
  useListPatientRecallsQuery,
  useLazyListPatientRecallsQuery,
  useCreatePatientRecallMutation,
  useDeletePatientRecallMutation,
  useGetPatientRecallQuery,
  useLazyGetPatientRecallQuery,
  useUpdatePatientRecallMutation,
  usePatientCloseDoseSpotMutation,
  useGetHealthHistoryQuery,
  useLazyGetHealthHistoryQuery,
  useCreatetHealthHistoryEntryMutation,
  useUpdateHealthHistoryEntryMutation,
  useDeleteHealthHistoryEntryMutation,
  useTaskGeneratePdfMutation
} = patient;
