import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { getMonth, getWeekdays, getAllTimestamps } from "./utils";
import { APP_KEYS } from "@/globals/constants";
import { CalendarViewType } from "@/globals/constants/types";

// TEMP: until we update location interaction
export interface Appointment {
  start: string;
  end: string;
  duration: number;
  status: string;
  patientName: string;
  chiefComplaint: string;
}
export interface Day {
  timestamp: string;
  day: number;
  disabled: boolean;
  slots: number;
  key: string;
  appointments: Appointment[];
}

interface CalendarState {
  selectedDate: { view: string; timestamp: string };
  allDays: string[];
  detailDays: string[];
  currentYear: number;
  detailView: CalendarViewType;
  currentTimestamps: any[];
  currentTimestamp: any;
  providerFilter: (number | never)[]; // providers; user id
  locationFilter: (number | string | never)[]; // selected locations location id
  selectedSlot: string | null; // which timeslot is currently selected by user in week / day view
  showLeftCol: boolean;
  selectedProviderId?: number;
}
const currentDate = dayjs().toISOString();
const currentYear = dayjs().year();
const currentTimestamps = getAllTimestamps(currentYear);

const initialState: CalendarState = {
  selectedDate: { view: APP_KEYS.WEEK, timestamp: currentDate },
  allDays: getMonth(currentTimestamps, currentDate),
  detailDays: getWeekdays(currentDate),
  currentYear: currentYear,
  currentTimestamps: currentTimestamps,
  currentTimestamp: currentDate,
  detailView: APP_KEYS.WEEK,
  providerFilter: [],
  locationFilter: [],
  selectedSlot: null,
  showLeftCol: true
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    updateProviderFilter: (
      state,
      action: PayloadAction<CalendarState["providerFilter"]>
    ) => {
      const filter = action.payload;
      state.providerFilter = filter;
    },
    updateLocationFilter: (
      state,
      action: PayloadAction<CalendarState["locationFilter"]>
    ) => {
      const selectedLocation = action.payload;
      state.locationFilter = selectedLocation;
    },
    resetCalendar: state => (state = initialState),
    setDetailDays: (
      state,
      action: PayloadAction<CalendarState["selectedDate"]>
    ) => {
      const { timestamp, view } = action.payload;
      const selectedYear = dayjs(timestamp).year();
      let timestamps = state.currentTimestamps;
      if (selectedYear !== state.currentYear) {
        state.currentYear = selectedYear;
        timestamps = getAllTimestamps(selectedYear);
        state.currentTimestamps = timestamps;
      }

      let selectedDays;
      if (view === APP_KEYS.MONTH) {
        selectedDays = getMonth(timestamps, timestamp);
      } else if (view === APP_KEYS.WEEK) {
        selectedDays = getWeekdays(timestamp);
      } else if (view === APP_KEYS.MULTI) {
        selectedDays = [timestamp];
      } else {
        selectedDays = [timestamp];
      }
      state.selectedDate = action.payload;
      state.currentYear = selectedYear;
      state.detailView = view;
      state.detailDays = selectedDays;
      state.allDays = getMonth(timestamps, timestamp);
      state.currentTimestamp = timestamp;
    },
    setSelectedSlot: (state, action: PayloadAction<string | null>) => {
      state.selectedSlot = action.payload;
    },
    setSelectedProviderId: (state, action: PayloadAction<number>) => {
      state.selectedProviderId = action.payload;
    },
    setShowLeftCol: (state, action: PayloadAction<boolean>) => {
      state.showLeftCol = action.payload;
    }
  }
});

export const {
  setDetailDays,
  resetCalendar,
  updateProviderFilter,
  updateLocationFilter,
  setSelectedSlot,
  setShowLeftCol,
  setSelectedProviderId
} = calendarSlice.actions;

export default calendarSlice.reducer;
