/* FileContent Component */
/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Button from "@/components/button";
import EmptyState from "@/components/cards/emptyState";
import FileUploader from "@/components/fileExplorer/upload";
import FileViewer from "@/components/fileExplorer/fileViewer";
import Icon from "@/components/icons";

// constants
import { STYLES, STATUS_KEYS } from "@/globals/constants";

// store
import {
  FileListItem,
  FileId,
  useFileBatchUpdateMutation
} from "@/store/services/file";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "@/components/modal/modalSlice";

// utils
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// styles
import styles from "./styles.module.scss";

export type FileType = "eob" | "hcfa" | "lab result";
export interface FileContentProps {
  fileType: FileType;
  files?: FileListItem[];
  onFileUpload: (files: FileList, fileType: FileType) => void;
  selectedFile: FileListItem | null;
  onFileSelect: (file: FileListItem, fileType: FileType) => void;
  hasUploadPermission: boolean;
}

export default function FileContent({
  fileType,
  files,
  onFileUpload,
  selectedFile,
  onFileSelect,
  hasUploadPermission = false
}: FileContentProps) {
  const dispatch = useDispatch();
  const [batchUpdate] = useFileBatchUpdateMutation();

  const handleReleaseFilesToPatient = (
    fileId: FileId,
    releaseToPatient: boolean
  ) => {
    batchUpdate({
      fileBatchUpdateRequest: {
        file_ids: [fileId],
        viewable_by_patient: releaseToPatient
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Successfully ${releaseToPatient ? "shared" : "hid"} file`,
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        addAlertToToastTrough({
          message: `Failed to ${releaseToPatient ? "share" : "hide"} file`,
          type: STATUS_KEYS.ERROR
        });
      });
  };

  return (
    <div className="padded flexCol">
      {hasUploadPermission && (
        <FileUploader onFilesAdded={file => onFileUpload(file, fileType)} />
      )}
      {files && files.length > 0 ? (
        <div className={styles.fileList}>
          {files.map(file => (
            <div
              className={clsx(styles.file, {
                [styles.selected]: file.file_id === selectedFile?.file_id
              })}
              key={file.file_id}
              data-cy="file-item"
            >
              <div className="flex">
                <Icon svg="file_highlighted" width={30} height={30} />
                <div>
                  <p className={styles.fileName}>{file.name}</p>
                  <p className="t5 gray400">
                    uploaded at{" "}
                    {convertUtcIntToLocalDatetime(file.created).format(
                      "hh:mma on MM/DD/YYYY"
                    )}
                  </p>
                  <div className="flex t5 gray400">
                    <Icon
                      svg={
                        file.viewable_by_patient ? "check_circle_blue" : "hide"
                      }
                      width={12}
                    />
                    {file.viewable_by_patient
                      ? "Visible to Patient"
                      : "Hidden from Patient"}
                  </div>
                </div>
              </div>

              <div className="flex">
                <Button
                  style={STYLES.SECONDARY}
                  onClick={() => onFileSelect(file, fileType)}
                >
                  <Icon
                    svg={
                      file.file_id === selectedFile?.file_id ? "hide" : "eye"
                    }
                  />
                  {file.file_id === selectedFile?.file_id
                    ? "Hide File"
                    : "View File"}
                </Button>
                <a href={file.url} download>
                  <Button style={STYLES.SECONDARY}>
                    <Icon svg={"arrow_down"} />
                    Download File
                  </Button>
                </a>
                <Button
                  style={STYLES.SECONDARY}
                  onClick={() =>
                    handleReleaseFilesToPatient(
                      file.file_id,
                      !file.viewable_by_patient
                    )
                  }
                >
                  <Icon svg={file.viewable_by_patient ? "hide" : "share"} />
                  {file.viewable_by_patient
                    ? "Hide from Patient"
                    : "Share with Patient"}
                </Button>
              </div>
            </div>
          ))}
          {selectedFile && (
            <FileViewer
              isReadOnly
              file={selectedFile}
              onClose={() => onFileSelect(selectedFile, fileType)}
            />
          )}
        </div>
      ) : (
        !hasUploadPermission && <EmptyState />
      )}
    </div>
  );
}
