/* InteractiveForm Name */
/* External Imports */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  PDFDocument,
  PDFTextField,
  PDFCheckBox,
  PDFDropdown,
  PDFRadioGroup,
  PDFSignature
} from "pdf-lib";
import {
  PdfViewerComponent,
  Toolbar,
  Navigation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  FormFields,
  Inject,
  Annotation
} from "@syncfusion/ej2-react-pdfviewer";
/* Local Imports */

// components
import Button from "../button";
import SubmittedStamp from "../flows/standardFlow/submittedStamp";
import Icon from "../icons";

// constants
import { SYNCFUSION_LICENSE_KEY } from "@/globals/constants/environment";
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  useFileUploadMutation,
  useFileToggleReviewedMutation
} from "@/store/services/file";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import {
  useTaskCompleteMutation,
  useTaskMarkIncompleteMutation,
  TaskId,
  useTaskUpdateMutation
} from "@/store/services/task";
import { UserId } from "@/store/services/patient";
import { LocationId } from "@/store/services/location";
import { setRightPaneOpen } from "../drawer/drawerSlice";
import { useFileBatchCopyMutation } from "@/store/services/file";
import { useReportFrontendErrorMutation } from "@/store/services/system";

// styles
import styles from "./styles.module.scss";

registerLicense(SYNCFUSION_LICENSE_KEY);

/* InteractiveForm Typescript Interface */
interface ComponentProps {
  fileUrl: string;
  fileName: string;
  fileId: number;
  patientId: UserId;
  taskId: TaskId;
  locationId: LocationId;
  taskCompleted?: boolean;
  isInfo?: boolean;
  type: "FILE";
}

export default function InteractiveForm({
  fileUrl,
  fileName,
  patientId,
  taskId,
  locationId,
  taskCompleted,
  fileId,
  isInfo,
  type
}: ComponentProps) {
  const dispatch = useDispatch();
  /* Redux */
  const [uploadFile] = useFileUploadMutation();
  const [completeTask] = useTaskCompleteMutation();
  const [markTaskIncomplete] = useTaskMarkIncompleteMutation();
  const [updateTask] = useTaskUpdateMutation();
  const [copyFile] = useFileBatchCopyMutation();
  const [reportFrontendError] = useReportFrontendErrorMutation();
  const [toggleFileReviewed] = useFileToggleReviewedMutation();

  /* Local State */
  const [formData, setFormData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  /* Effects */

  /* Event Handlers */
  async function downloadClicked() {
    setIsSubmitting(true);
    const fields = await getFields();

    setFormData(fields);
  }

  async function fillPdfForm() {
    const existingPdfUrl = fileUrl;
    fetch(existingPdfUrl).then(response => {
      fillForm();
      async function fillForm() {
        const existingPdfBytes = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const form = pdfDoc.getForm();

        const fields = form.getFields();

        const parsedData = await JSON.parse(formData);
        // Define an async function for the loop

        const svgTest = async function drawSvgPaths(
          svgCommands: { command: string; x: number; y: number }[],
          signaturePosition: { x: number; y: number },
          fieldPageNum: number
        ) {
          let pathString = "";
          for (const point of svgCommands) {
            if (point.command === "M") {
              pathString += `M ${point.x} ${point.y} `;
            } else if (point.command === "L") {
              pathString += `L ${point.x} ${point.y} `;
            }
          }
          // SVG path for signature

          const svgPath = pathString;
          // Add a blank page to the document
          const page = pdfDoc.getPage(fieldPageNum);

          // Draw the SVG path as a black line
          // page.moveDown(25);
          page.drawSvgPath(svgPath, {
            scale: 0.1,
            x: signaturePosition.x,
            y: signaturePosition.y + 25
          });
        };

        let hasFormError = false;
        const processFields = async () => {
          for (const field of fields) {
            const name = field.getName();

            if (field instanceof PDFTextField) {
              const value = parsedData[name];
              if (value) {
                form.getTextField(name).setText(value.fieldValue);
              }
            }
            if (field instanceof PDFCheckBox) {
              // the checkbox field name needs to be the same as the value in the parsedData
              // we need to remove any spaces or underscores from the name
              const normalizedName = name?.replace(/ /g, "")?.replace(/_/g, "");
              if (!normalizedName) {
                hasFormError = true;
                return;
              }
              const value = parsedData[normalizedName];
              if (!value) {
                hasFormError = true;
                return;
              }
              if (value.isSelected) {
                form.getCheckBox(name).check();
              } else {
                form.getCheckBox(name).uncheck();
              }
            }

            if (field instanceof PDFDropdown) {
              const value = parsedData[name];
              if (value) {
                form.getDropdown(name).select(value.fieldValue);
              }
            }
            if (field instanceof PDFRadioGroup) {
              const value = parsedData[name];
              if (value) {
                form.getRadioGroup(name).select(value.fieldValue);
              }
            }
            if (field instanceof PDFSignature) {
              // Set PNG image as signature
              const signature = field;
              const widgets = signature.acroField.getWidgets();
              let signaturePosition;
              widgets.forEach(w => {
                signaturePosition = w.getRectangle();
              });
              if (!parsedData[name]) {
                return;
              }
              const value = parsedData[name];

              const jVal = await JSON.parse(value);
              const pages = pdfDoc.getPages();

              const fieldPage = pdfDoc.findPageForAnnotationRef(field.ref);

              const fieldPageNum = pages.findIndex(
                page => page.ref.tag === fieldPage?.ref.tag
              );

              // @ts-ignore
              await svgTest(jVal, signaturePosition, fieldPageNum);
            }
          }
        };

        await processFields();
        if (hasFormError) {
          setIsSubmitting(false);
          setIsLoading(false);
          setIsError(true);
          dispatch(
            addAlertToToastTrough({
              message:
                "There is an issue with this form, please contact support through the orange icon on the bottom corner of your screen to let them know",
              type: STATUS_KEYS.ERROR
            })
          );
          reportFrontendError({
            reportSystemError: {
              message:
                "Form error: " +
                "fileId: " +
                fileId +
                ", fileName: " +
                fileName +
                ", taskId: " +
                taskId,
              stack_trace: "Form error"
            }
          });
          return;
        }
        // Flatten the form fields to finalize changes
        // NOTE: form cannot be edited again after this step
        form.flatten();

        // Save the modified PDF as a blob
        const modifiedPdfBytes = await pdfDoc.save();
        const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
          type: "application/pdf"
        });
        const modifiedPdfFile = new File([modifiedPdfBlob], fileName, {
          type: "application/pdf"
        });
        const files = [modifiedPdfFile];
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
          const f = files[i];
          data.append(f.name, f, f.name);
        }

        uploadFile({
          body: data,
          patientId,
          locationId
        })
          .unwrap()
          .then(createdFiles => {
            // We only uploaded one file so this is a safe assumption
            const fileId = Object.values(createdFiles)[0];
            dispatch(
              addAlertToToastTrough({
                message: "File saved",
                status: STATUS_KEYS.SUCCESS
              })
            );
            updateTask({
              taskId,
              taskUpdatePayload: { file: { submitted_file_id: fileId } }
            })
              .unwrap()
              .then(() => {
                handleCompleteTask();
              })
              .catch(() =>
                dispatch(
                  addAlertToToastTrough({
                    message: "Submitted file could not be associated with task",
                    status: STATUS_KEYS.ERROR
                  })
                )
              );
          })
          .catch(error => {
            dispatch(
              addAlertToToastTrough({
                message: "File could not be saved",
                status: STATUS_KEYS.ERROR
              })
            );
          });
      }
    });
  }

  const handleAcknowledgeFile = () => {
    if (type === "FILE") {
      copyFile({
        fileBatchCopyRequest: { patient_id: patientId, file_ids: [fileId] }
      })
        .unwrap()
        .then(() => {
          handleCompleteTask();
        })
        .catch(() => {
          dispatch(
            addAlertToToastTrough({
              message: "File could not be copied to patient files",
              status: STATUS_KEYS.ERROR
            })
          );
        });
    } else if (type === "DOCUMENT_REVIEW") {
      // first, update the file to be marked as reviewed
      toggleFileReviewed({ fileId })
        .unwrap()
        .then(() => {
          handleCompleteTask();
        })
        .catch(() => {
          dispatch(
            addAlertToToastTrough({
              message: "File could not be marked as reviewed",
              status: STATUS_KEYS.ERROR
            })
          );
        });
    }
  };

  const handleCompleteTask = () => {
    if (taskCompleted) {
      markTaskIncomplete({ taskId })
        .unwrap()
        .then(() => {
          setIsSubmitting(false);
          dispatch(
            addAlertToToastTrough({
              message: "Task marked as incomplete",
              status: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setRightPaneOpen(false));
        })
        .catch(() => {
          setIsSubmitting(false);
          dispatch(
            addAlertToToastTrough({
              message: "Task could not be marked as incomplete",
              status: STATUS_KEYS.ERROR
            })
          );
        });
    } else {
      completeTask({ taskId })
        .unwrap()
        .then(() => {
          setIsSubmitting(false);
          if (type === "FILE") {
            dispatch(
              addAlertToToastTrough({
                message: "Task completed",
                status: STATUS_KEYS.SUCCESS
              })
            );
          }
          dispatch(setRightPaneOpen(false));
        })
        .catch(() => {
          setIsSubmitting(false),
            dispatch(
              addAlertToToastTrough({
                message: "Task could not be completed",
                status: STATUS_KEYS.ERROR
              })
            );
        });
    }
  };

  useEffect(() => {
    if (formData) fillPdfForm();
  }, [formData]);

  // check has form error

  async function getFields() {
    // @ts-ignore
    const container = document?.getElementById("container");

    // @ts-ignore
    const viewer = container?.ej2_instances[0] as PdfViewerComponent;
    const fields = await viewer.formFieldsModule.downloadFormFieldsData();
    return fields;
  }

  return (
    <div className={styles.InteractiveForm}>
      {/* Render the PDF Viewer */}
      <PdfViewerComponent
        id="container"
        documentPath={fileUrl}
        resourceUrl="https://cdn.syncfusion.com/ej2/24.1.41/dist/ej2-pdfviewer-lib"
        style={{ height: "calc(100vh - 140px)", width: "960px" }}
        zoomValue={100}
      >
        <Inject
          services={[
            Toolbar,
            Navigation,
            Annotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            FormFields
          ]}
        />
      </PdfViewerComponent>
      {isInfo && !taskCompleted && type === "FILE" && (
        <Button onClick={handleAcknowledgeFile} style={STYLES.FULL_WIDTH}>
          Mark as Read
        </Button>
      )}
      {isError && (
        <div className="padded card error">
          There is an issue with this form, please contact support through the
          orange icon on the bottom corner of your screen to let them know
        </div>
      )}
      {!isInfo && !taskCompleted && (
        <Button
          onClick={downloadClicked}
          style={STYLES.FULL_WIDTH}
          loading={isSubmitting || isLoading}
          nativeButtonProps={{ disabled: isSubmitting || isLoading || isError }}
        >
          {isError ? "Unable to save file" : "Save File"}
        </Button>
      )}
    </div>
  );
}
