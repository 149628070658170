import * as generated from "./generated/practice";

const practice = generated.practice.enhanceEndpoints({
  endpoints: {
    practiceSearchUsers: {
      providesTags: ["Patients"]
    },
    patientGetList: {
      providesTags: ["Patients"]
    },
    practiceGetLabOrders: {
      providesTags: ["LabOrder"]
    },
    practiceListAppointmentTypes: {
      providesTags: ["Scheduling"]
    },
    practiceCreateAppointmentType: {
      invalidatesTags: ["Scheduling"]
    },
    practiceUpdateAppointmentType: {
      invalidatesTags: ["Scheduling"]
    },
    practiceDeleteAppointmentType: {
      invalidatesTags: ["Scheduling"]
    },
    listTaskBundles: {
      providesTags: ["TaskBundles"]
    },
    createTaskBundle: {
      invalidatesTags: ["TaskBundles", "Scheduling"]
    },
    updateTaskBundle: {
      invalidatesTags: ["TaskBundles", "Scheduling"]
    },
    deleteTaskBundle: {
      invalidatesTags: ["TaskBundles", "Scheduling"]
    },
    practiceUpdate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Practice"];
      }
    },
    practiceInfo: {
      providesTags: ["Practice"]
    }
  }
});

export * from "./generated/practice";
export default practice;
export const {
  usePatientGetListQuery,
  useLazyPatientGetListQuery,
  usePracticeCreateMutation,
  usePracticeListQuery,
  useLazyPracticeListQuery,
  usePracticeUpdateMutation,
  usePracticeInfoQuery,
  useLazyPracticeInfoQuery,
  usePracticeAppointmentsExportMutation,
  usePracticeAddUserMutation,
  usePracticeInviteMutation,
  usePracticeGetFormsQuery,
  useLazyPracticeGetFormsQuery,
  usePracticeGetTemplatesQuery,
  useLazyPracticeGetTemplatesQuery,
  usePracticeGetNotificationsQuery,
  useLazyPracticeGetNotificationsQuery,
  usePracticeGetProvidersQuery,
  useLazyPracticeGetProvidersQuery,
  usePracticeListAppointmentTypesQuery,
  useLazyPracticeListAppointmentTypesQuery,
  usePracticeSearchUsersQuery,
  useLazyPracticeSearchUsersQuery,
  usePracticeListRolesQuery,
  useLazyPracticeListRolesQuery,
  usePracticeCreateRoleMutation,
  usePracticeUpdateRoleMutation,
  usePracticeEditRolesQuery,
  useLazyPracticeEditRolesQuery,
  usePracticeAssignUserRoleMutation,
  usePracticeCreateContactMutation,
  usePracticeUpdateContactMutation,
  usePracticeDeleteContactsMutation,
  usePracticeListContactsQuery,
  useLazyPracticeListContactsQuery,
  usePracticeGetLabOrdersQuery,
  useLazyPracticeGetLabOrdersQuery,
  useTagCreateMutation
} = practice;
