// external
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";

// components
import Button from "@/components/button";
import DashboardCard from "@/components/cards/dashboardCard";
import Icon from "@/components/icons";
import Input from "@/components/input";
import Head from "@/components/datagrid/head";
import ContentRenderer from "@/components/textArea/contentRenderer";
import WeightInput from "@/components/input/weightInput";

// utils
import { HandleFormat } from "@/components/datagrid/helpers";
import { getGestationalAgeObjectAtDate } from "@/globals/helpers";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store
import { usePatientGetInfoQuery } from "@/store/services/patient";
import { useEncounterInfoQuery } from "@/store/services/encounter";

// constants
import {
  METRICS,
  METRIC_LABELS,
  INPUT_TYPES,
  STYLES
} from "@/globals/constants";
import { GridDataType } from "../datagrid";
import { metrics } from "@/components/datagrid";

// styles
import styles from "./styles.module.scss";

export interface FetalGrowthFlowSheetProps {
  encounterId?: number;
  patientId?: number;
  isReadOnly?: boolean;
  data: GridDataType;
  // On Submit for the editable row (if there is one)
  onRowSubmit?: (data: any) => void;
  // Should the form submit when any input is blurred
  submitOnBlur?: boolean;
  // On click event for non-editable rows
  onRowNavigate?: (rowId: number) => void;
  // The navigate button a11y title
  navigateButtonTitle?: string;
  // If the data is loading
  isLoading?: boolean;
  // Headers of the table that will be used to index into the data
  headers: Array<string>;
  // Title of the flow sheet that will display on the card
  title: string;
  // Defaults values to the input fields
  defaultValues?: metrics;
}
export default function FlowSheet({
  encounterId,
  data,
  isReadOnly,
  onRowSubmit,
  submitOnBlur,
  onRowNavigate,
  navigateButtonTitle = "Row navigate",
  isLoading,
  headers,
  title,
  defaultValues
}: FetalGrowthFlowSheetProps) {
  const router = useRouter();

  const { register, handleSubmit, setValue, reset, control, watch } = useForm();

  const { data: encounter } = useEncounterInfoQuery(
    {
      encounterId: encounterId as number
    },
    { skip: !encounterId }
  );

  const { data: patientInfo } = usePatientGetInfoQuery(
    {
      patientId: encounter?.patient.user_id as number
    },
    {
      skip: !encounter?.patient.user_id
    }
  );

  // local state
  const [openRowIds, setOpenRowIds] = useState<number[]>([]);

  // Effects
  // When the encounter changes so, in essensce the first time the data is loaded
  // we need to set the data in the form
  useEffect(() => {
    const defaultValues = getValuesFromData(data);

    reset(defaultValues);
  }, [encounterId, encounter]);

  useEffect(() => {
    const defaultValues = getValuesFromData(data);

    reset(defaultValues, { keepValues: true });
  }, [data]);

  const getValuesFromData = (newData: GridDataType) => {
    // Find the current entry in the data based on encounter ID
    const currentEntry = (newData || []).find(
      entry => entry.metrics.encounter_id === encounterId
    );

    // Create the default values object from the current entry's metrics
    const defaultValues = {
      ...currentEntry?.metrics
    };

    // Format the date if it exists
    if (defaultValues.date) {
      defaultValues.date =
        dayjs(`${defaultValues.date}`, "YYYYMMDD").format("YYYY-MM-DD") || "";
    }

    // Calculate gestational age if we have the necessary data
    if (
      patientInfo?.pregnancy?.edd?.estimated_due_date &&
      (encounter?.start || encounter?.appointment?.starts || encounter?.created)
    ) {
      const gestAgeObj = getGestationalAgeObjectAtDate(
        parseInt(
          dayjs(patientInfo.pregnancy.edd.estimated_due_date).format("YYYYMMDD")
        ),
        encounter?.start || encounter?.appointment?.starts || encounter?.created
      );
      defaultValues.weeks = `${gestAgeObj?.weeks}` || "";
      defaultValues.days = `${gestAgeObj?.days}` || "";
    }

    // Set birth_weight only if we have weight data
    if (currentEntry?.metrics?.weight_grams) {
      defaultValues.birth_weight = {
        grams: currentEntry.metrics.weight_grams
      };
      defaultValues.weight_grams = currentEntry.metrics.weight_grams;
    }

    return defaultValues;
  };

  // Event handlers
  const submit = (formData: { [key: string]: any }) => {
    // Dates in flowsheets come from the encounter they're attached to
    let adjustedData = formData;

    adjustedData.date = parseInt(
      dayjs(`${encounter?.start}`, "YYYYMMDDHHmmss").format("YYYYMMDD")
    );
    if (adjustedData.weeks) {
      formData.weeks = (formData.weeks as string) || "";
    }
    if (adjustedData.days) {
      adjustedData.days = (adjustedData.days as string) || "";
    }
    if (adjustedData.birth_weight) {
      adjustedData.weight_grams = adjustedData.birth_weight.grams;
    }

    onRowSubmit && onRowSubmit(adjustedData);
  };

  // Toggles extra row drawer visibility
  const handleToggleRowOpen = (id: number) => {
    let newOpenRowIds;
    // if row is already open, close
    if (openRowIds.includes(id)) {
      newOpenRowIds = openRowIds.filter(openRowId => openRowId !== id);
    } else {
      // otherwise open it
      newOpenRowIds = [...openRowIds, id];
    }
    setOpenRowIds(newOpenRowIds);
  };

  const renderInputField = (metric: string) => {
    if (INPUT_TYPES[metric] === "date") {
      return (
        <p style={{ paddingLeft: 4 }}>
          {encounter &&
            convertUtcIntToLocalDatetime(
              encounter.start ||
              encounter.appointment?.starts ||
              encounter.created
            ).format("MM/DD/YYYY")}
        </p>
      );
    }
    // do not allow editing of weeks and days, these are calculated from gestational age and should be read-only
    // otherwise the user could enter an incorrect gestational age and the GA displayed here would not match the GA in the system
    if (metric === METRICS.WEEKS || metric === METRICS.DAYS) {
      return (
        // days and weeks metrics are strings
        <p data-cy={`prenatal-flowsheet-${metric}`} style={{ paddingLeft: 4 }}>
          {defaultValues?.[metric] as string}
        </p>
      );
    }
    if (metric === METRICS.PERCENT_CHANGE_WEIGHT_SINCE_BIRTH) {
      return "-";
    }
    switch (metric) {
      case "birth_weight":
        return (
          <Controller
            name="birth_weight"
            control={control}
            render={({ field: { onChange, value } }) => (
              <WeightInput
                value={value}
                onChange={onChange}
                submit={() => handleSubmit(submit)()}
                hiddenLabel={true}
                showGramsConversion={false}
              />
            )}
          />
        );
      default:
        return (
          <Input
            value={defaultValues?.[metric] as string | number}
            label={METRIC_LABELS[metric] || metric}
            name={`${metric}`}
            id={`${metric}`}
            required={metric === METRICS.DATE}
            type={INPUT_TYPES[metric] || "text"}
            placeholder=" "
            hiddenLabel
            register={register}
            rules={{
              onBlur: () => {
                if (submitOnBlur) {
                  handleSubmit(submit)();
                }
              }
            }}
          />
        );
    }
  };

  const spring = {
    type: "spring",
    damping: 25,
    stiffness: 120
  };

  return (
    <div className={styles.FlowSheet}>
      <DashboardCard
        title={title}
        isReadOnly={isReadOnly}
        isEmpty={isReadOnly && (!data || data.length === 0)}
        isLoading={isLoading}
      >
        <form onSubmit={handleSubmit(submit)}>
          <table className={styles.Datagrid}>
            <Head headers={headers} hasEmptyEnd />
            <tbody>
              {/* show input row if no existing row has been selected for editing */}
              {!isReadOnly && encounterId && (
                <tr>
                  {headers.map(metric => {
                    if (metric == "note") {
                      return (
                        <td
                          key={`${metric}-input`}
                          className={styles.inputCell}
                          data-cy={`${metric}-input`}
                        >
                          <button
                            className={styles.noteArrow}
                            type="button"
                            onClick={() => {
                              document.getElementById("note")?.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                              });
                            }}
                          >
                            <Icon svg="chevron_down_blue" width={15} />
                          </button>
                        </td>
                      );
                    }
                    return (
                      <td
                        key={`${metric}-input`}
                        className={clsx(styles.inputCell)}
                        data-cy={`${metric}-input`}
                      >
                        {renderInputField(metric)}
                      </td>
                    );
                  })}
                  {/** This is here to line up with the headers */}
                  <td className={styles.inputCell}>&nbsp;</td>
                </tr>
              )}

              {/* populate table with existing flow sheet */}
              {(!isReadOnly && encounterId
                ? // if in edit mode, current encounter is being edited, so exclude it from the read list
                data?.filter(
                  entry => entry.metrics.encounter_id !== encounterId
                )
                : data
              )?.map(({ metrics, id }) => (
                <Fragment key={metrics.encounter_id as number}>
                  <AnimatePresence>
                    <motion.tr
                      layout
                      transition={spring}
                      key={`data-${metrics.encounter_id}`}
                      data-cy="historical-entries"
                    >
                      {/* display this row as read-only */}
                      {headers.map(metric => (
                        <td
                          key={`${metric}-${metrics.encounter_id}`}
                          className={`${metric}-${metrics.encounter_id}`}
                          data-cy={`${metric}`}
                        >
                          {/* handle formatting exceptions */}
                          <p style={{ padding: 4 }}>
                            {HandleFormat(
                              metrics,
                              metric,
                              metrics.encounter_id as number,
                              handleToggleRowOpen,
                              openRowIds,
                              router
                            )}
                          </p>
                        </td>
                      ))}
                      <td>
                        {
                          // if this row corresponds to the current encounter, show edit button
                          metrics.encounter_id &&
                          metrics.encounter_id !== encounterId && (
                            <Button
                              style={STYLES.ICON}
                              onClick={_ =>
                                onRowNavigate &&
                                onRowNavigate(metrics.encounter_id as number)
                              }
                              nativeButtonProps={{
                                title: navigateButtonTitle
                              }}
                            >
                              <Icon svg={"arrow_right_grey"} />
                            </Button>
                          )
                        }
                      </td>
                    </motion.tr>
                  </AnimatePresence>
                  {openRowIds.includes(metrics.encounter_id as number) && (
                    <tr>
                      <td colSpan={headers.length} className={styles.drawer}>
                        <ContentRenderer content={metrics.note as string} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </form>
      </DashboardCard>
    </div>
  );
}
