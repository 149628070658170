/* Icon */
/* External Imports */
import clsx from "clsx";
import Image from "next/image";

/* Local Imports */

// Icons

// constants

// store

// styles
import styles from "./styles.module.scss";

/* Icon Typescript Interface */
export interface IconProps {
  svg: string;
  width?: number;
  height?: number;
  flipped?: boolean;
  title?: string;
}

export default function Icon({
  svg,
  width = 15,
  height = 15,
  flipped = false,
  title
}: IconProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <Image
      className={clsx(styles.Icon, { [styles.flip]: flipped })}
      src={`/svgs/${svg}.svg`}
      alt={`${svg} icon`}
      width={width}
      height={height}
      title={title}
    />
  );
}
