import * as generated from "./generated/pregnancy";

const pregnancy = generated.pregnancy.enhanceEndpoints({
  endpoints: {
    pregnancyUpsert: {
      invalidatesTags: ["Patients"]
    },
    pregnancyInfo: {
      providesTags: ["EDD", "Patients"]
    },
    listEddHistory: {
      providesTags: ["EDD"]
    },
    createEdd: {
      invalidatesTags: ["EDD", "Patients"]
    },
    updateEdd: {
      invalidatesTags: ["EDD", "Patients"]
    },
    deleteEdd: {
      invalidatesTags: ["EDD", "Patients"]
    }
  }
});

export * from "./generated/pregnancy";
export default pregnancy;
export const {
  usePregnancyUpsertMutation,
  useListPastPregnanciesQuery,
  useLazyListPastPregnanciesQuery,
  usePregnancyInfoQuery,
  useLazyPregnancyInfoQuery,
  usePregnancyLaborInfoQuery,
  useLazyPregnancyLaborInfoQuery,
  usePregnancyLaborSheetQuery,
  useLazyPregnancyLaborSheetQuery,
  usePregnancyLaborUpsertMutation,
  usePregnancyLaborEventUpsertMutation,
  usePregnancyLaborEventDeleteMutation,
  usePregnancyLaborRecurringEventUpdateMutation,
  usePregnancyLaborRecurringEventCreateMutation,
  usePregnancyLaborRecurringEventDeleteMutation,
  useListEddHistoryQuery,
  useLazyListEddHistoryQuery,
  useCreateEddMutation,
  useDeleteEddMutation,
  useUpdateEddMutation
} = pregnancy;
