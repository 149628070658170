/* Demographics Name */
/* External Imports */
import clsx from "clsx";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

/* Local Imports */

// components
import InsuranceInformation from "@/components/forms/insurance";
import BasicAccordion from "@/components/accordions/basic";
import Button from "@/components/button";
import PatientGeneralInfoForm from "@/components/forms/patientGeneralInformation";
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { PatientInfo, UserId } from "@/store/services/patient";
import { UserInfo } from "@/store/services/encounter";
import { ProviderInfo } from "@/store/services/provider";
import { usePatientGetInfoQuery } from "@/store/services/patient";
import { useLazyPatientInfoExportQuery } from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { RootState } from "@/store/store";

// utils
import { openPdfFromBase64 } from "@/components/labOrdering/utils";

// styles
import styles from "./styles.module.scss";
import EmergencyContactListing from "./forms/emergencyContactsForm";
import DashboardCard from "@/components/cards/dashboardCard";

/* Demographics Typescript Interface */
interface DemographicsProps {
  user?: PatientInfo | ProviderInfo | UserInfo;
  isTaskView?: boolean;
  patientId?: UserId;
}

export default function Demographics({
  user,
  isTaskView = false,
  patientId
}: DemographicsProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const [exportPatientInfo] = useLazyPatientInfoExportQuery();

  /* Local State */
  const [isEditingInfo, setIsEditingInfo] = useState<boolean>(isTaskView);
  const [isEditingFirst, setIsEditingFirst] = useState<boolean>(isTaskView);
  const [isEditingSecond, setIsEditingSecond] = useState<boolean>(isTaskView);
  const [isEditingThird, setIsEditingThird] = useState<boolean>(isTaskView);
  const { data: localPatient } = usePatientGetInfoQuery(
    { patientId: patientId as number },
    { skip: !patientId }
  );

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  /* Event Handlers */

  // for the task version of these forms,
  // I want to refetch the patient each time I update
  // this is the make the RTKQuery tags play well and instantly update the data inside the drawer
  const localUser = useMemo(() => {
    if (isTaskView) {
      return localPatient;
    } else {
      return user;
    }
  }, [user, localPatient, isTaskView]);

  const handleExportList = () => {
    exportPatientInfo({
      patientId: localUser?.user_id as number
    })
      .unwrap()
      .then(res => {
        openPdfFromBase64(res.content);
      })
      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Failed to export",
            type: STATUS_KEYS.ERROR
          })
        )
      );
  };

  return (
    <div className={clsx(styles.Demographics, { [styles.oneCol]: isTaskView })}>
      <div className={styles.exportButton}>
        {!sessionInfo?.is_patient && (
          <Button style={STYLES.TERTIARY} onClick={handleExportList}>
            <Icon svg="printer" />
            Export Demographics & Insurance Information
          </Button>
        )}
      </div>
      <div className={styles.colWrap}>
        <DashboardCard
          title="General Information"
          customRightElement={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingInfo(!isEditingInfo);
                  }}
                >
                  {isEditingInfo ? "Update" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <PatientGeneralInfoForm
            patient={localUser as PatientInfo}
            isReadOnly={!isEditingInfo}
            onUpdate={() => setIsEditingInfo(false)}
          />
        </DashboardCard>
        <DashboardCard
          title="Primary Insurance"
          customRightElement={
            <>
              {!isMobile && (
                <Button
                  nativeButtonProps={{ id: "edit-primary-insurance" }}
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingFirst(!isEditingFirst);
                  }}
                >
                  {isEditingFirst ? "Update" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <InsuranceInformation
            type={"PRIMARY"}
            patientId={localUser?.user_id as number}
            isReadOnly={!isEditingFirst}
            onUpdate={() => setIsEditingFirst(false)}
          />
        </DashboardCard>
      </div>
      {localUser && (
        <DashboardCard title="Emergency Contacts">
          <EmergencyContactListing patientInfo={localUser as PatientInfo} />
        </DashboardCard>
      )}
      <div className={styles.colWrap}>
        {" "}
        <DashboardCard
          title="Secondary Insurance"
          customRightElement={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingSecond(!isEditingSecond);
                  }}
                >
                  {isEditingSecond ? "Cancel" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <InsuranceInformation
            type={"SECONDARY"}
            patientId={localUser?.user_id as number}
            isReadOnly={!isEditingSecond}
            onUpdate={() => setIsEditingSecond(false)}
          />
        </DashboardCard>
        <DashboardCard
          title="Tertiary Insurance"
          customRightElement={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingThird(!isEditingThird);
                  }}
                >
                  {isEditingThird ? "Cancel" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <InsuranceInformation
            type={"TERTIARY"}
            patientId={localUser?.user_id as number}
            isReadOnly={!isEditingThird}
            onUpdate={() => setIsEditingThird(false)}
          />
        </DashboardCard>
      </div>
    </div>
  );
}
