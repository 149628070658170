/* DocumentReview */
/* External Imports */
import clsx from "clsx";
import { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

/* Local Imports */

// components
import Icon from "@/components/icons";
import Button from "@/components/button";
import ViewBody from "@/components/viewBody";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// constants
import { STATUS_KEYS } from "@/globals/constants";

// store
import { RootState } from "@/store/store";
import { UserId, usePatientGetInfoQuery } from "@/store/services/patient";
import { TaskId } from "@/store/services/task";
import {
  PatientListItem,
  useLazyPatientGetListQuery
} from "@/store/services/practice";
import { useStaffUserListQuery } from "@/store/services/staff";
import {
  useFileDetailsQuery,
  useFileUpdateMutation
} from "@/store/services/file";
import {
  useTaskInfoQuery,
  useTaskUpdateMutation,
  useTaskCompleteMutation,
  useTaskMarkIncompleteMutation
} from "@/store/services/task";

// helpers
import { nameAndDOB, formatPhone } from "@/globals/helpers/formatters";

// styles
import styles from "../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import AssignGenericStaffTask from "../triggers/assignGenericStaffTask";

/* DocumentReviewProps Typescript Interface */
interface DocumentReviewProps {
  url: string;
  fileName: string;
  fileId: number;
  patientId: UserId;
  taskId: TaskId;
}

export default function DocumentReview({
  url,
  fileName,
  fileId,
  patientId,
  taskId
}: DocumentReviewProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { sessionInfo: cookie } = useSelector((state: RootState) => state.auth);

  /* Local State */
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [isTogglingReviewed, setIsTogglingReviewed] = useState(false);
  const [isTogglingReleased, setIsTogglingReleased] = useState(false);
  const [selectedPatient, setPatient] = useState<(typeof patients)[number]>();
  const [patientQuery, setPatientQuery] = useState<string>("");

  const { data: taskInfo } = useTaskInfoQuery({ taskId }, { skip: !cookie });
  const { data: patient, isLoading: isLoadingSelectedPatient } =
    usePatientGetInfoQuery({ patientId }, { skip: !cookie });
  const {
    data: fileInfo,
    refetch: reloadFile,
    isLoading: loadingFile
  } = useFileDetailsQuery({ fileId }, { skip: !cookie });

  // Get list of patients
  const [listPatients, { data: patientListing, isLoading: isLoadingPatients }] =
    useLazyPatientGetListQuery();
  const patients = useMemo(
    () =>
      (patientListing?.patients || []).map(p => {
        return {
          value: p,
          label: nameAndDOB(p)
        };
      }),
    [patientListing]
  );

  // Get list of staff
  const { data: staffList, isLoading: isLoadingStaff } = useStaffUserListQuery({
    includeInactive: false,
    includeSuperadmins: false
  });
  const staff = useMemo(
    () =>
      (staffList || []).map(s => {
        return {
          value: s,
          label: `${s.first_name} ${s.last_name} (${s.type})`
        };
      }),
    [staffList]
  );

  const [updateTask] = useTaskUpdateMutation();
  const [updateFile] = useFileUpdateMutation();
  const [completeTask] = useTaskCompleteMutation();
  const [markIncompleteTask] = useTaskMarkIncompleteMutation();

  /* Effects */
  useEffect(() => {
    listPatients({ query: patientQuery });
  }, [patientQuery]);
  useEffect(() => {
    if (patient) {
      setPatient({
        value: {
          user_id: patientId,
          first_name: patient?.first_name,
          last_name: patient?.last_name,
          dob: patient?.dob
        } as PatientListItem,
        label: nameAndDOB(patient)
      });
    }
  }, [patient]);

  /* Event Handlers */
  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf(".");
    return lastDotIndex > -1 ? filename.substring(lastDotIndex + 1) : "";
  };

  const fileExtension = getFileExtension(fileName).toLowerCase();
  const renderFileContent = () => {
    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <img src={url} alt="Displayed File" width={720} height={500} />;
      case "pdf":
        return <embed src={url} width="100%" height="100%" />;
      default:
        return <p>Unsupported file type: {fileExtension}</p>;
    }
  };

  const selectStaffAssignee = async (staff_assignee: UserId) => {
    try {
      await updateTask({
        taskId,
        taskUpdatePayload: {
          staff_assignee
        }
      });
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to reassign",
          type: STATUS_KEYS.ERROR
        })
      );
    }

    dispatch(
      addAlertToToastTrough({
        message: "Reassigned",
        type: STATUS_KEYS.SUCCESS
      })
    );
  };

  const selectPatient = (newPatientId: UserId) => {
    // TODO: Move this to a single transaction on the server, so that it can be
    // safely rolled back if one of the updates fails.

    // Change the patient associated with the task
    updateTask({
      taskId,
      taskUpdatePayload: {
        associated_patient: newPatientId
      }
    })
      .then(() =>
        // Move the file to the new patient
        updateFile({
          fileId,
          fileUpdateRequest: {
            name: fileName,
            patient_id: newPatientId,
            tags: Object.keys(fileInfo?.tags || []).map(tag => parseInt(tag)) // I hate this
          }
        })
      )
      .then(() =>
        // Show a success message
        dispatch(
          addAlertToToastTrough({
            message: "Patient updated",
            type: STATUS_KEYS.SUCCESS
          })
        )
      )
      .catch(err => {
        // Show an error message
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to change patient",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const toggleReleasedToPatient = async () => {
    setIsTogglingReleased(true);

    try {
      await updateFile({
        fileId,
        fileUpdateRequest: {
          name: fileName,
          patient_id: selectedPatient?.value.user_id,
          tags: Object.keys(fileInfo?.tags || []).map(tag => parseInt(tag)), // I hate this
          viewable_by_patient: !!!fileInfo?.viewable_by_patient
        }
      }).unwrap();

      await reloadFile();

      dispatch(
        addAlertToToastTrough({
          message: fileInfo?.viewable_by_patient
            ? "File released to patient"
            : "File revoked from patient",
          type: STATUS_KEYS.SUCCESS
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to release file patient",
          type: STATUS_KEYS.ERROR
        })
      );
    }

    setIsTogglingReleased(false);
  };

  const toggleReviewed = async () => {
    setIsTogglingReviewed(true);

    if (fileInfo?.reviewed_by) {
      try {
        await markIncompleteTask({ taskId }).unwrap();

        await reloadFile();

        dispatch(
          addAlertToToastTrough({
            message: "File marked as not reviewed",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (err) {
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to mark file as not reviewed",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    } else {
      try {
        await completeTask({ taskId }).unwrap();

        await reloadFile();

        dispatch(
          addAlertToToastTrough({
            message: "File marked as reviewed",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (err) {
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to mark file as reviewed",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }

    setIsTogglingReviewed(false);
  };

  const handleTitleChange = async () => {
    if (!titleRef.current) return;

    const newName = titleRef.current.textContent?.trim();
    if (!newName || newName + "." + getFileExtension(fileName) === fileName)
      return;

    try {
      await updateFile({
        fileId,
        fileUpdateRequest: {
          name: newName + "." + getFileExtension(fileName),
          patient_id: selectedPatient?.value.user_id,
          tags: Object.keys(fileInfo?.tags || []).map(tag => parseInt(tag)),
          viewable_by_patient: fileInfo?.viewable_by_patient
        }
      }).unwrap();

      dispatch(
        addAlertToToastTrough({
          message: "Filename updated successfully",
          type: STATUS_KEYS.SUCCESS
        })
      );
    } catch (err) {
      console.error(err);
      // Reset to original filename
      if (titleRef.current) {
        titleRef.current.textContent = fileName.replaceAll(
          "." + getFileExtension(fileName),
          ""
        );
      }
      dispatch(
        addAlertToToastTrough({
          message: "Failed to update filename",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  };

  return (
    <div className={styles.DocumentReview}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1
            className={styles.editableTitle}
            contentEditable="true"
            spellCheck="true"
            role="textbox"
            aria-label="Document title"
            translate="no"
            ref={titleRef}
            onBlur={handleTitleChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
          >
            {fileName.replaceAll("." + getFileExtension(fileName), "")}
          </h1>
          <div className={styles.editIcon}>
            <Icon svg="edit" />
          </div>
        </div>

        <div className={styles.actions}>
          <div className={styles.field}>
            <label>Assignee</label>
            <Select
              theme={SelectDefaultTheme}
              styles={SelectDefaultStyles}
              id="assignee"
              placeholder="Select a staff member"
              options={staff}
              onChange={v => {
                if (v) {
                  selectStaffAssignee(
                    (v as (typeof patients)[number]).value.user_id
                  );
                }
              }}
              value={staff.find(
                s => s.value.user_id === taskInfo?.assignee.user_id
              )}
              isLoading={isLoadingStaff}
            />
          </div>

          <div className={styles.field}>
            <label>Patient</label>
            <div className={styles.patientSelect}>
              <Select
                theme={SelectDefaultTheme}
                styles={SelectDefaultStyles}
                id="patient"
                placeholder="Select a patient"
                options={patients}
                onInputChange={v => setPatientQuery(v)}
                onChange={v => {
                  if (v) {
                    setPatient(v as (typeof patients)[number]);
                    selectPatient(
                      (v as (typeof patients)[number]).value.user_id
                    );
                  }
                }}
                value={selectedPatient}
                isLoading={isLoadingPatients}
              />
              {patient?.phone && (
                <p className={styles.phone}>
                  Patient Phone #: {formatPhone(patient.phone)}
                </p>
              )}
            </div>
          </div>

          <Button
            onClick={() => {
              window.open(
                `/patients/${selectedPatient?.value.user_id}`,
                "_blank"
              );
            }}
            nativeButtonProps={{ disabled: !selectedPatient }}
            loading={isLoadingSelectedPatient}
          >
            <Icon svg="user-wh" />
            Patient Chart
          </Button>
          <Button onClick={toggleReviewed} loading={isTogglingReviewed}>
            <Icon
              svg={
                fileInfo?.reviewed_at ? "x_failure_outline" : "check-done-wh"
              }
            />
            {fileInfo?.reviewed_at ? "Mark Not Reviewed" : "Mark Reviewed"}
          </Button>
          <Button
            onClick={toggleReleasedToPatient}
            loading={isTogglingReleased}
          >
            <Icon svg={fileInfo?.viewable_by_patient ? "hide-wh" : "eye-wh"} />
            {!loadingFile && fileInfo && fileInfo.viewable_by_patient
              ? "Revoke Patient Access"
              : "Release to Patient"}
          </Button>
          <AssignGenericStaffTask />
        </div>
      </div>

      <ViewBody fileId={fileId}>
        <div
          className={clsx(styles.body, {
            [styles.hasBackground]: fileExtension !== "pdf"
          })}
        >
          {renderFileContent()}
        </div>
      </ViewBody>
    </div>
  );
}
