/* FileAnnotations Name */
/* External Imports */
import { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

/* Local Imports */

// components
import Annotation from "@/components/annotations";
import AddAnnotation from "@/components/annotations/addAnnotation";

// constants
import { STATUS_KEYS } from "@/globals/constants";

// store
import {
  FileId,
  FileAnnotation,
  useFileAnnotationDeleteMutation,
  AnnotationId,
  useFileAnnotationCreateMutation,
  useFileAnnotationUpdateMutation,
  useFileDetailsQuery
} from "@/store/services/file";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

/* FileAnnotations Typescript Interface */
interface FileAnnotationsProps {
  fileId: FileId;
}

export default function FileAnnotations({ fileId }: FileAnnotationsProps) {
  /* Redux */
  const dispatch = useDispatch();

  const { data: fileDetails } = useFileDetailsQuery(
    { fileId: fileId as FileId },
    { skip: !fileId }
  );
  const [deleteAnnotation] = useFileAnnotationDeleteMutation();
  const [createAnnotation, { isLoading: isCreating }] =
    useFileAnnotationCreateMutation();
  const [updateAnnotation, { isLoading: isUpdating }] =
    useFileAnnotationUpdateMutation();
  const [addAnnotationOpen, setAddAnnotationOpen] = useState(false);

  /* Local State */
  const [openAnnotationIds, setOpenAnnotationIds] = useState<
    (AnnotationId | null)[]
  >([]);
  /* Local State */

  /* Effects */

  /* Event Handlers */

  const handleClose = () => {
    setAddAnnotationOpen(false);
  };

  function renderAddButton() {
    return (
      <button
        className={styles.addAnnotation}
        onClick={() => setAddAnnotationOpen(true)}
      >
        +
      </button>
    );
  }

  const handleDeleteFileAnnotation = (
    fileId: FileId,
    annotationId: AnnotationId
  ) => {
    deleteAnnotation({ fileId, annotationId })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCreateFileAnnotation = (fileId: FileId, annotation: string) => {
    createAnnotation({
      fileId,
      fileAnnotationCreateRequest: { file_id: fileId, annotation }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Created",
            type: STATUS_KEYS.SUCCESS
          })
        );
        setAddAnnotationOpen(false);
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to create annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };
  const handleUpdateFileAnnotation = (
    fileId: FileId,
    annotationId: AnnotationId,
    annotation: string
  ) => {
    // if the annotation id is not already present in the open annotation ids, add it
    if (!openAnnotationIds.includes(annotationId)) {
      setOpenAnnotationIds([...openAnnotationIds, annotationId]);
    }

    updateAnnotation({
      fileId,
      annotationId,
      fileAnnotationUpdateRequest: {
        annotation_id: annotationId,
        annotation
      }
    })
      .unwrap()
      .then(() => {
        // remove the annotation id from the open annotation ids
        setOpenAnnotationIds(
          openAnnotationIds.filter(id => id !== annotationId)
        );
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };
  return (
    <div className={clsx(styles.FileAnnotations)}>
      <div
        className={styles.annotationWrapper}
        data-cy="file-annotations-wrapper"
      >
        {/* either display already existing annotations, or empty state prompting user to add some */}

        <>
          <div className={styles.overflow}>
            {fileDetails?.annotations?.map((fileAnnotation: FileAnnotation) => (
              <Annotation
                key={fileAnnotation.annotation_id}
                annotation={fileAnnotation.annotation}
                author={fileAnnotation.author}
                created={fileAnnotation.created}
                updated={fileAnnotation.updated}
                annotationId={fileAnnotation.annotation_id}
                isEditing={openAnnotationIds.includes(
                  fileAnnotation.annotation_id
                )}
                onAnnotationUpdate={(annotationId, annotation) =>
                  handleUpdateFileAnnotation(
                    fileAnnotation.file_id,
                    annotationId as AnnotationId, // because file & lab order annotation ids are different types
                    annotation
                  )
                }
                onAnnotationCreate={(annotation: string) =>
                  handleCreateFileAnnotation(fileAnnotation.file_id, annotation)
                }
                onDelete={annotationId =>
                  handleDeleteFileAnnotation(
                    fileAnnotation.file_id,
                    annotationId as AnnotationId // because file & lab order annotation ids are different types
                  )
                }
                isSubmitting={isCreating || isUpdating}
              />
            ))}
          </div>

          <div className={styles.emptyState}>
            <div className={styles.addWrapper}>
              {renderAddButton()}

              {addAnnotationOpen && fileId && (
                <AddAnnotation
                  onCancel={handleClose}
                  onUpdate={(annotationId, annotation) =>
                    handleUpdateFileAnnotation(
                      fileId,
                      annotationId as AnnotationId, // because file & lab order annotation ids are different types
                      annotation
                    )
                  }
                  onCreate={annotation =>
                    handleCreateFileAnnotation(fileId, annotation)
                  }
                  isEditing={false}
                  isSubmitting={isCreating || isUpdating}
                />
              )}
            </div>
            <div className="tMd Med dark">Add Comments</div>
            <p className="xLight">Add annotations to the document</p>
          </div>
        </>
      </div>
    </div>
  );
}
