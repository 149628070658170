/* LabFlowsheetEntry Name */

/* External Imports */
import clsx from "clsx";

/* Components */
import LabFlowsheetFiles from "./labFlowsheetFiles";
import LabFlowsheetForm from "./labFlowsheetForm";

/* Store */

import {
  LabFlowsheetId,
  LabFlowsheetResultsByDay,
  LabFlowsheetTemplateId,
  UserId,
  useLabFlowsheetReadTemplateQuery
} from "@/store/services/lab";

/* Constants */

/* Styles */
import styles from "./styles.module.scss";

/* LabFlowsheetEntry Typescript Interface */
interface LabFlowsheetEntryProps {
  activeFlowsheetTemplateId: LabFlowsheetTemplateId;
  fileId?: number;
  dayResults?: LabFlowsheetResultsByDay;
  labFlowsheetId?: LabFlowsheetId;
  patientId: UserId;
}

export default function LabFlowsheetEntry({
  activeFlowsheetTemplateId,
  dayResults,
  labFlowsheetId,
  patientId
}: LabFlowsheetEntryProps) {
  /* Redux */
  const { data: labFlowsheetTemplate } = useLabFlowsheetReadTemplateQuery({
    labFlowsheetTemplateId: activeFlowsheetTemplateId
  });
  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.LabFlowsheetEntry)}>
      <LabFlowsheetFiles
        dayResults={dayResults}
        labFlowsheetId={labFlowsheetId}
        activeFlowsheetTemplateId={activeFlowsheetTemplateId}
        patientId={patientId}
      />
      <LabFlowsheetForm
        activeFlowsheetTemplateId={activeFlowsheetTemplateId}
        templateTests={labFlowsheetTemplate?.tests || []}
        dayResults={dayResults}
        labFlowsheetId={labFlowsheetId}
        patientId={patientId}
      />
    </div>
  );
}
