/* Assign Generic Staff Task  */
/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Button from "@/components/button";
import Icon from "@/components/icons";

// constants

// store
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

// styles
import styles from "./styles.module.scss";
import { STYLES } from "@/globals/constants";

/* AssignGenericStaffTask Typescript Interface */
interface AssignGenericStaffTaskProps {}

export default function AssignGenericStaffTask({}: AssignGenericStaffTaskProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { data: features } = useGetFeatureFlagsQuery();

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleClick = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.GENERIC_STAFF_TASK,
        props: { title: "Assign Staff Task" }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  return (
    <div
      className={clsx(styles.AssignGenericStaffTask, {
        [styles.hidden]: !features?.dev_staff_tasking_enabled
      })}
    >
      <Button onClick={handleClick} style={STYLES.SECONDARY}>
        <Icon svg="assign_task" />
        Assign Staff Task
      </Button>
    </div>
  );
}
