/* A place to store application constants */
import {
  BloodType,
  EncounterStatus,
  PaymentMethod
} from "@/store/services/patient";
import { BACKEND_PROTOCOL, BACKEND_HOST, BACKEND_PORT } from "./environment";
import { AnnotationKeyType } from "./types";
import { Statecode } from "@/store/services/location";
import { PregnancyOutcome } from "@/store/services/encounter";
import { ClaimStatus } from "@/store/services/claim";
import { AppointmentStatus } from "@/store/services/scheduling";

// APPLICATION CONSTANTS

// page routes
const PAGES = {
  ROOT: "/",
  LANDING: "landing",
  LOGIN: "/login",
  HOME: "/home",
  SETTINGS: "/settings",
  PATIENTS: "/patients",
  ENCOUNTERS: "/encounters",
  LABS: "/labs",
  SUPERBILL: "/superbill",
  MESSAGES: "/inbox",
  RESETREQUEST: "/resetRequest"
};

// backend routes
const BACKEND_BASE_URL = `${BACKEND_PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}/api/v1`;
const BACKEND_ROUTES = {
  VALIDATE_CREDENTIALS: `${BACKEND_BASE_URL}/user/validate-credentials`
};

// page subsections
const PATIENTS_VIEWS = {
  ACTIVE: "active_patients",
  TODAY: "today_patients",
  ARCHIVED: "achived_patients"
};

const APP_KEYS = {
  DEFAULT: "default",
  PATIENTS: "patients",
  TASKS: "tasks",
  MOBILE_TASKS: "mobile_tasks",
  WEEK: "week",
  DAY: "day",
  MULTI: "multi",
  MONTH: "month",
  NEW: "new",
  ESTABLISHED: "established",
  PROSPECTIVE: "prospective",
  ARCHIVED: "archived",
  ALL: "all",
  UPCOMING_APPTS: "upcoming_appts",
  LAB_RESULTS: "lab_results",
  ENCOUNTER_LAB_RESULTS: "encounter_lab_results",
  PATIENT_LAB_RESULTS: "patient_lab_results",
  ORDERS: "orders",
  FILES: "files",
  FILES_MOBILE: "files_mobile",
  PAST_ENCOUNTERS: "past_encounters",
  DOT_PHRASE_LIBRARY: "dot_phrase_library",
  FETAL_GROWTH: "fetal_growth",
  FAXES: "faxes",
  READ_FAXES: "read_faxes",
  OUTBOUND_FAXES: "outbound_faxes",
  PATIENT_FACING_FILES: "patient_facing_files",
  PATIENT_FILES: "patient_files",

  // Settings
  STAFF_INFO: "staff_info",
  APPOINTMENT_TYPES: "appointment_types",
  TASK_BUNDLES: "task_bundles",

  // claims
  INCOMPLETE_NOTES: "incomplete_notes",
  READY_TO_BILL: "ready_to_bill",
  SENT_TO_PAYOR: "sent_to_payor",
  REQUIRES_CHANGES: "requires_changes",
  FINALIZED_CLAIMS: "finalized_claims",
  PROCESSING: "processing",
  REJECTED: "rejected",
  DENIED: "denied",
  PATIENT_BALANCE: "patient_balance",
  IN_REVIEW: "in_review",
  CLAIMS: "claims",
  NON_BILLABLE: "non_billable",
  ON_HOLD: "on_hold",

  PATIENT_RECALLS: "patient_recalls",
  ALL_ENCOUNTERS: "all_encounters",
  APPT_ENCOUNTER_TYPE: "appointment_encounter_type",

  INCOMPLETE_ENCOUNTERS: "incomplete_encounters",
  INCOMPLETE_ENCOUNTERS_PRACTICE: "incomplete_encounters_practice",
  STAFF_TASKS: "staff_tasks",
  PATIENT_TASKS: "patient_tasks"
};

const CALENDAR_VIEW = [APP_KEYS.DAY, APP_KEYS.WEEK, APP_KEYS.MONTH];

const SCHEDULE_VIEWS = [
  { id: APP_KEYS.DAY, label: APP_KEYS.DAY },
  { id: APP_KEYS.WEEK, label: APP_KEYS.WEEK },
  { id: APP_KEYS.MONTH, label: APP_KEYS.MONTH }
];

const PROVIDER_DASH_TABS = [
  {
    label: "Clinical Work",
    id: "provider_dash"
  },
  {
    label: "Admin",
    id: "pario-admin"
  },
  {
    label: "Clinic Calendar",
    id: "clinic_cal"
  }
];

const PATIENTS_TABS = [
  {
    label: "Active",
    id: PATIENTS_VIEWS.ACTIVE
  }
];

const CLM_TABS = {
  PROVIDER: [
    { id: APP_KEYS.ALL, label: "All Claims" },
    { id: APP_KEYS.IN_REVIEW, label: "Drafts" },
    { id: APP_KEYS.ON_HOLD, label: "On hold" },
    { id: APP_KEYS.REQUIRES_CHANGES, label: "Changes required" },
    { id: APP_KEYS.PROCESSING, label: "Sent to biller" },
    { id: APP_KEYS.SENT_TO_PAYOR, label: "Sent to clearinghouse" },
    { id: APP_KEYS.REJECTED, label: "Clearinghouse rejections" },
    { id: APP_KEYS.DENIED, label: "Payor denials" },
    { id: APP_KEYS.PATIENT_BALANCE, label: "Patient balance" },
    { id: APP_KEYS.FINALIZED_CLAIMS, label: "Finalized" },
    { id: APP_KEYS.NON_BILLABLE, label: "Non-billable" }
  ],
  BILLER: [
    { id: APP_KEYS.ALL, label: "All Claims" },
    { id: APP_KEYS.IN_REVIEW, label: "Ready for submission" },
    { id: APP_KEYS.ON_HOLD, label: "On hold" },
    { id: APP_KEYS.REQUIRES_CHANGES, label: "Changes required" },
    { id: APP_KEYS.SENT_TO_PAYOR, label: "Sent to clearinghouse" },
    { id: APP_KEYS.REJECTED, label: "Clearinghouse rejections" },
    { id: APP_KEYS.DENIED, label: "Payor denials" },
    { id: APP_KEYS.PATIENT_BALANCE, label: "Patient balance" },
    { id: APP_KEYS.FINALIZED_CLAIMS, label: "Finalized" },
    { id: APP_KEYS.NON_BILLABLE, label: "Non-billable" }
  ]
};

// * PATIENTS MODULE *//
const PATIENT_VIEWS = {
  DASH: "patient_dashboard",
  ENCOUNTER: "encounter"
};

// LANDING PAGE //
const LAYOUTS = {
  LANDING: "landing",
  LOGIN: "login",
  INTERNAL: "internal",
  MOBILE: "mobile"
};

const PUBLIC_LAYOUTS = [LAYOUTS.LANDING, LAYOUTS.LOGIN];

const STYLES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  TERTIARY_CLR: "tertiaryColor",
  CTA: "callout",
  FULL_WIDTH: "fullWidth",
  SECONDARY_FULL: "secondaryFull",
  SECONDARY_FULL_TEXT_WRAP: "secondaryFullTextWrap",
  NO_OUTLINE: "noOutline",
  DANGER: "danger",
  // to remove in future
  ICON: "icon",
  ACTION: "action",
  LEGACY: "legacy", // for marking legacy styles
  DELETE: "delete",
  SUCCESS: "success"
};

const PRODUCT_OFFERINGS = {
  CHART: "chart",
  INTAKE: "intake",
  ORDER: "order",
  SCHEDULE: "schedule"
};

const STATUS_KEYS = {
  ERROR: "error",
  INFO: "info",
  INFO_GREY: "infoGrey",
  SUCCESS: "success",
  WARNING: "warning",
  DISABLED: "disabled",
  RECIPIENT: "recipient",
  LOCATION: "location",
  LOADING: "loading",
  PENDING: "pending"
};

const FLOW_KEYS = {
  PRENATAL: "PRENATAL",
  WELL_WOMAN: "WELL_WOMAN",
  LABOR: "LABOR",
  POSTPARTUM: "POSTPARTUM",
  INFANT: "INFANT"
};

const PATIENT_STATUS = {
  [APP_KEYS.NEW]: APP_KEYS.NEW,
  [APP_KEYS.ESTABLISHED]: APP_KEYS.ESTABLISHED,
  [APP_KEYS.PROSPECTIVE]: APP_KEYS.PROSPECTIVE,
  [APP_KEYS.ARCHIVED]: APP_KEYS.ARCHIVED
};

const APPOINTMENT_STATUS_TYPES: Record<AppointmentStatus, string> = {
  SCHEDULED: STATUS_KEYS.INFO_GREY,
  CONFIRMED: STATUS_KEYS.INFO,
  RESCHEDULED: STATUS_KEYS.ERROR,
  CANCELED: STATUS_KEYS.ERROR,
  NO_SHOW: STATUS_KEYS.ERROR,
  CHECKED_IN: STATUS_KEYS.SUCCESS,
  IN_PROGRESS: STATUS_KEYS.PENDING,
  COMPLETED: STATUS_KEYS.WARNING,
  CHECKED_OUT: STATUS_KEYS.INFO_GREY
};

const ENCOUNTER_STATUS_TYPES: Record<EncounterStatus, string> = {
  IN_PROGRESS: STATUS_KEYS.WARNING,
  SUBMITTED: STATUS_KEYS.SUCCESS,
  DELETED: STATUS_KEYS.ERROR
};

const ICON_KEYS = {
  CAL: "cal",
  LAB: "lab",
  ATOM: "atom",
  VORTEX: "vortex",
  CHAT: "chat"
};

const STATUS_TYPES = {
  [STATUS_KEYS.ERROR]: STATUS_KEYS.ERROR,
  [STATUS_KEYS.INFO]: STATUS_KEYS.INFO,
  [STATUS_KEYS.SUCCESS]: STATUS_KEYS.SUCCESS,
  [STATUS_KEYS.WARNING]: STATUS_KEYS.WARNING
};

// DATA CONSTANTS
// Data metrics
const METRICS: { [key: string]: string } = {
  ADJUSTMENTS: "Adjustments",
  TASK_ACTIONS: "Task Actions",
  FROM: "from",
  TO: "to",
  DESCRIPTION: "description",
  EMAIL: "email",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  SUFFIX: "suffix",
  NPI: "npi",
  DEA: "dea",
  DATE_OF_BIRTH: "date_of_birth",
  IS_ACTIVE: "is_active",
  IS_SCHEDULABLE: "is_schedulable",
  NAME: "name",
  PERMISSIONS: "permissions",
  PHONE: "phone",
  ROLE: "role",
  STATUS: "status",
  WEEKS: "weeks",
  DAYS: "days",
  DATE: "date",
  WEIGHT: "weight",
  WEIGHT_GAIN: "weight_gain",
  BP: "blood_pressure",
  PULSE: "pulse",
  FUNDAL_HT: "fundal_height",
  FHT: "fetal_heart_tones",
  FETAL_POS: "fetal_position",
  FETAL_ACT: "fetal_activity",
  FM: "FM",
  EDEMA: "edema",
  PROTEIN_GLUC: "protein_gluc",
  EDD: "EDD",
  PED: "PED",
  DOB: "dob",
  CLINICIAN: "clinician",
  LOCATION: "location",

  // Appointment metrics
  APPOINTMENT_DATE: "app_date",
  APPOINTMENT_TYPE: "appt_type",
  APPOINTMENT_STATUS: "appt_status",

  // Appointment Type metrics
  APPOINTMENT_DURATION: "duration",
  APPOINTMENT_COLOR: "color",
  APPOINTMENT_TASK_BUNDLE: "task_bundle",
  APPOINTMENT_CODE_BUNDLE: "code_bundle",

  // Task Bundle metrics
  TASK_BUNDLE_NAME: "task_bundle_name",
  TASK_BUNDLE_DESCRIPTION: "task_bundle_description",
  TASK_BUNDLE_NUM_TASKS: "task_bundle_num_tasks",

  TAG: "tag",
  NOTE: "note",
  LINK: "link",
  TASK: "task",
  PRENATAL: "PRENATAL",
  WELL_WOMAN: "WELL_WOMAN",
  LABOR: "LABOR",
  POSTPARTUM: "POSTPARTUM",
  SCHEDULED: "SCHEDULED",
  CANCELED: "CANCELED",
  NO_SHOW: "NO_SHOW",
  CHECKED_IN: "CHECKED_IN",
  CONFIRMED: "CONFIRMED",
  RESCHEDULED: "RESCHEDULED",
  IN_PROGRESS: "IN_PROGRESS",
  CHECKED_OUT: "CHECKED_OUT",
  REQUIRES_CHANGES: "REQUIRES_CHANGES",
  STAFF: "staff",
  PATIENT: "patient",
  OWNER: "owner",
  BILLED: "billed",

  // STAFF TYPES
  PROVIDER: "provider",
  BILLER: "biller",
  ADMIN: "admin",
  MA: "medical_assistant",

  // dash config metrics, if don't end up in backend then move to APP keys
  ORDERS: "orders",
  ORDERS_PERFORMING_SITE: "orders_performing_site",
  HEALTH_HISTORY: "health_history",
  TIMELINE: "timeline",
  TASKS: "tasks",
  DOCUMENTS: "documents",
  PRACTICE_DOCUMENTS: "practice_documents",
  APPOINTMENTS: "appointments",
  RESULTS: "results",
  MESSAGES: "messages",
  FACEPAGE: "facepage",
  DELETED: "DELETED",
  ARCHIVED: "ARCHIVED",
  ACTIVE: "ACTIVE",
  DIAGNOSIS: "DIAGNOSIS",
  STARTS: "starts",
  LAB_TEST: "test",
  LAB_VALUE: "value",
  LAB_REFERENCE: "reference",
  LAB_NOTE: "lab_note",
  NEW_TAB_LINK: "new_tab_link",
  IMAGING: "imaging",

  ORDER_ID: "order_id",
  ORDER_LABEL: "order_label",
  ORDER_STATUS: "order_status",
  ORDER_OUTCOME: "order_outcome",
  ORDER_REQUISITION_LINK: "Requisition",
  ORDER_RESULTS_LINK: "Results",
  ORDER_RESULT_NOTE: "Note",
  DATE_SENT: "Date Sent",
  DATE_FULFILLED: "Date Fulfilled",
  DATE_UPDATED: "Updated",
  ENCOUNTER_LINK: "encounter_link",
  ORDER_REVIEWED: "order_reviewed",

  FILE_VISIBLE_TO_PATIENT: "file_visible_to_patient",
  FILENAME: "filename",
  UPLOAD_TIME: "upload_time",
  UPLOADED_BY: "uploaded_by",
  SERVICE_TIME: "service_time",
  FILE_TAGS: "file_tags",
  UNSENT: "unsent",
  SENT: "sent",
  FINAL: "final",
  CORRECTED: "corrected",
  PRELIMINARY: "preliminary",
  ADDRESS_LINE_1: "addr_line_1",
  ADDRESS_LINE_2: "addr_line_2",
  ADDRESS_LINE_3: "addr_line_3",
  CITY: "city",
  STATE: "state",
  ZIP: "zip",
  SUMMARY: "summary",
  ENCOUNTER_TYPE: "encounter_type",
  TIME: "time",
  BILLING: "billing",
  FORMS: "forms",
  ORG_FILES: "org_files",
  LABS: "labs",
  SCHEDULING: "scheduling",
  FILES: "files",

  // Billing
  OVERDUE: "OVERDUE",
  COMPLETE: "complete",
  PENDING: "pending",
  DEMOGRAPHICS: "demographics",

  INCOMPLETE_ENCOUNTER: "incomplete_encounter",

  PHRASE_NAME: "phrase_name",
  PHRASE_DESCRIPTION: "phrase_desc",
  PHRASE_EDITOR_NAME: "phrase_editor",
  PHRASE_LAST_EDIT_TIME: "phrase_last_edited",
  PHRASE_OPERATIONS: "phrase_operations",

  // patient types
  OB: "OB",
  GYN: "GYN",
  INFANT: "INFANT",
  INACTIVE: "INACTIVE",
  PROSPECTIVE: "PROSPECTIVE",
  PRIMARY_CARE: "PRIMARY_CARE",

  // Infant Growth
  HEART_RATE: "heart_rate",
  RESPIRATORY_RATE: "respiratory_rate",
  TEMP: "temperature",
  LENGTH: "length",
  HEAD_CIRC: "head_circ",

  // allergies
  MILD: "MILD",
  MODERATE: "MODERATE",
  SEVERE: "SEVERE",
  FATAL: "FATAL",

  // faxes
  FAXES: "faxes",
  SENDER: "sender",
  RECIPIENT: "recipient",
  TIMESTAMP: "timestamp",
  RECEIVED_AT: "received_at",
  SENT_FOR_REVIEW_AT: "sent_for_review_at",
  REVIEWED_AT: "reviewed_at",
  REVIEWED_BY: "reviewed_by",
  OUTBOUND_FAX_STATUS: "outbound_fax_status",
  // pregnancy
  COMPLETED: "completed",
  PREGNANCY_COMPLETION_TIME: "pregnancy_completion_time",
  PREGNANCY_DELIVERY_DATE: "pregnancy_delivery_date",
  DESIRED_BIRTH_LOC: "desired_birthplace",

  // task
  ASSIGNED: "assigned",

  // lab order urgency following FHIR standards
  URGENT: "URGENT",
  ASAP: "ASAP",
  ROUTINE: "ROUTINE",
  STAT: "STAT",

  // patient
  FEMALE: "FEMALE",
  MALE: "MALE",
  UNKNOWN: "UNKNOWN",

  // medication
  ACTIVE_STATUS: "ACTIVE",
  NONCOMPLIANT: "NONCOMPLIANT",

  NOTES: "notes",

  // scheduling
  INTERNAL: "internal",
  EXTERNAL: "external",
  OUT_OF_OFFICE: "out_of_office",
  ON_CALL_FIRST: "on_call_first",
  ON_CALL_SECOND: "on_call_second",
  IN_CLINIC: "in_clinic",
  SCHEDULE: "schedule",
  ON_CALL_IN_CLINIC_FIRST: "on_call_in_clinic_first",
  ON_CALL_IN_CLINIC_SECOND: "on_call_in_clinic_second",

  // payment ledger
  PAYMENT: "payment",
  SOURCE: "source",
  CHARGE: "charge",
  ADJUSTMENT: "adjustment",
  DATE_OF_SERVICE: "date_of_service",
  TRANSACTION_NOTE: "transaction_note",
  AUTHOR: "author",

  // vitals
  HEIGHT: "height",
  FEET: "feet",
  INCHES: "inches",

  // Infant Growth
  DATE_OF_ENTRY: "date_of_entry",
  WEIGHT_GRAMS: "weight_grams",
  STANDING_HEIGHT_CM: "standing_height_cm",
  RECUMBENT_LENGTH_CM: "recumbent_length_cm",
  HEAD_CIRCUMFERENCE_CM: "head_circumference_cm",

  // claims
  IN_REVIEW: "IN_REVIEW",
  CHANGES_REQUIRED: "CHANGES_REQUIRED",
  HOLD: "HOLD",
  ACCEPTED: "ACCEPTED",
  CORRECTED_AND_RESUBMITTED: "CORRECTED_AND_RESUBMITTED",
  APPEAL_SUBMITTED: "APPEAL_SUBMITTED",
  CLAIM_AT_SECONDARY: "CLAIM_AT_SECONDARY",
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
  PATIENT_BALANCE: "PATIENT_BALANCE",
  DENIED_PENDING_PROVIDER_REVIEW: "DENIED_PENDING_PROVIDER_REVIEW",
  DENIED: "DENIED",
  PARTIALLY_DENIED: "PARTIALLY_DENIED",
  REJECTED_BY_CLEARINGHOUSE: "REJECTED_BY_CLEARINGHOUSE",
  CREATED: "CREATED",
  NON_BILLABLE: "NON_BILLABLE",

  // claim list item
  CLAIM_ID: "claim_id",
  PATIENT_NAME: "patient_name",
  PROVIDER_NAME: "provider_name",
  INSURANCE_NAME: "insurance_name",
  CLAIMED_AMOUNT: "claimed_amount",
  CHARGE_AMOUNT: "charge_amount",

  // Data grid stuff
  DATA_TAGS: "data_tags",

  // Task creation
  FORM_LIST: "form_list",
  FORM_NAME: "form_name",
  FORM_TITLE: "form_title",
  FORM_DESCRIPTION: "form_description",

  BILL_TO_PRACTICE: "CLIENT",
  BILL_TO_PATIENT: "PATIENT",
  BILL_TO_GUARANTOR: "GUARANTOR",
  BILL_TO_INSURANCE: "THIRD_PARTY",

  // claims
  CLAIMED: "claimed",
  ALLOWED: "allowed",
  DISCOUNT: "discount",
  INSURANCE_PAID: "insurance_paid",
  APPLIED_DEDUCTIBLE: "applied_deductible",
  COINSURANCE: "coinsurance",
  COPAY: "copay",
  CHARGE_RESULT: "charge_result",
  DENIAL_CODE: "denial_code",
  CPT_CODE: "cpt_code",
  ICD10_CODE: "icd10_code",
  MOD: "modifier_code",
  MODS: "modifier_codes",
  PAID_FULL: "PAID_FULL",
  PAID_PARTIAL: "PAID_PARTIAL",
  ADJUSTED: "ADJUSTED",
  PAYMENT_DENIED: "PAYMENT_DENIED",
  CREATED_AT: "created_at",
  IS_BILLABLE: "is_billable",

  // Estimated due date
  ESTIMATED_DUE_DATE: "estimated_due_date",
  DATE_OF_METHOD: "date_of_method",

  // Patient recalls
  DUE_DATE: "due_date",
  REASON: "reason",

  INBOX: "inbox",
  SIGNUP_COMPLETED: "signup_completed",
  INCOMPLETE_TASK_COUNT: "incomplete_tasks_count",
  HAS_ANNOTATIONS: "has_annotations",
  BIRTH_WEIGHT: "birth_weight",

  // checkout
  PAYMENT_METHOD: "payment_method",
  CASH: "CASH",
  CHECK: "CHECK",
  CREDIT_CARD: "CREDIT_CARD",
  ENCOUNTER_STATUS: "encounter_status",

  // infant flow sheet
  PERCENT_CHANGE_WEIGHT_SINCE_BIRTH: "percent_change_weight_since_birth",

  // medical status
  NORMAL: "NORMAL",
  ABNORMAL: "ABNORMAL",
  DECLINED: "DECLINED",
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",

  ENCOUNTER_PHONE_TYPE: "PHONE",
  LOCATION_HOME_TYPE: "HOME",
  LOCATION_OTHER_TYPE: "OTHER",

  NOT_CANCELED: "NOT_CANCELED"
};

const METRIC_LABEL_OVERRIDES = {
  [METRICS.FETAL_POS]: "Fetal Presentation"
};
// metric labels (copy)
const METRIC_LABELS = {
  [METRICS.PROVIDER]: "Provider",
  [METRICS.BILLER]: "Biller",
  [METRICS.ADMIN]: "Admin",
  [METRICS.MA]: "Medical Assistant",
  [METRICS.DATE_OF_BIRTH]: "Date of Birth",
  [METRICS.IS_ACTIVE]: "Is Active",
  [METRICS.IS_SCHEDULABLE]: "Is Schedulable",
  [METRICS.DEA]: "DEA",
  [METRICS.NPI]: "NPI",
  [METRICS.BILL_TO_PRACTICE]: "Practice",
  [METRICS.BILL_TO_PATIENT]: "Patient",
  [METRICS.BILL_TO_GUARANTOR]: "Guarantor",
  [METRICS.BILL_TO_INSURANCE]: "Insurance",
  [METRICS.FILE_VISIBLE_TO_PATIENT]: "Visible to Patient",
  [METRICS.FILENAME]: "File Name",
  [METRICS.UPLOAD_TIME]: "Uploaded At",
  [METRICS.UPLOADED_BY]: "Uploaded By",
  [METRICS.SERVICE_TIME]: "Serviced",
  [METRICS.FILE_TAGS]: "Tags",
  [METRICS.FIRST_NAME]: "First Name",
  [METRICS.LAST_NAME]: "Last Name",
  [METRICS.SUFFIX]: "Suffix",
  [METRICS.MOBILE]: "Mobile",
  [METRICS.WEIGHT_GAIN]: "weight gain",
  [METRICS.FUNDAL_HT]: "fundal height",
  [METRICS.FETAL_POS]: "fetal position",
  [METRICS.PROTEIN_GLUC]: "protein glucose",
  [METRICS.APPOINTMENT_DATE]: "Next/Last Appt.",
  [METRICS.APPOINTMENTS]: "appointments",
  [METRICS.LINK]: " ",
  [METRICS.FHT]: "FHT",
  [METRICS.BP]: "BP (Sys/Dias)",
  [METRICS.PRENATAL]: "OB Prenatal",
  [METRICS.POSTPARTUM]: "Postpartum",
  [METRICS.WELL_WOMAN]: "Well Woman",
  [METRICS.CHECKED_IN]: "Checked in",
  [METRICS.CHECKED_OUT]: "Checked out",
  [METRICS.SCHEDULED]: "Scheduled",
  [METRICS.CONFIRMED]: "Confirmed",
  [METRICS.RESCHEDULED]: "Rescheduled",
  [METRICS.SCHEDULING]: "Scheduling",
  [METRICS.IN_PROGRESS]: "In Progress",
  [METRICS.CANCELED]: "Canceled",
  [METRICS.NO_SHOW]: "No Show",
  [METRICS.ORDERS]: "Labs",
  [METRICS.HEALTH_HISTORY]: "Health History",
  [METRICS.TIMELINE]: "Activity Timeline",
  [METRICS.TASKS]: "Tasks",
  [METRICS.DOB]: "Date of Birth",
  [METRICS.EMAIL]: "Email",
  [METRICS.MESSAGES]: "Messages",
  [METRICS.DELETED]: "deleted",
  [METRICS.ARCHIVED]: "archived",
  [METRICS.ACTIVE]: "reactivated",
  [METRICS.PHONE]: "Phone",
  [METRICS.APPOINTMENT_STATUS]: "Appt. Status",
  [METRICS.APPOINTMENT_TYPE]: "Appt. Type",
  [METRICS.APPOINTMENT_DURATION]: "Duration (minutes)",
  [METRICS.APPOINTMENT_TASK_BUNDLE]: "Task Bundle",
  [METRICS.APPOINTMENT_CODE_BUNDLE]: "Code Bundle",
  [METRICS.STARTS]: "Time",
  [METRICS.LAB_TEST]: "Test",
  [METRICS.LAB_VALUE]: "Result",
  [METRICS.LAB_REFERENCE]: "Reference",
  [METRICS.LAB_NOTE]: "Note",
  [METRICS.NEW_TAB_LINK]: " ",
  [METRICS.ORDER_LABEL]: "Label",
  [METRICS.ORDER_STATUS]: "Status",
  [METRICS.ORDER_OUTCOME]: "Outcome",
  [METRICS.ORDER_REQUISITION_LINK]: "Requisition",
  [METRICS.ORDER_RESULTS_LINK]: "Results",
  [METRICS.ENCOUNTER_LINK]: "Encounter",
  [METRICS.ORDER_ID]: "ID",
  [METRICS.FILES]: "Files",
  [METRICS.IMAGING]: "Imaging",
  [METRICS.FINAL]: "Final",
  [METRICS.CORRECTED]: "Corrected",
  [METRICS.PRELIMINARY]: "Preliminary",
  [METRICS.UNSENT]: "Unsent",
  [METRICS.SENT]: "Sent",
  [METRICS.ADDRESS_LINE_1]: "Address Line 1",
  [METRICS.ADDRESS_LINE_2]: "Address Line 2",
  [METRICS.ADDRESS_LINE_3]: "Address Line 3",
  [METRICS.CITY]: "City",
  [METRICS.STATE]: "State",
  [METRICS.ZIP]: "Postal Code",
  [METRICS.SUMMARY]: "Summary",
  [METRICS.ENCOUNTER_TYPE]: "Encounter Type",
  [METRICS.TIME]: "Time",
  [METRICS.FETAL_ACT]: "Fetal Activity",
  [METRICS.ORG_FILES]: "Files",
  [METRICS.ORDER_REVIEWED]: "Reviewed",

  // billing
  [METRICS.OVERDUE]: "Overdue",
  [METRICS.COMPLETE]: "Paid",
  [METRICS.PENDING]: "Pending",
  [METRICS.DENIED]: "Denied",
  [METRICS.BILLING]: "Claim status",
  [METRICS.BILLED]: "Billed",
  [METRICS.DEMOGRAPHICS]: "Demographics & Insurance",

  // superbill
  [METRICS.APPROVED]: "Paid",
  [METRICS.DENIED]: "Denied",

  [METRICS.INCOMPLETE_ENCOUNTER]: "Incomplete Encounters",

  [METRICS.PHRASE_NAME]: "Name",
  [METRICS.PHRASE_DESCRIPTION]: "Description",
  [METRICS.PHRASE_EDITOR_NAME]: "Last Editor",
  [METRICS.PHRASE_LAST_EDIT_TIME]: "Last Edited",
  [METRICS.PHRASE_OPERATIONS]: "Edit Phrase",

  [METRICS.DESIRED_BIRTH_LOC]: "Planned birthplace",

  // labor
  [METRICS.LABOR]: "Labor",

  // patient types
  [METRICS.OB]: "OB",
  [METRICS.INFANT]: "Infant",
  [METRICS.GYN]: "Gyn",
  [METRICS.POSTPARTUM]: "Postpartum",
  [METRICS.INACTIVE]: "Inactive",
  [METRICS.PROSPECTIVE]: "Prospective",
  [METRICS.PRIMARY_CARE]: "Primary Care",

  // Infant Growth
  [METRICS.HEART_RATE]: "HR",
  [METRICS.RESPIRATORY_RATE]: "RR",
  [METRICS.LENGTH]: "Length",
  [METRICS.HEAD_CIRC]: "Head Circumference",

  // allergies
  [METRICS.MILD]: "Mild",
  [METRICS.MODERATE]: "Moderate",
  [METRICS.SEVERE]: "Severe",
  [METRICS.FATAL]: "Fatal",

  // faxes
  [METRICS.FAXES]: "Faxes",
  [METRICS.SENDER]: "Sender",
  [METRICS.RECIPIENT]: "Recipient",
  [METRICS.TIMESTAMP]: "Timestamp",
  [METRICS.RECEIVED_AT]: "Received on",
  [METRICS.SENT_FOR_REVIEW_AT]: "Sent for review",
  [METRICS.REVIEWED_AT]: "Reviewed on",
  [METRICS.REVIEWED_BY]: "Reviewed by",
  [METRICS.OUTBOUND_FAX_STATUS]: "Status",

  // pregnancy
  [METRICS.PREGNANCY_COMPLETION_TIME]: "Completed Date",
  [METRICS.PREGNANCY_DELIVERY_DATE]: "Delivery Date",

  // tasks
  [METRICS.ASSIGNED]: "Assigned On",

  [METRICS.PROSPECTIVE]: "Prospective",

  SUBMITTED: "Submitted",

  // patient
  [METRICS.FEMALE]: "Female",
  [METRICS.MALE]: "Male",
  [METRICS.UNKNOWN]: "Unknown",

  // medication
  [METRICS.ACTIVE_STATUS]: "Active",
  [METRICS.NONCOMPLIANT]: "Non-compliant",

  // lab ordering urgency
  [METRICS.URGENT]: "Urgent",
  [METRICS.ASAP]: "ASAP",
  [METRICS.ROUTINE]: "Routine",
  [METRICS.STAT]: "STAT",

  [METRICS.NOTES]: "Notes",
  [METRICS.ORDERS_PERFORMING_SITE]: "Performing Site",

  // scheduling
  [METRICS.INTERNAL]: "Event",
  [METRICS.EXTERNAL]: "External",
  [METRICS.OUT_OF_OFFICE]: "OOO",
  [METRICS.ON_CALL_FIRST]: "1st Call",
  [METRICS.ON_CALL_SECOND]: "2nd Call",
  [METRICS.IN_CLINIC]: "Clinic",
  [METRICS.SCHEDULE]: "Shift",
  [METRICS.ON_CALL_IN_CLINIC_FIRST]: "Clinic - 1st Call",
  [METRICS.ON_CALL_IN_CLINIC_SECOND]: "Clinic - 2nd Call",

  // payments ledger
  [METRICS.PAYMENT]: "Patient Balance",
  [METRICS.SOURCE]: "Source",
  [METRICS.CHARGE]: "Amount",
  [METRICS.ADJUSTMENTS]: "Adjustments",
  [METRICS.DATE_OF_SERVICE]: "Date of Service",
  [METRICS.TRANSACTION_NOTE]: "Note",
  [METRICS.AUTHOR]: "Author",

  // vitals
  [METRICS.HEIGHT]: "Height",
  [METRICS.WEIGHT]: "Weight (lbs)",
  [METRICS.TEMP]: "Temp (°F)",
  [METRICS.FEET]: "Height (ft)",
  [METRICS.INCHES]: "Height (in)",

  [METRICS.DAYS]: "Days",

  // Infant Growth
  [METRICS.DATE_OF_ENTRY]: "Date",
  [METRICS.WEIGHT_GRAMS]: "Weight (g)",
  [METRICS.BIRTH_WEIGHT]: "Weight",
  [METRICS.STANDING_HEIGHT_CM]: "Standing Height (cm)",
  [METRICS.RECUMBENT_LENGTH_CM]: "Recumbent Length (cm)",
  [METRICS.HEAD_CIRCUMFERENCE_CM]: "Head Circumference (cm)",

  // Task creation
  [METRICS.FORM_NAME]: "Form Name",
  [METRICS.FORM_TITLE]: "Form Title",
  [METRICS.FORM_DESCRIPTION]: "Form Description",

  // claims
  [METRICS.CLAIMED]: "Fee",
  [METRICS.ALLOWED]: "Allowed",
  [METRICS.DISCOUNT]: "Adjustment",
  [METRICS.INSURANCE_PAID]: "Insurance Paid",
  [METRICS.APPLIED_DEDUCTIBLE]: "Deductible",
  [METRICS.COINSURANCE]: "Coinsurance",
  [METRICS.COPAY]: "Copay",
  [METRICS.PATIENT_BALANCE]: "Patient Balance",
  [METRICS.CHARGE_RESULT]: "Result",
  [METRICS.DENIAL_CODE]: "Denial Code",
  [METRICS.CPT_CODE]: "CPT Code",
  [METRICS.ICD10_CODE]: "ICD-10 Codes",
  [METRICS.MOD]: "Mod",
  [METRICS.MODS]: "Modifier Codes",
  [METRICS.PAID_FULL]: "Paid",
  [METRICS.PAID_PARTIAL]: "Partial Payment",
  [METRICS.ADJUSTED]: "Adjusted",
  [METRICS.PAYMENT_DENIED]: "Denied",
  // Claims
  [METRICS.IN_REVIEW]: "Ready to bill",
  [METRICS.CHANGES_REQUIRED]: "Changes required",
  [METRICS.HOLD]: "On hold",
  [METRICS.CORRECTED_AND_RESUBMITTED]: "Corrected claim submitted",
  [METRICS.APPEAL_SUBMITTED]: "Appeal submitted",
  [METRICS.CLAIM_AT_SECONDARY]: "Claim at secondary",
  [METRICS.ACCEPTED]: "Accepted by clearinghouse",
  [METRICS.SUBMITTED]: "Submitted",
  [METRICS.APPROVED]: "Paid in Full",
  [METRICS.PATIENT_BALANCE]: "Patient balance",
  [METRICS.DENIED_PENDING_PROVIDER_REVIEW]: "Denied pending provider review",
  [METRICS.DENIED]: "Denied",
  [METRICS.PARTIALLY_DENIED]: "Partially denied pending provider review",
  [METRICS.REJECTED_BY_CLEARINGHOUSE]: "Rejected by clearinghouse",
  [METRICS.CREATED]: "Draft",
  [METRICS.NON_BILLABLE]: "Non-billable",

  // Claim list item
  [METRICS.CLAIM_ID]: "Pario Claim ID",
  [METRICS.PATIENT_NAME]: "Patient",
  [METRICS.PROVIDER_NAME]: "Provider",
  [METRICS.INSURANCE_NAME]: "Insurance",
  [METRICS.CHARGE_AMOUNT]: "Fee",
  [METRICS.CLAIMED_AMOUNT]: "Claim Amount",
  [METRICS.CREATED_AT]: "Created At",
  [METRICS.IS_BILLABLE]: "Billable",

  [METRICS.ESTIMATED_DUE_DATE]: "Estimated Due Date",
  [METRICS.DATE_OF_METHOD]: "Date",
  COMPLETED: "Ready for Checkout",

  [METRICS.DUE_DATE]: "Due date",
  [METRICS.REASON]: "Reason",

  [METRICS.INBOX]: "Messages",

  [METRICS.SIGNUP_COMPLETED]: "Activated",
  [METRICS.INCOMPLETE_TASK_COUNT]: "Incomplete Tasks",
  [METRICS.HAS_ANNOTATIONS]: "Has Comments",

  // checkout
  [METRICS.PAYMENT_METHOD]: "Payment Type",
  [METRICS.CASH]: "Cash",
  [METRICS.CHECK]: "Check",
  [METRICS.CREDIT_CARD]: "Credit Card",

  [METRICS.ENCOUNTER_STATUS]: "Note Status",

  // infant flowsheet
  [METRICS.PERCENT_CHANGE_WEIGHT_SINCE_BIRTH]: "Weight Delta (since birth)",

  // task bundles
  [METRICS.TASK_BUNDLE_NAME]: "Bundle Name",
  [METRICS.TASK_BUNDLE_DESCRIPTION]: "Description",
  [METRICS.TASK_BUNDLE_NUM_TASKS]: "Number of Tasks",

  // medical status
  [METRICS.NORMAL]: "Normal",
  [METRICS.ABNORMAL]: "Abnormal",
  [METRICS.DECLINED]: "Declined",
  [METRICS.POSITIVE]: "Positive",
  [METRICS.NEGATIVE]: "Negative",
  [METRICS.ENCOUNTER_PHONE_TYPE]: "Phone",
  [METRICS.LOCATION_HOME_TYPE]: "Home",
  [METRICS.LOCATION_OTHER_TYPE]: "Other",

  [APP_KEYS.STAFF_TASKS]: "Staff Tasks",
  [METRICS.NOT_CANCELED]: "Not Canceled"
};

const TAG_LABELS = {
  [METRICS.PRENATAL]: "OB",
  [METRICS.WELL_WOMAN]: "GYN"
};

const INPUT_TYPES = {
  [METRICS.DATE]: "date",
  [METRICS.WEIGHT_GRAMS]: "number",
  [METRICS.STANDING_HEIGHT_CM]: "number",
  [METRICS.RECUMBENT_LENGTH_CM]: "number",
  [METRICS.HEAD_CIRCUMFERENCE_CM]: "number",
  [METRICS.DATE_OF_ENTRY]: "date"
};

// grid type to data mapping
const GRID_METRICS: { [key: string]: string[] } = {
  [FLOW_KEYS.PRENATAL]: [
    METRICS.WEEKS,
    METRICS.DAYS,
    METRICS.DATE,
    METRICS.WEIGHT,
    METRICS.BP,
    METRICS.PULSE,
    METRICS.FUNDAL_HT,
    METRICS.FHT,
    METRICS.FETAL_POS,
    METRICS.FETAL_ACT,
    METRICS.EDEMA
  ],
  [APP_KEYS.PATIENTS]: [METRICS.NAME, METRICS.DOB, METRICS.SIGNUP_COMPLETED],
  [METRICS.OB]: [
    METRICS.NAME,
    METRICS.EDD,
    METRICS.DOB,
    METRICS.SIGNUP_COMPLETED
  ],
  [METRICS.POSTPARTUM]: [
    METRICS.NAME,
    METRICS.DOB,
    METRICS.PREGNANCY_DELIVERY_DATE,
    METRICS.SIGNUP_COMPLETED
  ],
  [APP_KEYS.STAFF_TASKS]: [
    METRICS.DUE_DATE,
    METRICS.FROM,
    METRICS.TO,
    METRICS.TASK,
    METRICS.STATUS,
    METRICS.ASSIGNED,
    METRICS.COMPLETED
  ],
  [APP_KEYS.PATIENT_TASKS]: [
    METRICS.FROM,
    METRICS.TO,
    METRICS.TASK,
    METRICS.STATUS,
    METRICS.ASSIGNED,
    METRICS.COMPLETED
  ],
  [METRICS.PRACTICE_DOCUMENTS]: [
    METRICS.ASSIGNED,
    METRICS.FILENAME,
    METRICS.FROM,
    METRICS.TO,
    METRICS.PATIENT_NAME,
    METRICS.STATUS,
    METRICS.REVIEWED_AT
  ],
  [METRICS.DOCUMENTS]: [
    METRICS.ASSIGNED,
    METRICS.FILENAME,
    METRICS.FROM,
    METRICS.PATIENT_NAME,
    METRICS.STATUS,
    METRICS.REVIEWED_AT
  ],
  [APP_KEYS.MOBILE_TASKS]: [METRICS.TASK, METRICS.STATUS],
  [APP_KEYS.UPCOMING_APPTS]: [
    METRICS.STARTS,
    METRICS.DATE,
    METRICS.LOCATION,
    METRICS.PATIENT,
    METRICS.APPOINTMENT_TYPE,
    METRICS.PROVIDER,
    METRICS.APPOINTMENT_STATUS
  ],
  [APP_KEYS.ENCOUNTER_LAB_RESULTS]: [
    METRICS.ORDER_STATUS,
    METRICS.LAB_TEST,
    METRICS.LAB_VALUE,
    METRICS.LAB_REFERENCE,
    METRICS.NEW_TAB_LINK
  ],
  [APP_KEYS.PATIENT_LAB_RESULTS]: [
    METRICS.LAB_TEST,
    METRICS.LAB_VALUE,
    METRICS.LAB_REFERENCE,
    METRICS.ORDER_STATUS,
    METRICS.DATE_UPDATED,
    METRICS.ORDERS_PERFORMING_SITE,
    METRICS.LAB_NOTE
  ],
  [APP_KEYS.ORDERS]: [
    METRICS.ORDER_LABEL,
    METRICS.DATE_SENT,
    METRICS.DATE_FULFILLED,
    METRICS.PATIENT,
    METRICS.PROVIDER,
    METRICS.ORDER_STATUS,
    METRICS.ORDER_REVIEWED,
    METRICS.ORDER_RESULT_NOTE
  ],
  [APP_KEYS.FILES]: [
    METRICS.FILENAME,
    METRICS.UPLOAD_TIME,
    METRICS.UPLOADED_BY,
    METRICS.REVIEWED_AT,
    METRICS.REVIEWED_BY,
    METRICS.FILE_TAGS,
    METRICS.HAS_ANNOTATIONS
  ],
  [APP_KEYS.PATIENT_FILES]: [
    METRICS.FILENAME,
    METRICS.UPLOAD_TIME,
    METRICS.UPLOADED_BY,
    METRICS.REVIEWED_AT,
    METRICS.REVIEWED_BY,
    METRICS.FILE_VISIBLE_TO_PATIENT,
    METRICS.FILE_TAGS,
    METRICS.HAS_ANNOTATIONS
  ],
  [APP_KEYS.PATIENT_FACING_FILES]: [
    METRICS.FILENAME,
    METRICS.UPLOAD_TIME,
    METRICS.FILE_TAGS
  ],
  [APP_KEYS.FILES_MOBILE]: [METRICS.FILENAME, METRICS.FILE_TAGS],
  [APP_KEYS.PAST_ENCOUNTERS]: [
    METRICS.DATE,
    METRICS.ENCOUNTER_TYPE,
    METRICS.PROVIDER,
    METRICS.NOTE
  ],
  [APP_KEYS.ALL_ENCOUNTERS]: [
    "",
    METRICS.DATE,
    METRICS.TIME,
    METRICS.ENCOUNTER_TYPE,
    METRICS.PROVIDER,
    METRICS.LOCATION,
    METRICS.APPOINTMENT_STATUS,
    METRICS.ENCOUNTER_STATUS
  ],
  [APP_KEYS.DOT_PHRASE_LIBRARY]: [
    METRICS.PHRASE_NAME,
    METRICS.PHRASE_DESCRIPTION,
    METRICS.PHRASE_EDITOR_NAME,
    METRICS.PHRASE_LAST_EDIT_TIME,
    METRICS.PHRASE_OPERATIONS
  ],
  [APP_KEYS.FETAL_GROWTH]: [
    METRICS.DATE_OF_ENTRY,
    METRICS.BIRTH_WEIGHT,
    METRICS.PERCENT_CHANGE_WEIGHT_SINCE_BIRTH,
    METRICS.RECUMBENT_LENGTH_CM,
    METRICS.HEAD_CIRCUMFERENCE_CM,
    METRICS.RESPIRATORY_RATE,
    METRICS.TEMP,
    METRICS.PULSE,
    METRICS.NOTE
  ],
  [APP_KEYS.FAXES]: [METRICS.RECEIVED_AT, METRICS.SENDER, METRICS.PATIENT],
  [APP_KEYS.READ_FAXES]: [
    METRICS.RECEIVED_AT,
    METRICS.SENT_FOR_REVIEW_AT,
    METRICS.SENDER,
    METRICS.REVIEWED_AT,
    METRICS.REVIEWED_BY,
    METRICS.PATIENT
  ],
  [METRICS.PAYMENTS]: [
    METRICS.DATE,
    METRICS.CHARGE,
    METRICS.PAYMENT_METHOD,
    METRICS.AUTHOR,
    METRICS.DATE_OF_SERVICE,
    METRICS.CPT_CODE,
    METRICS.TRANSACTION_NOTE
  ],
  [APP_KEYS.OUTBOUND_FAXES]: [
    METRICS.RECIPIENT,
    METRICS.SENDER,
    METRICS.DATE_SENT,
    METRICS.OUTBOUND_FAX_STATUS
  ],
  [METRICS.FORM_LIST]: [METRICS.FORM_TITLE, METRICS.FORM_DESCRIPTION],
  [APP_KEYS.INCOMPLETE_NOTES]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS
  ],
  [APP_KEYS.READY_TO_BILL]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS
  ],
  [APP_KEYS.PROCESSING]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS
  ],

  [APP_KEYS.SENT_TO_PAYOR]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS,
    METRICS.CREATED_AT
  ],
  [APP_KEYS.REQUIRES_CHANGES]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS,
    METRICS.CREATED_AT
  ],
  [APP_KEYS.FINALIZED_CLAIMS]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS,
    METRICS.CREATED_AT
  ],
  [APP_KEYS.CLAIMS]: [
    METRICS.DATE_OF_SERVICE,
    METRICS.PATIENT_NAME,
    METRICS.INSURANCE_NAME,
    METRICS.PROVIDER_NAME,
    METRICS.CLAIMED_AMOUNT,
    METRICS.CLAIM_ID,
    METRICS.STATUS,
    METRICS.CREATED_AT
  ],
  [APP_KEYS.PATIENT_RECALLS]: [
    METRICS.DUE_DATE,
    METRICS.PATIENT_NAME,
    METRICS.PHONE,
    METRICS.REASON
  ],
  [APP_KEYS.INCOMPLETE_ENCOUNTERS]: [
    METRICS.DATE,
    METRICS.TIME,
    METRICS.ENCOUNTER_TYPE,
    METRICS.PATIENT_NAME,
    METRICS.LOCATION,
    METRICS.APPOINTMENT_STATUS
  ],
  [APP_KEYS.INCOMPLETE_ENCOUNTERS_PRACTICE]: [
    METRICS.DATE,
    METRICS.TIME,
    METRICS.PROVIDER_NAME,
    METRICS.ENCOUNTER_TYPE,
    METRICS.PATIENT_NAME,
    METRICS.LOCATION,
    METRICS.APPOINTMENT_STATUS
  ],
  [APP_KEYS.STAFF_INFO]: [
    METRICS.FIRST_NAME,
    METRICS.LAST_NAME,
    METRICS.SUFFIX,
    METRICS.EMAIL,
    METRICS.PHONE,
    METRICS.ROLE,
    METRICS.NPI,
    METRICS.DEA,
    METRICS.DATE_OF_BIRTH,
    METRICS.ADDRESS_LINE_1,
    METRICS.ADDRESS_LINE_2,
    METRICS.CITY,
    METRICS.STATE,
    METRICS.ZIP,
    METRICS.IS_SCHEDULABLE,
    METRICS.IS_ACTIVE
  ],
  [APP_KEYS.APPOINTMENT_TYPES]: [
    METRICS.APPOINTMENT_TYPE,
    METRICS.ENCOUNTER_TYPE,
    METRICS.APPOINTMENT_DURATION,
    METRICS.APPOINTMENT_COLOR,
    METRICS.APPOINTMENT_TASK_BUNDLE,
    METRICS.APPOINTMENT_CODE_BUNDLE
  ],
  [APP_KEYS.TASK_BUNDLES]: [
    METRICS.TASK_BUNDLE_NAME,
    METRICS.TASK_BUNDLE_DESCRIPTION,
    METRICS.TASK_BUNDLE_NUM_TASKS
  ]
};

const PATIENT_TYPES = [
  METRICS.OB,
  METRICS.GYN,
  METRICS.POSTPARTUM,
  METRICS.PROSPECTIVE,
  METRICS.INACTIVE
];

// ORDERS
const ANNOTATION_TYPES = {
  ANNOTATE: "annotate",
  PROBLEM_LIST: "problem_list",
  MSG_PATIENT: "msg_patient",
  TASK: "task_internal",
  ORDER_LAB: "order_lab",
  ORDER_IMG: "order_img",
  WRITE_RX: "write_rx",
  REFERRAL: "make_ref"
};

const ANNOTATION_LABELS: Record<AnnotationKeyType, string> = {
  [ANNOTATION_TYPES.ANNOTATE]: "Annotate",
  [ANNOTATION_TYPES.PROBLEM_LIST]: "Problem List",
  [ANNOTATION_TYPES.MSG_PATIENT]: "Message Patient",
  [ANNOTATION_TYPES.TASK]: "Task (Internal)",
  [ANNOTATION_TYPES.ORDER_LAB]: "Order Lab",
  [ANNOTATION_TYPES.ORDER_IMG]: "Order Imaging",
  [ANNOTATION_TYPES.WRITE_RX]: "Write Rx",
  [ANNOTATION_TYPES.REFERRAL]: "Make Referral"
};

// TO BE IN BACKEND

const TAG_COLOR_MAP = {
  [METRICS.PRENATAL]: STATUS_KEYS.SUCCESS,
  [METRICS.WELL_WOMAN]: STATUS_KEYS.WARNING,
  [METRICS.OVERDUE]: STATUS_KEYS.ERROR,
  [METRICS.APPROVED]: STATUS_KEYS.SUCCESS,
  [METRICS.PENDING]: STATUS_KEYS.WARNING,
  [METRICS.DENIED]: STATUS_KEYS.ERROR,
  [METRICS.PARTIALLY_DENIED]: STATUS_KEYS.ERROR,
  [METRICS.REJECTED_BY_CLEARINGHOUSE]: STATUS_KEYS.ERROR,
  [METRICS.DENIED]: STATUS_KEYS.ERROR,
  [METRICS.APPROVED]: STATUS_KEYS.SUCCESS,
  SUBMITTED: STATUS_KEYS.INFO
};

const EVENT_COLOR_MAP = {
  [METRICS.INTERNAL]: STATUS_KEYS.INFO,
  [METRICS.EXTERNAL]: STATUS_KEYS.WARNING,
  [METRICS.OUT_OF_OFFICE]: STATUS_KEYS.INFO_GREY
};

const DASH_CONFIG = {
  [METRICS.FACEPAGE]: [
    { id: METRICS.SUMMARY },
    { id: METRICS.DEMOGRAPHICS },
    {
      id: METRICS.NOTES
    },
    {
      id: METRICS.TASKS
    },
    {
      id: METRICS.FILES
    },
    {
      id: METRICS.IMAGING
    }
  ],
  // TODO(Landon): We need a better way to build these arrays since we need to factor in feature
  // flags and whatnot
  [METRICS.PROVIDER]: [
    {
      id: METRICS.APPOINTMENTS, // object for now in case we need to store additional properties
      // but could be an array
      dataQuery: {
        which: METRICS.PROVIDER
      }
    },
    {
      id: METRICS.INCOMPLETE_ENCOUNTERS
    },
    {
      id: METRICS.DOCUMENTS
    },
    {
      id: METRICS.TASKS
    },
    {
      id: APP_KEYS.STAFF_TASKS
    },
    {
      id: METRICS.FAXES
    }
  ],
  [METRICS.PATIENT]: [
    {
      id: METRICS.APPOINTMENTS // object for now in case we need to store additional properties
      // but could be an array
    },
    {
      id: METRICS.FILES
    },
    {
      id: METRICS.TASKS
    }
  ],
  [METRICS.ADMIN]: [
    {
      id: METRICS.SCHEDULING
    },
    {
      id: METRICS.TASKS
    },
    {
      id: APP_KEYS.STAFF_TASKS
    },
    {
      id: METRICS.INCOMPLETE_ENCOUNTER
    },
    {
      id: METRICS.ORG_FILES
    },
    {
      id: METRICS.FAXES
    }
  ],
  [METRICS.BILLER]: [
    {
      id: METRICS.SCHEDULING
    },
    {
      id: METRICS.TASKS
    },
    {
      id: APP_KEYS.STAFF_TASKS
    },
    {
      id: METRICS.FAXES
    }
  ]
};

const CLAIM_PROVIDER_LABEL_OVERRIDES = {
  [METRICS.IN_REVIEW]: "Sent to biller",
  [METRICS.SUBMITTED]: "Sent to clearinghouse"
};

const CLAIM_STATUS_TYPES = {
  [METRICS.IN_REVIEW]: STATUS_KEYS.WARNING,
  [METRICS.CHANGES_REQUIRED]: STATUS_KEYS.ERROR,
  [METRICS.HOLD]: STATUS_KEYS.INFO_GREY,
  [METRICS.ACCEPTED]: STATUS_KEYS.SUCCESS,
  [METRICS.SUBMITTED]: STATUS_KEYS.INFO,
  [METRICS.APPROVED]: STATUS_KEYS.SUCCESS,
  [METRICS.PATIENT_BALANCE]: STATUS_KEYS.ERROR,
  [METRICS.DENIED_PENDING_PROVIDER_REVIEW]: STATUS_KEYS.ERROR,
  [METRICS.DENIED]: STATUS_KEYS.ERROR,
  [METRICS.PARTIALLY_DENIED]: STATUS_KEYS.ERROR,
  [METRICS.REJECTED_BY_CLEARINGHOUSE]: STATUS_KEYS.ERROR,
  [METRICS.NON_BILLABLE]: STATUS_KEYS.INFO_GREY
};

const CLAIM_PROVIDER_STATUS_TYPES_OVERRIDES = {
  [METRICS.SUBMITTED]: STATUS_KEYS.WARNING,
  [METRICS.ACCEPTED]: STATUS_KEYS.WARNING,
  [METRICS.IN_REVIEW]: STATUS_KEYS.WARNING
};

const TASK_STATUS_TYPES = {
  Pending: STATUS_KEYS.WARNING,
  Complete: STATUS_KEYS.SUCCESS,
  Reviewed: STATUS_KEYS.SUCCESS,
  Unreviewed: STATUS_KEYS.WARNING
};

const CHART_STATUS_MAP = {
  IN_PROGRESS: {
    label: "Resume Appointment ->",
    type: STATUS_KEYS.ERROR
  },
  SCHEDULED: {
    label: "Start Appointment ->",
    type: STATUS_KEYS.INFO_GREY
  },
  CANCELED: {
    label: "View Chart ->",
    type: STATUS_KEYS.INFO_GREY
  },
  NO_SHOW: {
    label: "View Chart ->",
    type: STATUS_KEYS.INFO_GREY
  },
  CHECKED_IN: {
    label: "Start Appointment ->",
    type: STATUS_KEYS.INFO_GREY
  },
  CONFIRMED: {
    label: "Start Appointment ->",
    type: STATUS_KEYS.INFO_GREY
  },
  CHECKED_OUT: {
    label: "View Encounter ->",
    type: STATUS_KEYS.INFO_GREY
  },
  FINISHED: {
    label: "View Encounter ->",
    type: STATUS_KEYS.INFO_GREY
  },
  COMPLETED: {
    label: "View Encounter ->",
    type: STATUS_KEYS.INFO_GREY
  },
  RESCHEDULED: {
    label: "Start Appointment ->",
    type: STATUS_KEYS.ERROR
  }
};

const USER_ROLES = {
  1: METRICS.OWNER,
  2: METRICS.ADMIN,
  4: METRICS.STAFF,
  8: METRICS.PROVIDER,
  16: METRICS.PATIENT
};

const MOBILE_VIEWS = {
  [METRICS.PROVIDER]: [
    METRICS.APPOINTMENTS,
    METRICS.MESSAGES,
    METRICS.LABS,
    METRICS.FILES
  ],
  [METRICS.PATIENT]: [
    METRICS.APPOINTMENTS,
    METRICS.TIMELINE,
    METRICS.MESSAGES,
    METRICS.FILES,
    METRICS.TASKS
  ],
  [METRICS.ADMIN]: [METRICS.APPOINTMENTS, METRICS.MESSAGES, METRICS.FILES]
};

const STATE_CODES: Statecode[] = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

const PREGNANCY_OUTCOMES: PregnancyOutcome[] = [
  "NSVD (vaginal delivery)",
  "NSVD (vaginal delivery) - Water",
  "NSVB (vaginal birth)",
  "TAB (therapeautic abortion)",
  "EAB (elective abortion)",
  "SAB (miscarriage)",
  "SBVD (stillbirth vaginal delivery)",
  "SBCS (stillbirth cesarean delivery)",
  "VAVD (vacuum vaginal delivery)",
  "FAVD (forceps vaginal delivery)",
  "CS (cesarean)",
  "VBAC (vaginal birth after previous cesarean)",
  "VBAC (vaginal birth after previous cesarean) - Water",
  "EP (ectopic pregnancy)",
  "UNKNOWN"
];

const BLOOD_TYPES: BloodType[] = [
  "A+",
  "A-",
  "B+",
  "B-",
  "AB+",
  "AB-",
  "O+",
  "O-",
  "UNKNOWN"
];

const ALLERGY_SEVERITITES = [
  METRICS.MILD,
  METRICS.MODERATE,
  METRICS.SEVERE,
  METRICS.FATAL
];

const BILL_RECIPIENTS = [
  METRICS.BILL_TO_PRACTICE,
  METRICS.BILL_TO_PATIENT,
  METRICS.BILL_TO_GUARANTOR,
  METRICS.BILL_TO_INSURANCE
];

const ORDER_URGENCY = [
  METRICS.ROUTINE,
  METRICS.URGENT,
  METRICS.ASAP,
  METRICS.STAT
];

const VISIT_LOCATIONS = ["HOME", "PHONE", "OTHER"];

const CLAIM_OUTCOMES = [METRICS.PAID_FULL, METRICS.PAYMENT_DENIED];
const CLAIM_STATUSES: ClaimStatus[] = [
  "IN_REVIEW",
  "CHANGES_REQUIRED",
  "ACCEPTED",
  "CORRECTED_AND_RESUBMITTED",
  "APPEAL_SUBMITTED",
  "CLAIM_AT_SECONDARY",
  "HOLD",
  "SUBMITTED",
  "REJECTED_BY_CLEARINGHOUSE",
  "DENIED_PENDING_PROVIDER_REVIEW",
  "DENIED",
  "PARTIALLY_DENIED",
  "PATIENT_BALANCE",
  "APPROVED"
];

const PAYMENT_METHODS: PaymentMethod[] = [
  METRICS.CASH as PaymentMethod,
  METRICS.CHECK as PaymentMethod,
  METRICS.CREDIT_CARD as PaymentMethod
];

export {
  LAYOUTS,
  PAGES,
  STYLES,
  PRODUCT_OFFERINGS,
  GRID_METRICS,
  PUBLIC_LAYOUTS,
  METRIC_LABELS,
  STATUS_TYPES,
  ICON_KEYS,
  STATUS_KEYS,
  METRICS,
  PATIENTS_TABS,
  APP_KEYS,
  FLOW_KEYS,
  PATIENT_VIEWS,
  PATIENTS_VIEWS,
  BACKEND_ROUTES,
  ANNOTATION_TYPES,
  ANNOTATION_LABELS,
  PROVIDER_DASH_TABS,
  INPUT_TYPES,
  APPOINTMENT_STATUS_TYPES,
  CALENDAR_VIEW,
  SCHEDULE_VIEWS,
  PATIENT_STATUS,
  DASH_CONFIG,
  TAG_LABELS,
  TAG_COLOR_MAP,
  USER_ROLES,
  MOBILE_VIEWS,
  PATIENT_TYPES,
  STATE_CODES,
  ALLERGY_SEVERITITES,
  BLOOD_TYPES,
  PREGNANCY_OUTCOMES,
  BILL_RECIPIENTS,
  ORDER_URGENCY,
  EVENT_COLOR_MAP,
  CHART_STATUS_MAP,
  VISIT_LOCATIONS,
  METRIC_LABEL_OVERRIDES,
  CLM_TABS,
  CLAIM_OUTCOMES,
  CLAIM_STATUS_TYPES,
  CLAIM_STATUSES,
  TASK_STATUS_TYPES,
  CLAIM_PROVIDER_LABEL_OVERRIDES,
  CLAIM_PROVIDER_STATUS_TYPES_OVERRIDES,
  PAYMENT_METHODS,
  ENCOUNTER_STATUS_TYPES
};
