import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

// components
import Button from "@/components/button";
import ContentRenderer from "@/components/textArea/contentRenderer";

// store
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";
import {
  useTaskCompleteMutation,
  useTaskInfoQuery
} from "@/store/services/task";
import { setModalIsOpen } from "../modalSlice";
import { RootState } from "@/store/store";

// styles
import styles from "../styles.module.scss";
interface GenericTaskModalProps {
  taskId: number;
  assigneeType: "staff" | "patient";
}

export default function GenericTaskModal({
  taskId,
  assigneeType
}: GenericTaskModalProps) {
  const dispatch = useDispatch();

  const [completeTask] = useTaskCompleteMutation();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: task, isSuccess: isTaskSuccess } = useTaskInfoQuery({
    taskId: taskId
  });

  // show the complete button for incomplete tasks assigned to the current user
  // or assigned to patients
  let showCompleteButton = true;
  if (task?.completed_at) {
    // If the task is already completed, don't show the complete button
    showCompleteButton = false;
  } else if (
    // If the task is assigned to a staff member and the current user is not the assignee,
    // don't show the complete button
    assigneeType === "staff" &&
    task?.assignee.user_id !== sessionInfo?.user_id
  ) {
    showCompleteButton = false;
  }

  const onSubmit = () => {
    completeTask({
      taskId: taskId
    });
    dispatch(setRightPaneOpen(false));
    dispatch(setModalIsOpen(false));
  };
  return (
    <div className={styles.TaskCompletionModal}>
      {isTaskSuccess ? (
        <>
          <h3>{task.generic?.task_title}</h3>
          <ContentRenderer content={task.generic?.task_text || ""} />
          {showCompleteButton && (
            <div className={styles.buttonContainer}>
              <Button type="button" onClick={onSubmit}>
                Complete
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
        </>
      )}
    </div>
  );
}
