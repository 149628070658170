/* EmptyState Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import CloudOff from "../../../../public/svgs/cloud-off.svg";

// constants

// store

// styles
import styles from "./styles.module.scss";

/* EmptyState Typescript Interface */
interface emptyStateProps {
  customMessage?: string;
  noMargin?: boolean;
  containerTestId?: string;
}

export default function EmptyState({
  customMessage,
  noMargin,
  containerTestId
}: emptyStateProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.EmptyState, { [styles.noMargin]: noMargin })}
      data-cy="empty-state"
      data-testid={containerTestId}
    >
      <div className={styles.icon}>
        <CloudOff width={20} height={20} stroke={styles.gray400} />
      </div>
      {customMessage || " No entries recorded yet"}
    </div>
  );
}
