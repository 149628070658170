/* LabFlowsheetFiles Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import DashboardCard from "@/components/cards/dashboardCard";
import LabFlowsheetFileViewer from "./labFlowsheetFileViewer";

// constants

// store

import {
  LabFlowsheetId,
  LabFlowsheetResultsByDay,
  LabFlowsheetTemplateId
} from "@/store/services/lab";
import { UserId } from "@/store/services/patient";

// styles
import styles from "./styles.module.scss";

/* LabFlowsheetFiles Typescript Interface */
interface LabFlowsheetFilesProps {
  dayResults?: LabFlowsheetResultsByDay;
  labFlowsheetId?: LabFlowsheetId;
  activeFlowsheetTemplateId: LabFlowsheetTemplateId;
  patientId: UserId;
}

export default function LabFlowsheetFiles({
  dayResults,
  labFlowsheetId,
  activeFlowsheetTemplateId,
  patientId
}: LabFlowsheetFilesProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.LabFlowsheetFiles)}>
      <DashboardCard title="Lab Result Files" icon="file-attachment-04">
        <div className={styles.fileContentWrapper}>
          <LabFlowsheetFileViewer
            labFlowsheetId={labFlowsheetId}
            activeFlowsheetTemplateId={activeFlowsheetTemplateId}
            attachedFileIds={dayResults?.file_ids}
            patientId={patientId}
          />
        </div>
      </DashboardCard>
    </div>
  );
}
