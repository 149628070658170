/* FileList Name */
/* External Imports */
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";

/* Local Imports */

// components
import SelectFile from "./selectFile";

// constants

// store
import { FileId, FileListItem } from "@/store/services/file";
// styles
import styles from "./styles.module.scss";

/* FileList Typescript Interface */
interface FileListProps {
  files: FileListItem[];
  selectedFileId?: FileId;
  onClick: (file: FileListItem) => void;
  onActionClick: (file: FileListItem) => void;
  isAdd?: boolean;
  isRemove?: boolean;
}

export default function FileList({
  files,
  selectedFileId,
  onClick,
  onActionClick,
  isAdd,
  isRemove
}: FileListProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleFileClick = (file: FileListItem) => {
    onClick(file);
  };

  const handleActionClick = (file: FileListItem) => {
    onActionClick(file);
  };

  return (
    <div className={clsx(styles.FileList)}>
      {files?.map(file => (
        <div
          className={styles.fileWrapper}
          key={file.file_id}
          data-testid={`file-wrapper-${file.file_id}`}
        >
          <SelectFile
            file={file}
            onClick={handleFileClick}
            isSelected={selectedFileId === file.file_id}
          />
          <AnimatePresence mode="wait">
            {selectedFileId === file.file_id && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{
                  duration: 0.2,
                  height: {
                    duration: 0.2
                  }
                }}
              >
                <button
                  className={clsx(styles.actionBtn, {
                    [styles.add]: isAdd,
                    [styles.remove]: isRemove
                  })}
                  type="button"
                  onClick={e => handleActionClick(file)}
                >
                  {isAdd && "Add File"}
                  {isRemove && "Remove File"}
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
}
