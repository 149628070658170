import clsx from "clsx";
import styles from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import Input from "@/components/input";
import TextArea from "@/components/textArea";

export default function PostpartumProgressUpdate() {
  const form = useForm();
  const { control } = form;

  const temp_vitals = [
    {
      label: "Heart rate",
      id: "heart_rate"
    },
    {
      label: "Blood pressure",
      id: "blood_pressure"
    },
    {
      label: "Temperature",
      id: "temperate"
    },
    {
      label: "Temperature Location",
      id: "temperate_location"
    },
    {
      label: "Maternal Position",
      id: "maternal_position"
    },
    {
      label: "Fundus",
      id: "fundus"
    },
    {
      label: "Lochia",
      id: "lochia"
    },
    {
      label: "Intake",
      id: "intake"
    },
    {
      label: "Output",
      id: "output"
    }
  ];

  const maternal_medication = [
    {
      label: "Recipient",
      id: "recipient"
    },
    {
      label: "Name",
      id: "medication_name"
    },
    {
      label: "Dose",
      id: "medication_dose"
    },
    {
      label: "Units",
      id: "units"
    },
    {
      label: "Route",
      id: "route"
    },
    {
      label: "Site Given",
      id: "site_given"
    },
    {
      label: "Lot Number",
      id: "medication_lot_number"
    },
    {
      label: "Expiration",
      id: "medication_expiration"
    },
    {
      label: "Indication",
      id: "medication_indication"
    }
  ];

  const infant_vitals = [
    {
      label: "Heart Rate",
      id: "heart_rate"
    },
    {
      label: "Respirations",
      id: "respirations"
    },
    {
      label: "Temperature",
      id: "temperature"
    },
    {
      label: "Temperature Location",
      id: "temperate_location"
    },
    {
      label: "Pulse Ox",
      id: "pulse_ox"
    },
    {
      label: "Color",
      id: "color"
    },
    {
      label: "Nose Flaring",
      id: "nose_flaring"
    },
    {
      label: "Grunting",
      id: "grunting"
    },
    {
      label: "Retractions",
      id: "retractions"
    },
    {
      label: "Void",
      id: "void"
    },
    {
      label: "Meconium",
      id: "meconium"
    }
  ];

  return (
    <form className={styles.ProgressUpdateForm}>
      <div className={clsx(styles.spanTwo, styles.columnContainer)}>
        <fieldset className={clsx(styles.bgLight)}>
          <legend>Entry Information</legend>
          <div className={styles.inputs}>
            <div>
              {/* NOTE: I'm using the native datetime picker because we're migrating
              away from pTime anyway and this is a much better option than what we had before */}
              <Input
                type="datetime-local"
                label="Entry time"
                id="time"
                name="time"
              />
            </div>
            <Input
              type="text"
              label="Author"
              placeholder="Author"
              name="author"
              id="author"
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Medication</legend>
          <div className={styles.inputs}>
            {maternal_medication.map(item => (
              <Input
                label={item.label as string}
                type="text"
                id={item.id}
                key={item.id}
                name={item.id}
                placeholder={item.label}
              />
            ))}
          </div>
        </fieldset>
      </div>
      <div className={clsx(styles.spanFour, styles.columnContainer)}>
        <fieldset>
          <legend>Maternal Vitals & Assessment</legend>
          <div className={styles.inputs}>
            {temp_vitals.map(item => (
              <Input
                label={item.label as string}
                type="text"
                key={item.id}
                id={item.id}
                name={item.id}
                placeholder={item.label}
              />
            ))}
          </div>
        </fieldset>
        <fieldset>
          <legend>Infant Vitals & Assessment</legend>
          <div className={clsx(styles.inputs, styles.fitRow)}>
            {infant_vitals.map(item => (
              <Input
                label={item.label as string}
                type="text"
                id={item.id}
                key={item.id}
                name={item.id}
                placeholder={item.label}
              />
            ))}
          </div>
        </fieldset>
      </div>

      <fieldset className={styles.spanSix}>
        <legend>Notes / Comments</legend>
        <TextArea label="Notes" hiddenLabel name="notes" id="notes" rows={4} />
      </fieldset>
    </form>
  );
}
