import { StylesConfig, Theme } from "react-select";

export function SelectDefaultTheme(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      primary: "rgba(72, 110, 131, 1)",
      primary75: "#5b8094",
      primary50: "#7398ab",
      primary25: "#8dadbe"
    }
  } as Theme;
}

export const SelectDefaultStyles: StylesConfig = {
  container: styles => ({
    ...styles,
    width: "100%"
  }),
  control: styles => ({
    ...styles,
    fontSize: "14px",
    minHeight: "36px",
    height: "36px"
  }),
  option: styles => ({
    ...styles,
    fontSize: "14px"
  })
};

export function SelectCompactTheme(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: 8,
    spacing: {
      baseUnit: 2,
      menuGutter: 0
    },
    colors: {
      ...theme.colors,
      primary: "rgba(72, 110, 131, 1)",
      primary75: "#5b8094",
      primary50: "#7398ab",
      primary25: "#8dadbe"
    }
  } as Theme;
}

export const SelectCompactStyles: StylesConfig = {
  container: styles => ({
    ...styles,
    width: "100%"
  }),
  control: styles => ({
    ...styles,
    fontSize: "12px",
    minHeight: "12px",
    borderRadius: 4,
    // hover
    ":hover": {
      backgroundColor: "rgba(72, 110, 131, 0.1)"
    },
    // focus
    ":focus": {
      backgroundColor: "rgba(72, 110, 131, 0.1)",
      borderColor: "transparent", // Add this to keep border transparent on focus
      boxShadow: "none" // Add this to ensure no focus ring appears
    }
  }),
  option: styles => ({
    ...styles,
    fontSize: "12px"
  }),
  menu: styles => ({
    ...styles,
    fontSize: "12px",
    borderRadius: 4
  }),
  dropdownIndicator: styles => ({
    ...styles,
    width: "14px",
    padding: 0
  }),
  clearIndicator: styles => ({
    ...styles,
    width: "14px",
    padding: 0
  }),
  indicatorSeparator: styles => ({
    ...styles,
    height: "14px"
  })
};
