import { createSlice } from "@reduxjs/toolkit";
import { ConversationListItem } from "@/store/services/generated/conversation";

type initialState = {
  selectedConversationIds: ConversationListItem["conversation_id"][];
};

const initialState: initialState = {
  selectedConversationIds: []
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addSelectedConversationId: (state, { payload }) => {
      state.selectedConversationIds =
        state.selectedConversationIds.concat(payload);
    },
    removeSelectedConversationId: (state, { payload }) => {
      state.selectedConversationIds = state.selectedConversationIds.filter(
        id => id !== payload
      );
    },
    setSelectedConversationIds: (state, { payload }) => {
      state.selectedConversationIds = payload;
    }
  }
});

export const {
  addSelectedConversationId,
  removeSelectedConversationId,
  setSelectedConversationIds
} = conversationSlice.actions;

export default conversationSlice.reducer;
