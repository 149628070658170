/* SelectFile Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Icon from "@/components/icons";

// constants

// store
import { FileListItem } from "@/store/services/file";

// styles
import styles from "./styles.module.scss";

// utils
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

/* SelectFile Typescript Interface */
interface SelectFileProps {
  file: FileListItem;
  onClick: (file: FileListItem) => void;
  isSelected: boolean;
}

export default function SelectFile({
  file,
  onClick,
  isSelected
}: SelectFileProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleFileClick = () => {
    onClick(file);
  };

  return (
    <div
      className={clsx(styles.SelectFile, { [styles.selected]: isSelected })}
      onClick={handleFileClick}
    >
      <div
        data-testid="check-icon"
        className={clsx(styles.checkIcon, { [styles.visible]: isSelected })}
      >
        <Icon svg="check_success_outline" />
      </div>
      <div className={styles.iconBackground}>
        <Icon svg="file-06" width={20} height={20} />
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.fileName}>{file.name}</p>
        <p className="t5 gray400">
          {convertUtcIntToLocalDatetime(file.created).format("hh:mm a M/D/YY")}
        </p>
      </div>
    </div>
  );
}
