/* standardFlow Name */
/* External Imports */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

/* Local Imports */

// components
import PatientHeader from "@/components/patientHeader";
import Note from "../_sections/note";
import AssessmentPlan from "../_sections/assessmentPlan";
import Alert from "@/components/alert";
import Button from "@/components/button";
import DashboardCard from "@/components/cards/dashboardCard";
import PrenatalFlowSheet from "@/components/prenatalFlowSheet";
import Certification from "@/components/certification";
import LaborFlow from "../labor";
import RelatedEncounterTab from "./relatedEncounterTab";
import Icon from "@/components/icons";
import Vitals from "../_sections/vitals";
import SubmittedStamp from "./submittedStamp";
import FetalGrowthFlowSheet from "@/components/fetalGrowthFlowSheet";
import NewbornForm from "@/components/forms/_labor/newbornExam/newborn";
import Copilot from "@/components/copilot";
import LaborFlowV2 from "@/components/laborv2"

// icons
import Edit from "../../../../public/svgs/edit_simple.svg";
import CalCheck from "../../../../public/svgs/calendar-check.svg";
import Delete from "../../../../public/svgs/delete_dynamic.svg";

// constants
import {
  STATUS_TYPES,
  STYLES,
  STATUS_KEYS,
  PAGES,
  METRIC_LABELS,
  METRICS,
  PATIENT_VIEWS
} from "@/globals/constants";

import { FLOW_KEYS } from "@/components/flows/constants";
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// store
import { useAppointmentUpsertMutation } from "@/store/services/scheduling";
import {
  usePracticeListAppointmentTypesQuery,
  AppointmentTypeDetails
} from "@/store/services/practice";
import {
  EncounterInfo,
  EncounterType,
  useEncounterSubmitMutation,
  useEncounterUpdateMutation,
  useEncounterCreateMutation,
  EncounterLocation,
  AltLocation,
  useLazyEncounterInfoQuery,
  useLazyEncounterExportQuery
} from "@/store/services/encounter";
import { useLocationGetListQuery } from "@/store/services/location";
import { UserId, usePatientGetInfoQuery } from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { RootState } from "@/store/store";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

// helpers
import { FORMAT, apptLocationToUpsert } from "@/globals/helpers/formatters";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
import {
  setLeftPaneContent,
  setLeftPaneOpen,
  setRightPaneOpen
} from "@/components/drawer/drawerSlice";
import { DRAWER_TYPES } from "@/components/drawer/dispatcher";

// utils
import {
  useCustomPracticeLocations,
  useFilteredAppointmentTypes
} from "@/globals/helpers/customHooks";
import { openPdfFromBase64 } from "@/components/labOrdering/utils";

// styles
import styles from "@/components/flows/standardFlow/styles.module.scss";
import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";

/* Well Woman Typescript Interface */
interface standardFlowProps {
  encounter: EncounterInfo;
  readOnly?: boolean;
  isPetrified?: boolean; // this is for claim state
}

export default function StandardFlow({
  encounter,
  readOnly,
  isPetrified
}: standardFlowProps) {
  const router = useRouter();

  // Redux
  const dispatch = useDispatch();
  const leftPaneOpen = useSelector(
    (state: RootState) => state.drawer.leftPaneOpen
  );

  // API calls: queries
  const { data: appointmentTypes } = usePracticeListAppointmentTypesQuery();
  const { data: patient } = usePatientGetInfoQuery({
    patientId: encounter.patient.user_id
  });
  const { data: features } = useGetFeatureFlagsQuery();
  const { data: parent } = usePatientGetInfoQuery(
    {
      patientId: patient?.parent_ids?.[0] as number
    },
    { skip: !(patient?.parent_ids && patient?.parent_ids?.length > 0) }
  );
  const customPracticeLocations = useCustomPracticeLocations();
  const locationOptions = customPracticeLocations.map(location => ({
    value: typeof location === "object" ? location.location_id : location,
    label: typeof location === "object" ? location.name : location
  }));

  // Filter appointment types available in encounter based on patient type
  const [apptTypeOptions] = useFilteredAppointmentTypes(patient);

  // API calls: mutations
  const [exportEncounter] = useLazyEncounterExportQuery();
  const [createEncounter] = useEncounterCreateMutation();
  const [updateEncounter, updateEncounterRequest] =
    useEncounterUpdateMutation();
  const [submitEncounter, submitEncounterRequest] =
    useEncounterSubmitMutation();
  const [getRelatedEncounter] = useLazyEncounterInfoQuery();
  const [updateAppointment] = useAppointmentUpsertMutation();

  // Local state
  const [isEditable, setIsEditable] = useState<boolean>();
  const [showSnapshot] = useState(!readOnly);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [certified, setCertified] = useState<boolean>(
    encounter.status !== "IN_PROGRESS"
  ); // if encounter has been previously submitted, default checkbox to checked

  // React Hook Form
  type FormValues = {
    select_appt_type: string;
    select_appt_location: EncounterLocation;
  };
  const defaultValues: Partial<FormValues> = {
    select_appt_type:
      apptTypeOptions.find(
        v =>
          v.value?.appointment_type ==
          encounter.appointment_type?.appointment_type
      )?.value?.appointment_type || appointmentTypes?.[0]?.appointment_type,
    select_appt_location:
      // Default to the location on the appointment
      (typeof encounter?.appointment?.appointment_location === "object"
        ? encounter?.appointment?.appointment_location?.location_id
        : encounter?.appointment?.appointment_location) || encounter.location
  };

  const {
    control,
    formState: { errors },
    watch,
    setValue
  } = useForm({ defaultValues });

  const visitType = watch("select_appt_type");
  const visitLocation = watch("select_appt_location");

  // Effects
  useEffect(() => {
    // Update the appt type and location when the encounter changes
    setValue(
      "select_appt_type",
      encounter?.appointment?.appointment_type?.appointment_type ||
        encounter?.appointment_type?.appointment_type
    );
    setValue("select_appt_location", encounter.location);
  }, [encounter]);

  useEffect(() => {
    if (readOnly || isPetrified) {
      setIsEditable(false);
    } else {
      setIsEditable(encounter.status === "IN_PROGRESS");
    }
  }, [encounter.status, readOnly, isPetrified]);

  useEffect(() => {
    if (updateEncounterRequest.isSuccess) {
      // TODO: render success toast
    } else if (updateEncounterRequest.isError) {
      // TODO: render error toast
    }
  }, [updateEncounterRequest]);

  useEffect(() => {
    if (submitEncounterRequest.isSuccess) {
      // TODO: render success toast
    } else if (submitEncounterRequest.isError) {
      // TODO: render error toast
    }
  }, [submitEncounterRequest]);

  useEffect(() => {
    // update encounter certification when toggling to new encounter
    setCertified(encounter.status !== "IN_PROGRESS");
    setValue("select_appt_location", encounter.location);
  }, [encounter.encounter_id]);

  useEffect(() => {
    if (certified && visitType && visitLocation) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [certified, visitLocation, visitType]);

  // If this is a prenatal encounter, but it's not associated with a pregnancy,
  // prompt the user to link it to a pregnancy
  useEffect(() => {
    if (!encounter.encounter_type || encounter.pregnancy_id) {
      return;
    }

    if (!["POSTPARTUM", "PRENATAL"].includes(encounter.encounter_type)) {
      return;
    }

    const formattedEncounterType =
      encounter.encounter_type[0].toUpperCase() +
      encounter.encounter_type.toLowerCase().slice(1);
    const title = `${formattedEncounterType} Encounter`;

    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.ARE_YOU_PRENATAL_CHARTING,
        props: {
          title,
          encounterId: encounter.encounter_id
        }
      })
    );
  }, [
    encounter.encounter_type,
    encounter.pregnancy_id,
    encounter.encounter_id
  ]);

  const handleSubmitNewbornExam = (formData: any) => {
    const newbornExam = formData;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: {
        provider_id: encounter.provider.user_id,
        patient_id: encounter.patient.user_id,
        encounter_type: encounter.encounter_type as EncounterType,
        type_specific: { newborn_exam: newbornExam }
      }
    })
      .unwrap()
      .then(() => {
        launchToast(
          "Newborn exam saved",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
      })
      .catch(() => {
        launchToast(
          "Oops! Newborn exam could not be saved",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      });
  };

  // On first render, open the left pane with the facepage
  useEffect(() => {
    if (!patient || !patient.user_id) {
      dispatch(setLeftPaneOpen(false)); // close the left pane if there is no patient
      return;
    }
    dispatch(setLeftPaneOpen(true));
    dispatch(
      setLeftPaneContent({
        type: DRAWER_TYPES.FACEPAGE,
        props: {
          patient,
          view: PATIENT_VIEWS.DASH
        }
      })
    );

    return () => {
      dispatch(setLeftPaneOpen(false));
    };
  }, [patient?.user_id]); // stop the effect from running on every render in labor flow

  // Callbacks
  const handleVisitTypeSelect = (item: AppointmentTypeDetails) => {
    if (
      item.encounter_type !== encounter.encounter_type ||
      item.appointment_type !== encounter.appointment_type?.appointment_type
    ) {
      updateEncounter({
        encounterId: encounter.encounter_id,
        encounterCreateUpdatePayload: {
          encounter_type: item.encounter_type,
          appointment_type_id: item.appointment_type_id,
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          location: encounter.location
        }
      })
        .unwrap()
        .then(() => {
          // BANDAID: this is a temporary fix to ensure appointment type is updated when visit type is changed
          // if there is an appointment associated with the encounter, update the appointment type
          if (encounter.appointment) {
            updateAppointment({
              appointmentUpsertRequest: {
                id: encounter.appointment.appointment_id,
                patient_id: encounter.patient.user_id,
                provider_id: encounter.provider.user_id,
                starts: encounter.appointment.starts,
                ends: encounter.appointment.ends,
                // make sure to update the appointment type and location to match
                appointment_type_id: item.appointment_type_id,
                appointment_location: apptLocationToUpsert(
                  encounter.appointment.appointment_location
                )
              }
            })
              .unwrap()
              .then(() => {
                console.info("Appointment type updated");
              })
              .catch(() => {
                console.error("Appointment type could not be updated");
              });
          }
          launchToast(
            `Visit type updated`,
            STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
          );
        })
        .catch(() =>
          launchToast(
            `Oops! Visit type could not be updated`,
            STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
          )
        );
    }
  };

  const handleSelectLocation = (setting: AltLocation | EncounterLocation) => {
    if (setting !== encounter.location) {
      updateEncounter({
        encounterId: encounter.encounter_id,

        encounterCreateUpdatePayload: {
          encounter_type: encounter.encounter_type as EncounterType,
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          location: setting
        }
      })
        .unwrap()
        .then(() => {
          launchToast(
            `Visit location updated`,
            STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
          );
          // updated location of related encounters
          const relatedEncounter = encounter.metadata.related;
          if (relatedEncounter && relatedEncounter.length > 0) {
            relatedEncounter.forEach(encounterId => {
              // first get the encounter using the lazy encounter info query
              getRelatedEncounter({ encounterId })
                .unwrap()
                .then(relatedEncounter => {
                  // then update the location if the encounter is not submitted
                  if (relatedEncounter.status === "IN_PROGRESS") {
                    updateEncounter({
                      encounterId: encounterId,
                      encounterCreateUpdatePayload: {
                        encounter_type:
                          relatedEncounter.encounter_type as EncounterType,
                        patient_id: relatedEncounter.patient.user_id,
                        provider_id: relatedEncounter.provider.user_id,
                        location: setting
                      }
                    });
                  }
                })
                .catch(() =>
                  launchToast(
                    `Related visit location could not be updated`,
                    STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
                  )
                );
            });
          }
        })
        .catch(() =>
          launchToast(
            `Oops! Visit location could not be updated`,
            STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
          )
        );
    }
  };

  const handleEditEncounterTime = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.EDIT_ENCOUNTER_DETAILS,
        props: { encounter, title: "Edit Encounter Details" }
      })
    );
  };

  const handleSubmit = () => {
    // encounter will always be defined when this is invoked

    submitEncounter({
      encounterId: encounter?.encounter_id as number,
      version: encounter?.version as number
    })
      .unwrap()
      .then(() => {
        launchToast(
          "Encounter submitted and superbill generated",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        updateAppointment({
          appointmentUpsertRequest: {
            id: encounter.appointment?.appointment_id,
            appointment_type_id: encounter.appointment?.appointment_type
              ?.appointment_type_id as string,
            patient_id: encounter.patient.user_id,
            provider_id: encounter.provider.user_id,
            appointment_location: apptLocationToUpsert(
              encounter.appointment?.appointment_location
            ),
            starts: encounter.appointment?.starts as number,
            ends: encounter.appointment?.ends as number,
            status: "COMPLETED"
          }
        });
      })
      .catch(() => {
        launchToast(
          "Oops! Encounter could not be submitted", // TODO: detailed user facing errors when backend supports it
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      });
  };

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };
  // Mark appointment complete
  const handleCompleteAppointment = async () => {
    try {
      // update appointment status
      await updateAppointment({
        appointmentUpsertRequest: {
          id: encounter.appointment?.appointment_id,
          appointment_type_id: encounter.appointment?.appointment_type
            .appointment_type_id as string,
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          appointment_location: apptLocationToUpsert(
            encounter.appointment?.appointment_location
          ),
          starts: encounter.appointment?.starts as number,
          ends: encounter.appointment?.ends as number,
          status: "COMPLETED"
        }
      });

      // user alert
      launchToast(
        `Appointment completed successfully.`,
        STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
      );
    } catch (error) {
      launchToast(
        `An error occurred. Appointment was not able to be marked completed.`,
        STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
      );
    }
  };

  const handleCreateAssociatedEncounter = (
    userId: UserId,
    newVisitType: EncounterType,
    existingVisitType: EncounterType
  ) => {
    // create infant encounter
    createEncounter({
      patientId: userId,
      providerId: encounter.provider.user_id,
      encounterCreateUpdatePayload: {
        related: [encounter.encounter_id], // associate this parent's encounter with baby's encounter
        encounter_type: newVisitType,
        patient_id: userId,
        provider_id: encounter.provider.user_id
      }
    })
      .unwrap()
      .then(encounterId => {
        launchToast(
          `${METRIC_LABELS[newVisitType]} encounter created`,
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        // then associate it
        // then route to that encounter
        const relatedEncounters = encounter.metadata.related;
        let newRelatedEncounters;
        if (relatedEncounters && relatedEncounters.length > 0) {
          newRelatedEncounters = [...relatedEncounters, encounterId];
        } else {
          newRelatedEncounters = [encounterId];
        }
        updateEncounter({
          encounterId: encounter.encounter_id,
          encounterCreateUpdatePayload: {
            related: newRelatedEncounters,
            encounter_type: existingVisitType,
            patient_id: encounter.patient.user_id,
            provider_id: encounter.provider.user_id
          }
        })
          .unwrap()
          .then(() => {
            launchToast(
              `${METRIC_LABELS[newVisitType]} encounter associated with postpartum encounter`,
              STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
            );
          })
          .catch(() =>
            launchToast(
              `Oops! ${METRIC_LABELS[newVisitType]} encounter could not be associated with postpartum encounter`,
              STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
            )
          );
      })
      .catch(() =>
        launchToast(
          `Oops! ${METRIC_LABELS[newVisitType]} encounter could not be created`,
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        )
      );
  };

  const handleReopenEncounter = () => {
    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: {
        provider_id: encounter.provider.user_id,
        patient_id: encounter.patient.user_id,
        encounter_type: encounter.encounter_type as EncounterType,
        status: "IN_PROGRESS"
      }
    })
      .unwrap()
      .then(() => {
        launchToast(
          "Encounter reopened",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        setCertified(false);
      })
      .catch(() =>
        launchToast(
          "Oops! Encounter could not be reopened",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        )
      );
  };

  const handlePrintNote = () => {
    exportEncounter({ encounterId: encounter.encounter_id })
      .unwrap()
      .then(res => {
        openPdfFromBase64(res.content);
      })
      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Failed to export",
            type: STATUS_KEYS.ERROR
          })
        )
      );
  };
  return (
    <div className={clsx(styles.standardFlow)}>
      {/* <Copilot encounter={encounter} /> */}
      {!readOnly && (
        <div className="padded-x">
          <PatientHeader patient={encounter.patient} />
        </div>
      )}
      <div
        className={clsx(styles.slide, {
          [styles.LeftPaneOpen]: !readOnly && leftPaneOpen
        })}
        data-cy="encounter-header"
      >
        {!readOnly && (
          <div className={styles.visit}>
            <div className={styles.dropdowns}>
              <div id="visit_location_wrapper" data-cy="visit-type">
                <Controller
                  name="select_appt_type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <label className="standardLabel">Visit Type</label>
                      <Select
                        theme={SelectDefaultTheme}
                        options={apptTypeOptions}
                        onChange={v => {
                          if (!v || !v.value) {
                            onChange(undefined);
                            return;
                          }

                          onChange(v.value?.appointment_type);
                          handleVisitTypeSelect(v.value);
                        }}
                        id="appt-type-select"
                        isDisabled={!isEditable}
                        value={{
                          value: apptTypeOptions.find(
                            opt => opt.value.appointment_type === value
                          )?.value,
                          label: (value && METRIC_LABELS[value]) || value
                        }}
                      />
                    </>
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div
                className="capitalize"
                id="visit_location_wrapper"
                data-cy="visit-location"
              >
                <Controller
                  name="select_appt_location"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <label className="standardLabel">Visit Location</label>
                      <Select
                        theme={SelectDefaultTheme}
                        options={locationOptions}
                        value={locationOptions.find(v => v.value === value)}
                        onChange={v => {
                          if (v?.value) {
                            onChange(v.value);
                            handleSelectLocation(v.value);
                          }
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>

            <div className={styles.date}>
              {encounter.provider && (
                <>
                  <p data-cy="clinician">
                    Clinician: {FORMAT.name(encounter.provider)}
                  </p>
                  <span className={styles.dot} />
                </>
              )}
              <p data-cy="encounter-start">
                {convertUtcIntToLocalDatetime(
                  encounter.start ||
                    encounter.appointment?.starts ||
                    encounter.created
                ).format("MM/DD/YYYY")}{" "}
                {convertUtcIntToLocalDatetime(
                  encounter.start ||
                    encounter.appointment?.starts ||
                    encounter.created
                ).format("h:mm a")}
              </p>
              {!readOnly && isEditable && (
                <Button style={STYLES.ICON}>
                  <Edit
                    onClick={handleEditEncounterTime}
                    stroke={styles.gray500}
                    width={16}
                    height={16}
                  />
                </Button>
              )}
            </div>
          </div>
        )}
        <div className={styles.flowWrapper}>
          <Alert
            message="Select visit type to begin session."
            type={STATUS_TYPES[STATUS_KEYS.WARNING]}
            isHidden={!!encounter.encounter_type}
          />
          <div
            className={clsx(styles.visitHeader, {
              [styles.showSnapshot]: showSnapshot
            })}
          >
            <div className={styles.left}>
              {FLOW_KEYS.INFANT ===
                (encounter.encounter_type as EncounterType) &&
                encounter.metadata.related &&
                encounter.metadata.related.map(relatedEncounterId => (
                  <RelatedEncounterTab
                    key={relatedEncounterId}
                    onClick={() =>
                      router.push(`${PAGES.ENCOUNTERS}/${relatedEncounterId}`)
                    }
                    title={`${
                      parent ? FORMAT.name(parent) : "Parent's"
                    }'s Postpartum Visit`}
                    encounterId={relatedEncounterId}
                    isRelated
                  />
                ))}
              {
                <RelatedEncounterTab
                  title={`${FORMAT.name(encounter?.patient)}'s ${
                    encounter.encounter_type === FLOW_KEYS.LABOR
                      ? "Delivery"
                      : (METRIC_LABELS[
                          encounter.appointment_type
                            ?.appointment_type as keyof typeof METRIC_LABELS
                        ] ||
                          encounter.appointment_type?.appointment_type ||
                          "") + " Visit"
                  }`}
                  encounterId={encounter.encounter_id}
                />
              }
              {FLOW_KEYS.POSTPARTUM ===
                (encounter.encounter_type as EncounterType) &&
                encounter.metadata.related &&
                encounter.metadata.related.map(relatedEncounterId => (
                  <RelatedEncounterTab
                    key={relatedEncounterId}
                    onClick={() =>
                      router.push(`${PAGES.ENCOUNTERS}/${relatedEncounterId}`)
                    }
                    title={`${
                      patient?.children?.[0]
                        ? FORMAT.name(patient?.children?.[0])
                        : "Baby"
                    }'s Infant Visit`}
                    encounterId={relatedEncounterId}
                    isRelated
                  />
                ))}
            </div>
            {/* Create an associated infant visit */}
            {!isPetrified &&
              features?.infant_charting_enabled &&
              encounter.encounter_type === FLOW_KEYS.POSTPARTUM &&
              !encounter?.metadata.related?.length &&
              patient?.practice_data.type !== METRICS.INFANT &&
              patient?.children?.[0] && (
                <Button
                  key={patient.children[0]?.user_id}
                  style={STYLES.SECONDARY}
                  onClick={() =>
                    handleCreateAssociatedEncounter(
                      patient.children[0].user_id,
                      FLOW_KEYS.INFANT as EncounterType,
                      FLOW_KEYS.POSTPARTUM as EncounterType
                    )
                  }
                >
                  Create {FORMAT.name(patient.children[0])} Infant Visit
                </Button>
              )}
            {/* create an associated postpartum visit */}
            {features?.postpartum_charting_enabled &&
              encounter.encounter_type === FLOW_KEYS.INFANT &&
              !encounter?.metadata.related?.length &&
              patient?.practice_data.type === METRICS.INFANT &&
              parent && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={() =>
                    handleCreateAssociatedEncounter(
                      parent.user_id,
                      FLOW_KEYS.POSTPARTUM as EncounterType,
                      FLOW_KEYS.INFANT as EncounterType
                    )
                  }
                >
                  Create {FORMAT.name(parent)} Postpartum Visit
                </Button>
              )}
            {/* these buttons are only here when the encounter is displayed in the claims drawer */}
            {isPetrified && (
              <div className="flex">
                <Button
                  style={STYLES.SECONDARY}
                  onClick={() => {
                    dispatch(setRightPaneOpen(false));
                    router.push(
                      `${PAGES.PATIENTS}/${encodeURIComponent(
                        encounter.patient.user_id
                      )}`
                    );
                  }}
                >
                  <Icon svg={"user"} />
                  View Patient {"->"}
                </Button>
                <Button
                  style={STYLES.SECONDARY}
                  onClick={() => {
                    dispatch(setRightPaneOpen(false));
                    router.push(
                      `${PAGES.ENCOUNTERS}/${encodeURIComponent(
                        encounter.encounter_id
                      )}`
                    );
                  }}
                >
                  View Encounter {"->"}
                </Button>
              </div>
            )}
          </div>

          {features?.prenatal_charting_enabled &&
            encounter.encounter_type === FLOW_KEYS.PRENATAL &&
            encounter.patient.pregnancy &&
            encounter.patient.pregnancy.flow_sheet && (
              // Note that this means we need to create a pregnancy before we
              // attempt to render a prenatal encounter. As a part of appointment
              // start for a prenatal appointment, we should create a pregnancy
              // for a patient if one does not currently exist.
              <PrenatalFlowSheet
                patient={encounter.patient}
                pregnancy={encounter.patient.pregnancy}
                isReadOnly={!isEditable}
                encounterId={encounter.encounter_id}
              />
            )}
          {encounter.encounter_type === FLOW_KEYS.INFANT && (
            <>
              <FetalGrowthFlowSheet
                encounter={encounter}
                isReadOnly={!isEditable}
              />
              {patient && (
                <NewbornForm
                  onSubmit={handleSubmitNewbornExam}
                  patientId={patient.user_id}
                  disabled={!isEditable}
                  encounterId={encounter.encounter_id}
                  examType="ENCOUNTER"
                />
              )}
            </>
          )}
          <div className={clsx(styles.cardWrapper)}>
            {encounter.encounter_type === FLOW_KEYS.LABOR ? (
              features?.labor_v2_enabled ? (
                <LaborFlowV2 encounter={encounter} />
              ) : (
                <LaborFlow encounter={encounter} />
              )
            ) : (
              <div className={styles.accordions}>
                {/* In all encounter types */}
                {![FLOW_KEYS.PRENATAL, FLOW_KEYS.INFANT].includes(
                  encounter.encounter_type as string
                ) && (
                  <DashboardCard title="Vitals" isReadOnly={!isEditable}>
                    <Vitals
                      encounterId={encounter.encounter_id}
                      patientId={encounter.patient.user_id}
                      providerId={encounter.provider.user_id}
                      encounterType={encounter.encounter_type as EncounterType}
                      defaultValues={encounter.vitals}
                      isEditable={isEditable}
                    />
                  </DashboardCard>
                )}

                <DashboardCard title="Encounter Note" isReadOnly={!isEditable}>
                  <div className={styles.flowWrapper}>
                    <Note
                      encounter={encounter}
                      isEditable={isEditable}
                      isPetrified={isPetrified}
                    />
                  </div>
                </DashboardCard>
                <DashboardCard
                  title="Assessment & Plan"
                  isReadOnly={!isEditable}
                >
                  <AssessmentPlan
                    encounter={encounter}
                    patient={patient}
                    isEditable={isEditable}
                  />
                </DashboardCard>
                {!readOnly && (
                  <>
                    <div id="certification_wrapper">
                      <Certification
                        onCertify={(certified: boolean) => {
                          setCertified(certified);
                        }}
                        isChecked={certified}
                        encounterId={encounter.encounter_id}
                        isReadOnly={!isEditable}
                      />
                    </div>

                    {submitEnabled ? (
                      <div className="note">
                        <Icon svg="lightbulb" width={12} /> Visit type,
                        location, and certification are required for submission
                      </div>
                    ) : (
                      <div className="note warning">
                        <div className="flexCol">
                          {!visitLocation && (
                            <div className="flex">
                              <Icon svg="warning" width={20} />
                              <a className="tMd" href="#visit_location_wrapper">
                                Visit location
                              </a>
                              is required for submission
                            </div>
                          )}
                          {!visitType && (
                            <div className="flex">
                              <Icon svg="warning" width={20} />
                              <a className="tMd" href="#visit_location_wrapper">
                                Visit type
                              </a>
                              is required for submission
                            </div>
                          )}
                          {!certified && (
                            <div className="flex">
                              <Icon svg="warning" width={20} />
                              <a className="tMd" href="#certification_wrapper">
                                Authentication
                              </a>
                              is required for submission
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className={styles.actionWrapper}>
                      <div className={styles.submittedStamp}>
                        <Button
                          style={STYLES.DELETE}
                          onClick={() => {
                            dispatch(setModalIsOpen(true));
                            dispatch(
                              setModalContent({
                                type: MODAL_TYPES.CONFIRM_DELETE_ENCOUNTER,
                                props: {
                                  encounter,
                                  message:
                                    "Are you sure you want to delete this encounter and its contents?",
                                  title: "Confirm Delete Encounter"
                                }
                              })
                            );
                          }}
                        >
                          <Delete
                            stroke={styles.errorText}
                            width={15}
                            height={17}
                          />
                          Delete Encounter
                        </Button>
                        <Button
                          onClick={handleReopenEncounter}
                          style={STYLES.SECONDARY}
                          nativeButtonProps={{ disabled: isEditable }}
                        >
                          Reopen Encounter
                        </Button>
                        {encounter.status === "SUBMITTED" &&
                          encounter.submitted &&
                          encounter.submitted_by && (
                            <SubmittedStamp
                              user={encounter.submitted_by}
                              timestamp={encounter.submitted}
                            />
                          )}
                      </div>
                      {encounter.appointment &&
                        encounter.status !== "SUBMITTED" && (
                          <Button
                            style={STYLES.SECONDARY}
                            onClick={handleCompleteAppointment}
                            nativeButtonProps={{
                              disabled:
                                encounter.appointment.status === "CHECKED_OUT",
                              title: "Appointment already completed."
                            }}
                          >
                            <CalCheck
                              width={14}
                              height={14}
                              stroke={styles.blueIcon}
                            />
                            Mark Ready for Checkout
                          </Button>
                        )}

                      <Button
                        style={STYLES.SECONDARY}
                        onClick={() =>
                          router.push(
                            `${PAGES.ENCOUNTERS}/${encounter.encounter_id}${PAGES.SUPERBILL}`
                          )
                        }
                        nativeButtonProps={{
                          disabled: !encounter.submitted,
                          title: "Submit encounter to generate superbill"
                        }}
                      >
                        <Icon svg="file_highlighted" width={20} height={20} />
                        View Superbill
                      </Button>

                      <Button
                        style={STYLES.SECONDARY}
                        onClick={handlePrintNote}
                        type="button"
                      >
                        <Icon svg="printer" width={14} height={14} />
                        Export Note
                      </Button>

                      <Button
                        style={STYLES.PRIMARY}
                        onClick={handleSubmit}
                        nativeButtonProps={{
                          disabled: !submitEnabled || !isEditable
                        }}
                      >
                        {encounter.status === "SUBMITTED" && encounter.submitted
                          ? "Sign & Resubmit"
                          : "Sign & Submit"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
