/* Send File For Review Modal */

// External
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import Icon from "@/components/icons";

// globals, constants, helpers
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";

// store
import { setModalIsOpen } from "../../modalSlice";
import { PatientInfo, ProviderInfo } from "@/store/services/patient";
import { RootState } from "@/store/store";
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useTagsListQuery } from "@/store/services/tag";
import { useTaskCreateMutation } from "@/store/services/task";
import { FileListItem, useFileUpdateMutation } from "@/store/services/file";
import { addRemoveSelectedFileId } from "@/components/fileExplorer/fileExplorerSlice";

// styles
import styles from "../../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

export interface SendFilesForReviewProps {
  file: FileListItem;
  inbound_fax_id?: number;
}

export default function SendFilesForReview({
  file,
  inbound_fax_id
}: SendFilesForReviewProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [updateFile, updateFileReq] = useFileUpdateMutation();

  const [createTask, createTaskReq] = useTaskCreateMutation();

  const { data: users, isSuccess: gotUsers } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const form = useForm<{
    select_patient: PatientOption | null;
    select_provider: ProviderInfo | null;
    file_name: string;
    file_tags: TagOption[];
  }>({});

  const { data: availableTags } = useTagsListQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  const { handleSubmit, control, reset } = form;

  // Memoized values
  const providerOptions = useMemo(() => {
    // Add null checks
    if (!users?.providers) return [];

    const allUsers = users.providers.concat(users.medical_assistants || []);
    return allUsers
      .map(user => ({
        label: user ? FORMAT.name(user) : "", // Add null check
        value: user
      }))
      .filter(option => option.label); // Filter out empty labels
  }, [users]);

  type TagOption = {
    label: string;
    value: number;
  };

  const tagOptions: TagOption[] | undefined = useMemo(() => {
    if (!availableTags) return [];
    if (!availableTags.length) return [];
    return availableTags?.map(tag => ({
      label: tag.name,
      value: tag.tag_id
    }));
  }, [availableTags]);

  type PatientOption = {
    value: PatientInfo;
    label: string;
  };

  const patientOptions: PatientOption[] = useMemo(() => {
    if (!users?.patients) return [];

    return users.patients
      .filter(p => p) // Filter out any null/undefined patients
      .map(p => ({
        value: p,
        label: nameAndDOB(p)
      }));
  }, [users]);

  // populate the form with the selected file's name on open
  useMemo(() => {
    // file tags are in the shape of {tag_id: boolean}
    // so I have to do some really icky data transformations here to populate the default tags
    const fileTags = tagOptions?.filter(tag =>
      Object.keys(file.tags)?.includes(tag.value.toString())
    );
    reset({
      select_patient: null,
      select_provider: null,
      file_name: file.name,
      file_tags: fileTags
    });
  }, [file.name, file.tags, tagOptions]);

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const onSubmit = (data: any) => {
    // first creater the DOCUMENT_REVIEW assigned to the selected provider
    createTask({
      taskCreatePayload: {
        task_type: "DOCUMENT_REVIEW",
        staff_assignee: data.select_provider.user_id,
        associated_patient: data.select_patient.user_id,
        document_review: {
          file_id: file.file_id,
          fax_id: inbound_fax_id
        }
      }
    })
      .then(res => {
        // then update the file with new name, tags, and patient_id
        // this will also move the file to patient files
        updateFile({
          fileId: file.file_id,
          fileUpdateRequest: {
            name: data.file_name,
            tags: data.file_tags.map((tag: any) => tag.value),
            patient_id: data.select_patient.user_id
          }
        })
          .then(res => {
            dispatch(
              addAlertToToastTrough({
                message: "File sent for review",
                status: STATUS_KEYS.SUCCESS
              })
            );
            dispatch(addRemoveSelectedFileId(file.file_id));
            dispatch(setModalIsOpen(false));
          })
          .catch(err => {
            dispatch(
              addAlertToToastTrough({
                message: "Failed to send file for review",
                status: STATUS_KEYS.ERROR
              })
            );
          });
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to send file for review",
            status: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <form
      className={clsx(styles.SendFilesForReview)}
      onSubmit={handleSubmit(onSubmit)}
      data-cy="send-files-for-review"
    >
      {gotUsers ? (
        <>
          <div className={styles.message}>
            <div>
              <p>Please select a patient to copy the file to:</p>
              <Controller
                name="select_patient"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={patientOptions}
                    value={patientOptions?.find(
                      // @ts-ignore
                      item => item.value.user_id === value?.user_id
                    )}
                    onChange={(newValue: any) => onChange(newValue?.value)}
                    required
                    id="file_review_select_patient"
                  />
                )}
              />
            </div>
            <div>
              <p>Please select a provider to send file to for review:</p>

              <Controller
                name="select_provider"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={providerOptions}
                    // @ts-ignore
                    value={providerOptions?.find(
                      item => item.value.user_id === value?.user_id
                    )}
                    //@ts-ignore
                    onChange={newValue => onChange(newValue?.value)}
                    required
                    id="file_review_select_provider"
                  />
                )}
              />
            </div>
            <div>
              <p>
                Rename the file:<sup>*</sup>
              </p>

              <span className="t5">
                <sup>*</sup>File name must include a file extension (e.g. .pdf,
                .jpg)
              </span>
              <Controller
                name="file_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    name="file_name"
                    id="file_review_file_name"
                    label="Change file name"
                    hiddenLabel
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    required
                  />
                )}
              />
            </div>
            <div>
              <p>Tags:</p>
              <Controller
                name="file_tags"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    isMulti
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={tagOptions}
                    value={value}
                    onChange={onChange}
                    id="file_review_tags"
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <Button style={STYLES.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              style={STYLES.PRIMARY}
              type="submit"
              loading={createTaskReq.isLoading || updateFileReq.isLoading}
            >
              <Icon svg="check-done-wh" />
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <Skeleton count={6} height={20} />
      )}
    </form>
  );
}
