/* FileViewerNav Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Button from "@/components/button";

import LayoutLeft from "../../../../../../../public/svgs/layout-left.svg";
import LayoutRight from "../../../../../../../public/svgs/layout-right.svg";
import Folder from "../../../../../../../public/svgs/folder.svg";
import AnnotationSvg from "../../../../../../../public/svgs/annotation.svg";

// constants
import { STYLES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";

/* FileViewerNav Typescript Interface */
interface FileViewerNavProps {
  showAnnotations: boolean;
  showFileList: boolean;
  selectedFileName?: string;
  onToggleShowAnnotations: () => void;
  onToggleShowFileList: () => void;
}

export default function FileViewerNav({
  showAnnotations,
  showFileList,
  selectedFileName,
  onToggleShowAnnotations,
  onToggleShowFileList
}: FileViewerNavProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.FileViewerNav)}>
      <Button
        style={showFileList ? STYLES.PRIMARY : STYLES.SECONDARY}
        onClick={onToggleShowFileList}
      >
        <LayoutLeft
          stroke={showFileList ? styles.gray300 : styles.gray500}
          height={20}
          width={20}
        />
        Files
        <Folder
          stroke={showFileList ? styles.gray300 : styles.gray500}
          height={16}
          width={16}
        />
      </Button>
      {selectedFileName}

      <Button
        style={showAnnotations ? STYLES.PRIMARY : STYLES.SECONDARY}
        onClick={onToggleShowAnnotations}
        nativeButtonProps={{ disabled: !selectedFileName }}
      >
        <AnnotationSvg
          stroke={showAnnotations ? styles.gray300 : styles.gray500}
          height={16}
          width={16}
        />
        Annotations
        <LayoutRight
          stroke={showAnnotations ? styles.gray300 : styles.gray500}
          height={20}
          width={20}
        />
      </Button>
    </div>
  );
}
